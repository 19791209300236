export const personSelectPathCheck = (
  isPersonPreferenceMandatory: boolean,
  isPersonSelectable: boolean,
  establishmentId: string,
  currentCustomerIndex: number,   
  activityId?: string,
) => {
  if (isPersonPreferenceMandatory && isPersonSelectable) {

    if (activityId) {
      return `/${establishmentId}/select-person/${currentCustomerIndex}/${activityId}`
    }
    return`/${establishmentId}/select-person/${currentCustomerIndex}`
  }
  
  return `/${establishmentId}/appointment`
} 