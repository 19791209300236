import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Linking } from 'react-native'
import { URL_API } from 'react-native-dotenv'
import { useDispatch } from 'react-redux'
import verifiedGif from '../../../../assets/verified.gif'

import { removePreviousAppointmentsStore } from '../../../Appointment/store/Actions'
import { BackButton, DefaultButtonWithText, Logo, Spinner } from '../index'

import { EngagementNotification } from '../EngagementNotification/EngagementNotification'
import { IUserData } from '../../Util'
import { useParams } from '../../../Router'
import {
  SuccessMessage,
  SuccessMessageInfo,
} from './Components/SuccessMessage/SuccessMessage'

import { transformAppointmentInfo } from './Components/extraInfoTransformer'
import { SuccessGif } from './style'
import { isNativeCheck } from '../../Util/isNativeCheck'
import { TSuccessResponse } from './TSuccesResponse'
import { useAppSelector } from '../../../store/hooks'

type Props = {
  responseType: TSuccessResponse
  downloadId?: number
  successMessage?: string
  isWaitingList?: boolean
}

export const BookingSuccess = ({
  downloadId,
  responseType,
  successMessage,
  isWaitingList,
}: Props) => {
  const [extraInfo, setExtraInfo] = useState<SuccessMessageInfo[] | undefined>(
    undefined
  )

  const isNative = isNativeCheck()
  const userData: IUserData = JSON.parse(localStorage.getItem('userData'))
  const { t } = useTranslation()
  const { establishmentId } = useParams<{ [key: string]: string }>()
  const dispatch = useDispatch()

  const specialDomainArray: string[] = ['@outlook', '@msn', '@hotmail', '@live']
  const specialDomainCheck = !!specialDomainArray.find((domainExtension) =>
    userData.email.includes(domainExtension)
  )
  const isEngagementNotification = specialDomainCheck && userData.isEmailSend
  const defaultSuccessMessage: string = userData.isEmailSend
    ? 'Appointment confirmation sent.'
    : 'Your appointment has been booked.'

  const settings = useAppSelector((state) => state.settings)

  const isShowActivitiesWithNoDuration =
    settings.values.onlineAppointmentsShowActivitiesWithNoDuration

  const downloadBookingDetails = () =>
    Linking.openURL(`${URL_API}/anonymous/ics/${downloadId}`)

  useEffect(() => {
    dispatch(removePreviousAppointmentsStore())
    if (typeof responseType !== 'undefined') {
      switch (responseType.type) {
        case 'appointment':
          return setExtraInfo(
            responseType.response.appointments.map((appointment) =>
              transformAppointmentInfo(
                appointment,
                isShowActivitiesWithNoDuration
              )
            )
          )
        case 'lastMinute':
          return setExtraInfo([
            transformAppointmentInfo(
              responseType.response.appointment,
              isShowActivitiesWithNoDuration
            ),
          ])
        case 'waitingList':
        default:
          setExtraInfo([])
          break
      }
    }
  }, [dispatch, responseType])

  if (typeof settings.values === 'undefined') {
    return <Spinner />
  }

  return (
    <>
      <Logo />
      <BackButton extraMargin customPath={`/${establishmentId}`} />
      <SuccessGif source={verifiedGif} />
      <SuccessMessage
        extraInfo={extraInfo}
        message={successMessage || defaultSuccessMessage}
      />
      {typeof responseType !== 'undefined' &&
      !isEngagementNotification &&
      !isNative &&
      !isWaitingList ? (
        <DefaultButtonWithText
          backgroundcolor={settings.values.backgroundColor}
          fontcolor={settings.values.highlightColor}
          hoversensitive
          onPress={downloadBookingDetails}
        >
          {t('Add to calendar')}
        </DefaultButtonWithText>
      ) : null}
      <EngagementNotification
        renderCheck={isEngagementNotification}
        backgroundColor={settings.values.backgroundColor}
        highlightColor={settings.values.highlightColor}
      />
    </>
  )
}
