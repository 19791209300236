import React, { FunctionComponent, useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'

import { SurveyForm } from '..'
import { SurveySuccess } from '../SurveySuccess/SurveySuccess'

export const SurveyBootstrap: FunctionComponent = () => (
  <Switch>
    <Route
      path="/:establishmentId/surveys/:surveyId/:customerId/:customerToken/:invitationUuid?"
      component={SurveyForm}
    />
    <Route path="/:establishmentId/surveys/success" component={SurveySuccess} />
  </Switch>
)
