import { Action } from 'redux'

import { IOptIns } from '../Client'

export const OPTINS_LOADING = 'SURVEY_LOADING'
export const OPTINS_SUCCESS = 'SURVEY_SUCCESS'

export type OptInsLoading = Action<typeof OPTINS_LOADING>

export interface OptInsSuccess extends Action<typeof OPTINS_SUCCESS> {
    payload: {
        optIns: IOptIns
    }
}
