import moment, { Moment } from 'moment'
import {
  IApiLastMinute,
  ILastMinute,
  ILastMinuteActivity,
  ILastMinutePerson,
} from './ILastMinute'

export default class LastMinuteTransformer {
  public static transform(apiLastMinute: IApiLastMinute): ILastMinute {
    const mappedActivities: ILastMinuteActivity[] = []
    let startsAt: Moment
    for (const apiActivity of apiLastMinute.attributes.activities) {
      mappedActivities.push({
        id: apiActivity.id,
        name: apiActivity.name,
        startsAt: moment(apiActivity.starts_at),
        endsAt: moment(apiActivity.ends_at),
        color: apiActivity.color,
        persons: apiActivity.persons.map(
          (apiPerson): ILastMinutePerson => ({
            id: apiPerson.id,
            name: apiPerson.name,
          })
        ),
        price: apiActivity.price,
      })
      if (!startsAt || startsAt.isAfter(moment(apiActivity.starts_at))) {
        startsAt = moment(apiActivity.starts_at)
      }
    }

    return {
      id: apiLastMinute.id,
      description: apiLastMinute.attributes.description,
      isOverlapped: apiLastMinute.attributes.is_overlapped,
      isBooked: apiLastMinute.attributes.is_booked,
      activities: mappedActivities,
      startsAt: startsAt,
      lastMinutePrice: apiLastMinute.attributes.last_minute_price
    }
  }
}
