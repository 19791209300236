import moment from 'moment'
import {TArguments} from "./TArguments";
import {TSelectedColor} from "./interface/TSelectedColor";
import {setCalendarStyle} from "./setCalendarStyle"

export const getAvailabilityMap: TArguments = (
  availability,
  monthIndex,
  numberOfMomentsIndicatingBusy,
  closingDays,
  maximumDate,
  selectedDayKey,
  disableColorCode,
  markerRadius
) => {
  if (!availability) {
    return {}
  }
  const availableDays = availability
    .map((element) => element.substr(0, 10))
    .reduce((acc, dateString) => {
      acc[dateString] = (acc[dateString] || 0) + 1
      return acc
    }, {})

  const currentDate = moment().subtract(1, "d")
    .add(monthIndex, 'months')
    .startOf('month')
    .startOf('isoWeek')
  const endOfMonth = moment()
    .add(monthIndex, 'months')
    .clone()
    .endOf('month')
    .endOf('isoWeek')

  let currentDateString
  let currentColor: TSelectedColor
  let isEnable
  const dayMap = {
    1: 'mon',
    2: 'tue',
    3: 'wed',
    4: 'thu',
    5: 'fri',
    6: 'sat',
    7: 'sun',
  }

  do {
    currentDateString = currentDate.format('YYYY-MM-DD')
    currentColor = null
    isEnable = false
    if (
      typeof availableDays[currentDateString] === 'undefined' ||
      availableDays[currentDateString] === 0
    ) {
      if (
        !closingDays.includes(dayMap[currentDate.isoWeekday()]) &&
        !currentDate.isBefore(moment())
      ) {
        currentColor = 'fullColor'
        isEnable = true // (was false)should always be true since waiting list function, keep it here just in case
      }
    } else if (
      availableDays[currentDateString] > numberOfMomentsIndicatingBusy
    ) {
      currentColor = 'availableColor'
      isEnable = true
    } else {
      currentColor = 'busyColor'
      isEnable = true
    }
    if (currentColor !== null && currentDate.isBefore(maximumDate)) {
      const isSelected = currentDateString === selectedDayKey
      availableDays[currentDateString] = setCalendarStyle(
        disableColorCode,
        isSelected,
        isEnable,
        markerRadius,
        currentColor
      )
    } else {
      delete availableDays[currentDateString]
    }

    currentDate.add(1, 'day')
  } while (currentDate.isBefore(endOfMonth))

  return availableDays
}
