import { AnyAction, Reducer } from 'redux'

import {
  PAYMENT_FAILED,
  PAYMENT_LOADING,
  PAYMENT_SUCCESS,
  PAYMENT_URL_INVALID,
} from './Actions'
import { TMollieResponse } from '../../PreviousAppointments/IPreviousAppointment'


export type TPaymentFallback = {
  loading: boolean
  status: TMollieResponse | 'invalidUrl'
  paidAppointment?: any
}

const initialState: TPaymentFallback = {
  loading: false,
  status: 'open',
}
export const reducer: Reducer<TPaymentFallback> = (
  state = initialState,
  action: AnyAction): TPaymentFallback => {
  switch (action.type) {
    case PAYMENT_URL_INVALID:
      return {
        ...state,
        loading: false,
        status: 'invalidUrl',
      }
    case PAYMENT_LOADING:
      return {
        ...state,
        loading: true,
      }
    case PAYMENT_FAILED:
      return {
        ...state,
        loading: false,
        status: 'failed',
      }
    case PAYMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        status: 'paid',
        paidAppointment: action.appointment,
      }
    default:
      return state
  }
}
