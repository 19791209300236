import { Reducer } from 'redux'
import { CHANGE_SNACKBAR_STATE } from './Action'
import { initialSnackbarState as initialState } from './initialSnackbarState'
import { ISnackbarState } from './interface/ISnackBarState'

export const reducer: Reducer<ISnackbarState> = (
  state: ISnackbarState = initialState,
  action
): ISnackbarState => {
  switch (action.type) {
    case CHANGE_SNACKBAR_STATE:
      return {
        ...state,
        snackBarState: action.payload.snackBarState,
        message: action.payload.message,
        title: action.payload.title,
        timeTillUnmount: action.payload.timeTillUnmount,
      }
    default:
      return state
  }
}
