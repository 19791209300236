import React, {FunctionComponent, useRef, useState} from 'react'
import { TextInput } from 'react-native'
import { useTranslation } from 'react-i18next'
import {IProps} from "./IProps";
import {
  FieldInputContainer, InputError,
  FieldLabel, FieldInput
} from "../../../App/Components/InputField/style";
import {hexToRgba} from "../../../App/Util";
import {checkGiftCard} from "./Client/checkGiftCard";

export const GiftCardField: FunctionComponent<IProps> = ({
  isShowGiftCardField,
  setGiftCarInput,
  giftCardInput,
  setGiftCardState,
  establishmentId,
  fontColor
}) => {
  const [errorMessage, setErrorMessage] = useState<'warning' | 'error' | 'success' | 'idle' | 'checkingCard'>('idle')
  const messageContent = {
    warning: 'This card has no value left' ,
    error: 'This code is not valid',
    success: 'Your gift card will be deducted from the deposit',
    checkingCard: 'Checking your card please wait',
  }
  const messageColor = {
    warning: '#d27a46' ,
    error: '#d24444',
    success: '#46d259',
    checkingCard: '#4673d2',
  }

  const { t } = useTranslation()
  const ref = useRef<TextInput>()

  const onPressFieldLabel = () => ref.current.focus()
  const skipLabelFocus = () => ref.current.focus()

  const submitGiftCardCheck = async () => {
    setGiftCardState('checkingCard')
    setErrorMessage("checkingCard")
    const cardResponse = await checkGiftCard(giftCardInput, establishmentId).catch((e)=>e)
    if(cardResponse.status === 200){
      if(cardResponse.data.remaining_value === 0){
        setErrorMessage("warning")
      } else setErrorMessage('success')
      return setGiftCardState('idle')
    }
    setErrorMessage('error')
    return setGiftCardState('idle')
  }

  const fadedFontColor: string = hexToRgba(fontColor, 0.6)

  if(!isShowGiftCardField){
    return null
  }
  return (
        <FieldInputContainer>
            <FieldLabel
                fontcolor={fadedFontColor}
                onPress={onPressFieldLabel}
                onFocus={skipLabelFocus}
            >
                {t('Gift card number')}
            </FieldLabel>
            <FieldInput
                fontcolor={fontColor}
                onBlur={submitGiftCardCheck}
                value={giftCardInput}
                onChange={(e)=>setGiftCarInput(e.target.value)}
                ref={ref}
            />
                <InputError showError={errorMessage !== 'idle'} statusColor={messageColor[errorMessage]}>
                    {t(messageContent[errorMessage])}
                </InputError>
        </FieldInputContainer>
  )
}