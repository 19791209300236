import React, { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import { StyledScrollView } from '../../AppContainer'

import { OptiosActivityIndicator, SpinnerText, SpinnerWrapper } from './style'

export const Spinner: FunctionComponent = () => {
    const { t } = useTranslation()
    return (
        <StyledScrollView>
            <SpinnerWrapper>
                <OptiosActivityIndicator size="large" />
                <SpinnerText>{t('loading...')}</SpinnerText>
            </SpinnerWrapper>
        </StyledScrollView>
    )
}
