import { Reducer } from 'redux'
import { SET_CONTEXT_MENU_URL, TOGGLE_CONTEXT_MENU } from './action'
import {
  initialContextMenuState as initialState,
  IContextMenuState,
} from './initialContextMenuState'

export const reducer: Reducer<IContextMenuState> = (
  state: IContextMenuState = initialState,
  action
): IContextMenuState => {
  switch (action.type) {
    case SET_CONTEXT_MENU_URL:
      return {
        ...state,
        isActive: action.payload.isActive,
        position: { ...action.payload.position },
        menuItems: { tileUrl: action.payload.tileUrl },
      }
    case TOGGLE_CONTEXT_MENU:
      return {
        ...state,
        isActive: action.payload.isActive,
      }
    default:
      return state
  }
}
