import styled from 'styled-components/native'

export const AppBackgroundImage = styled.Image`
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
`

export const BookingClosed = styled.Text<{ textColor: string }>`
    text-align: center;
    padding: 0 1.5px;
    margin: 20px auto;
    max-width: 60%;
    color: ${({ textColor }) => textColor};
`