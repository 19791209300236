export const stringToHsl = (
    text: string,
    saturation: number,
    lightness: number
) => {
    let hash = 0
    for (let i = 0; i < text.length; i++) {
        // tslint:disable-next-line:no-bitwise
        hash = text.charCodeAt(i) + ((hash << 5) - hash)
    }

    const hue = hash % 360

    return 'hsl(' + hue + ', ' + saturation + '%, ' + lightness + '%)'
}
