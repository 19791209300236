import {
  IApiPreviousAppointment,
  IPreviousAppointment,
  IPreviousAppointmentActivity,
} from '../IPreviousAppointment'

export const previousAppointmentTransformer = (
  apiResponse: IApiPreviousAppointment
): IPreviousAppointment => ({
  date: apiResponse.date,
  id: apiResponse.id,
  token: apiResponse.token,
  hasCabins: apiResponse.has_cabins,
  deposit: apiResponse.deposit
    ? { status: apiResponse.deposit.status }
    : undefined,
  activities: apiResponse.appointment_activities.map(
    (apiActivity): IPreviousAppointmentActivity => ({
      id: apiActivity.id,
      uuid: apiActivity.uuid,
      name: apiActivity.name,
      categoryId: apiActivity.category_id,
      person: {
        id: apiActivity.person.id,
        uuid: apiActivity.person.uuid,
        firstName: apiActivity.person.first_name,
        lastName: apiActivity.person.last_name,
        isPreferred: apiActivity.person.is_preferred,
        isCabine: apiActivity.person.is_cabine,
      },
    })
  ),
})
