import React from 'react'
import { useTranslation } from 'react-i18next'
import { ServiceTitle, StyledContainer } from '../../../Appointment/style'
import { BackButton } from '../../../App/Components'
import { useHistory } from '../../../Router'
import { ActivityEdit } from '../ActivityEdit/ActivityEdit'
import { ISettingValues } from '../../../Settings/ISettings'
import { IPreviousAppointment } from '../../../PreviousAppointments/IPreviousAppointment'
import { SelectPersonForAllActivities } from '../../../Appointment/Components'
import { IExtraActivity } from '../../../ExtraActivity/Client/IExtraActivity'
import { isSelectAPerson } from '../../../App/Util/selectPersonChecks/isSelectAPerson/isSelectAPerson'

type Props = {
  establishmentId: string
  settingValues: ISettingValues
  appointment: IPreviousAppointment
  activityDetails: IExtraActivity[]
}

export const EditOverviewHeader = ({
  establishmentId,
  settingValues,
  appointment,
  activityDetails,
}: Props) => {
  const { t } = useTranslation()
  const history = useHistory()

  // TODO prevPath does not work, fix tis after migration of react router @help
  const prevPath: string = history.location.state
    ? history.location.state.prevPath
    : ''
  // TODO possible breaking logic when changing router
  const selectPersonPath = `/${establishmentId}/edit-person`

  return (
    <>
      <ServiceTitle fontcolor={settingValues.highlightColor}>
        {t('Services')}
      </ServiceTitle>
      <BackButton
        customPath={
          prevPath.includes(selectPersonPath)
            ? `/${establishmentId}/previous-appointments`
            : undefined
        }
      />
      <StyledContainer highlightcolor={settingValues.highlightColor}>
        {appointment.activities.map((activity, i) => {
          const isPreferredPerson = activity.person.isPreferred
          return (
            <ActivityEdit
              isPersonSelectable={activityDetails[i].isPersonSelectable}
              activity={activity}
              settingsValues={settingValues}
              isPreferredPerson={isPreferredPerson}
            />
          )
        })}
        <SelectPersonForAllActivities
          isPersonSelectable={isSelectAPerson(activityDetails)}
          moveAppointmentData={activityDetails}
        />
      </StyledContainer>
    </>
  )
}
