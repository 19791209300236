import React, { useEffect, useMemo } from 'react'

import { Logo, Spinner } from '../App/Components'
import { StyledScrollView } from '../App/Components/AppContainer'
import { Calendar } from '../Calendar/Calendar/Calendar'
import { useHistory, useParams } from '../Router'
import { getUserToken } from '../App/Util/localStorageHelper/userToken/setUserToken'
import { EditOverviewHeader } from './components/EditOverviewHeader/EditOverviewHeader'
import { IExtraActivity } from '../ExtraActivity/Client/IExtraActivity'
import { useAppSelector } from '../store/hooks'
import { getUserData } from '../App/Util'

export const MoveAppointment = () => {
  const { establishmentId } = useParams<{ [key: string]: string }>()
  const history = useHistory()

  const { settings, editPreviousAppointment, flatActivities } = useAppSelector(
    (state) => ({
      settings: state.settings.values,
      editPreviousAppointment: state.appointment.editPreviousAppointment,
      flatActivities: state.activities.list
        .map((activityCollection) => activityCollection.activities)
        .flat(2) as IExtraActivity[],
    })
  )

  useEffect(() => {
    if (!editPreviousAppointment) {
      const userEmail = getUserData().email
      const userToken = getUserToken(establishmentId, userEmail)
      if (!userToken) history.push(`/${establishmentId}`)
      else
        history.push(`/${establishmentId}/previous-appointments/${userToken}`)
    }
  }, [editPreviousAppointment, history, establishmentId])

  const activityArray = useMemo(() => {
    if (!editPreviousAppointment) return []
    const selectedActivityIds = editPreviousAppointment.activities.map(
      (activity) => activity.id
    )
    return flatActivities.filter((activity) =>
      selectedActivityIds.includes(activity.id)
    )
  }, [editPreviousAppointment, flatActivities])

  if (!settings || !editPreviousAppointment) {
    return <Spinner />
  }

  const sortedActivityDetails = activityArray.sort((a, b) => {
    const indexA = editPreviousAppointment.activities.findIndex(
      (activity) => activity.uuid === a.uuid
    )
    const indexB = editPreviousAppointment.activities.findIndex(
      (activity) => activity.uuid === b.uuid
    )
    return indexA - indexB
  })

  return (
    <StyledScrollView>
      <Logo />
      <EditOverviewHeader
        establishmentId={establishmentId}
        settingValues={settings}
        appointment={editPreviousAppointment}
        activityDetails={sortedActivityDetails}
      />
      <Calendar
        settings={settings}
        previousAppointment={editPreviousAppointment}
      />
    </StyledScrollView>
  )
}
