import moment from 'moment'
import { AxiosResponse } from 'axios'
import { IUserData, setUserData } from '../../App/Util'
import { IExtraActivity } from '../../ExtraActivity/Client/IExtraActivity'
import { IMenuActivity } from '../../Menu/Store/IMenuActivity'
import { IPreviousAppointment } from '../../PreviousAppointments/IPreviousAppointment'
import { IPerson } from '../../SelectPerson/interface/IPerson'

import previousAppointmentsClient from '../../PreviousAppointments/Client/Client'
import Client from '../Client/Client'
import {
  TAvailability,
  TAppointmentStatus,
} from './interface/IAppointmentState'
import { CustomerFormData } from '../../CustomerForm/ICustomerFormData'
import { AppDispatch } from '../../store/store'
import { ApiAppointmentResponse } from './interface/IApiAppointmentResponse'
import { ICustomerBlock } from '../Client/interface/ICustomerBlock'
import { IWorkshopSubscription } from '../../Workshop/store/IWorkshop'
import WorkshopClient from '../../Workshop/Client/Client'
import { TNewFreeMomentFinderResponse } from './interface/TNewFreeMomentFinderResponse'

export const RESET_ACTIVITY = 'RESET_ACTIVITY'
export const ADD_ACTIVITY = 'ADD_ACTIVITY'
export const ADD_CUSTOMER = 'ADD_CUSTOMER'
export const ADD_PERSON_TO_APPOINTMENT = 'ADD_PERSON_TO_APPOINTMENT'
export const ADD_MENU = 'ADD_MENU'
export const ADD_AVAILABILITY = 'ADD_AVAILABILITY'
export const AVAILABILITY_LOADING = 'AVAILABILITY_LOADING'
export const ADD_AVAILABILITY_FAIL = 'ADD_AVAILABILITY_FAIL'
export const ADD_NEW_AVAILABILITY = 'ADD_NEW_AVAILABILITY'
export const AVAILABILITY_NEW_LOADING = 'AVAILABILITY_NEW_LOADING'
export const SET_DATE = 'SET_DATE'
export const SET_PREVIOUS_APPOINTMENTS = 'SET_PREVIOUS_APPOINTMENTS'
export const PREV_APPOINTMENT_MOVED = 'PREV_APPOINTMENT_MOVED'
export const DELETE_APPOINTMENT = 'DELETE_APPOINTMENT'
export const DELETE_LAST_ADDED = 'DELETE_LAST_ADDED'
export const FORM_LOADING = 'FORM_LOADING'
export const FORM_LOADING_DONE = 'FORM_LOADING_DONE'
export const APPOINTMENT_STATUS = 'APPOINTMENT_STATUS'
export const SET_CURRENT_CUSTOMER_INDEX = 'SET_CURRENT_CUSTOMER_INDEX'
export const DELETE_CUSTOMER_INDEX = 'DELETE_CUSTOMER_INDEX'
export const ADD_NEW_AVAILABILITY_FAIL = 'ADD_NEW_AVAILABILITY_FAIL'
export const REMOVE_PREVIOUS_APPOINTMENTS_STORE =
  'REMOVE_PREVIOUS_APPOINTMENTS_STORE'
export const AVAILABILITY_NEW_LOADING_DONE = 'AVAILABILITY_NEW_LOADING_DONE'
export const DELETE_ACTIVITY = 'DELETE_ACTIVITY'
export const CANCEL_WORKSHOP_SUBSCRIPTION = 'CANCEL_WORKSHOP_SUBSCRIPTION'
export const UPDATE_TOTAL_AMOUNT = 'UPDATE_TOTAL_AMOUNT'
export const UPDATE_TOTAL_DEPOSIT = 'UPDATE_TOTAL_DEPOSIT'
export const SET_UPDATE_PREVIOUS_APPOINTMENT = 'SET_UPDATE_PREVIOUS_APPOINTMENT'
export const REMOVE_UPDATE_PREVIOUS_APPOINTMENT =
  'REMOVE_EDIT_PREVIOUS_APPOINTMENT'
export const EDIT_UPDATE_PREVIOUS_APPOINTMENT =
  'EDIT_UPDATE_PREVIOUS_APPOINTMENT'

export function resetActivity() {
  return {
    type: RESET_ACTIVITY,
  }
}

export function addMenu(menu: IMenuActivity[], categoryId: number) {
  return {
    type: ADD_MENU,
    menu,
    categoryId,
  }
}

export function addCustomer() {
  return {
    type: ADD_CUSTOMER,
  }
}

export function setCurrentCustomerIndex(customerIndex: number) {
  return {
    type: SET_CURRENT_CUSTOMER_INDEX,
    customerIndex,
  }
}

export function addActivity(activity: IExtraActivity) {
  return {
    type: ADD_ACTIVITY,
    activity,
  }
}

export function addPersonToAppointment(
  customerIndex: number | undefined,
  person: IPerson | undefined,
  activityId: number | undefined
) {
  return {
    type: ADD_PERSON_TO_APPOINTMENT,
    customerIndex,
    person,
    activityId,
  }
}

export function deleteActivity(activity: IMenuActivity | IExtraActivity, customerIndex: number) {
  return {
    type: DELETE_ACTIVITY,
    payload: {
      activity,
      customerIndex,
    },
  }
}

function addAvailabilityLoading() {
  return {
    type: AVAILABILITY_LOADING,
  }
}

function addNewAvailabilityLoadingDone(requestId: number) {
  return {
    type: AVAILABILITY_NEW_LOADING_DONE,
    meta: { requestId },
  }
}

function addAvailabilitySuccess(availability: TAvailability[]) {
  return {
    type: ADD_AVAILABILITY,
    availability,
  }
}

function addAvailabilityFail() {
  return {
    type: ADD_AVAILABILITY_FAIL,
  }
}

function addNewAvailabilityLoading(requestId: number) {
  return {
    type: AVAILABILITY_NEW_LOADING,
    meta: { requestId },
  }
}

function addNewAvailabilityFail(requestId: number) {
  return {
    type: ADD_NEW_AVAILABILITY_FAIL,
    meta: { requestId },
  }
}

function addNewAvailabilitySuccess(
  availability: TAvailability[],
  requestId: number
) {
  return {
    type: ADD_NEW_AVAILABILITY,
    availability,
    meta: { requestId },
  }
}

export function addAvailability(
  establishmentId: string,
  monthIndex: number,
  activities: ICustomerBlock[]
) {
  return async (dispatch: AppDispatch) => {
    dispatch(addAvailabilityLoading())
    return Client.getFreeMoments(establishmentId, monthIndex, activities).then(
      (response: AxiosResponse<string[]>) => {
        const list: TAvailability[] = response.data.map((date): TAvailability => ({
          timeSlot: date,
          score: 0,
          isBest: false,
        }))
        dispatch(addAvailabilitySuccess(list))
      },
      () => dispatch(addAvailabilityFail())
    )
  }
}

let requestId = 1
export function addNewAvailability(
  establishmentId: string,
  monthIndex: number,
  activities: ICustomerBlock[],
  customerToken: string | null
) {
  return async (dispatch: AppDispatch) => {
    const currentRequestId = requestId
    requestId += 1

    Client.abortController.abort()
    Client.abortController = new AbortController()

    dispatch(addNewAvailabilityLoading(currentRequestId))

    let startsAt = moment()
      .add(monthIndex, 'months')
      .startOf('month')
      .startOf('isoWeek')
    const endsAt = moment()
      .add(monthIndex, 'months')
      .endOf('month')
      .endOf('isoWeek')
    if (startsAt.isBefore(moment())) {
      startsAt = moment().startOf('day')
    }
    let axiosResults
    try {
      const weeks = Math.ceil(endsAt.diff(startsAt, 'weeks', true))
      axiosResults = await Promise.all(
        Array(weeks)
          .fill(null)
          .map(async (_, i) => {
            const currentDate = startsAt
              .clone()
              .add(i, 'weeks')
              .startOf('isoWeek')
            return Client.getNewFreeMoments(
              establishmentId,
              currentDate,
              currentDate.clone().endOf('isoWeek'),
              activities,
              customerToken
            )
          })
      )
    } catch (error) {
      dispatch(addNewAvailabilityFail(currentRequestId))
    }

    const transformResult: TAvailability[][] = axiosResults.map(
      (resultObject: AxiosResponse<TNewFreeMomentFinderResponse>) =>
        Object.values(resultObject.data.days || {})
          .map((day) =>
            Object.entries(day.free_moments).map(([scoreKey, daysForScore]) =>
              Object.entries(daysForScore).map(([dateKey]): TAvailability => {
                const score = scoreKey.split('_')[1]
                return { score: Number(score), timeSlot: dateKey, isBest: false}
              })
            )
          )
          .flat(2)
    )

    transformResult.forEach((result) =>
      dispatch(addNewAvailabilitySuccess(result, currentRequestId))
    )
    dispatch(addNewAvailabilityLoadingDone(currentRequestId))
  }
}

function setDateSuccess(date: string) {
  return {
    type: SET_DATE,
    date,
  }
}

export function setDate(date: string) {
  return (dispatch: any) => {
    dispatch(setDateSuccess(date))
  }
}

function getPreviousAppointmentsSuccess(
  appointments: IPreviousAppointment[],
  workshops: IWorkshopSubscription[]
) {
  return {
    type: SET_PREVIOUS_APPOINTMENTS,
    appointments,
    workshops,
  }
}

export function getPreviousAppointments(
  establishmentId: string,
  email: string,
  token: string
) {
  return async (dispatch: any) => {
    const previousAppointments =
      await previousAppointmentsClient.getPreviousAppointments(
        establishmentId,
        email,
        token
      )
    dispatch(
      getPreviousAppointmentsSuccess(
        previousAppointments.appointments,
        previousAppointments.workshops
      )
    )
  }
}

export function movedPreviousAppointment(
  updatedAppointment: IPreviousAppointment
) {
  return {
    type: PREV_APPOINTMENT_MOVED,
    payload: {
      updatedAppointment,
    },
  }
}

export function removePreviousAppointmentsStore() {
  return {
    type: REMOVE_PREVIOUS_APPOINTMENTS_STORE,
  }
}

export function deleteAppointment(appointmentId: number) {
  return {
    type: DELETE_APPOINTMENT,
    appointmentId,
  }
}

export function deleteLastAdded() {
  return {
    type: DELETE_LAST_ADDED,
  }
}

export function deleteCustomerIndex(customerIndex: number) {
  return {
    type: DELETE_CUSTOMER_INDEX,
    payload: {
      customerIndex,
    },
  }
}

function formLoading() {
  return {
    type: FORM_LOADING,
  }
}

export function setAppointmentStatus(
  appointmentStatus: TAppointmentStatus | undefined
) {
  return {
    type: APPOINTMENT_STATUS,
    payload: {
      appointmentStatus,
    },
  }
}

export function setUpdatePreviousAppointment(
  previousAppointment: IPreviousAppointment
) {
  return {
    type: SET_UPDATE_PREVIOUS_APPOINTMENT,
    payload: {
      previousAppointment,
    },
  }
}

export function editUpdatePreviousAppointment(
  person: IPerson | null,
  activityId: number
) {
  return {
    type: EDIT_UPDATE_PREVIOUS_APPOINTMENT,
    payload: {
      person,
      activityId,
    },
  }
}

export function removeUpdatePreviousAppointment() {
  return {
    type: REMOVE_UPDATE_PREVIOUS_APPOINTMENT,
  }
}

export function sendWaitingListData(
  establishmentId: string,
  activitiesToSend: IMenuActivity[][],
  customerData: CustomerFormData,
  date: string
) {
  return (dispatch: any) => {
    const { firstName, lastName, phone, email, comment, sendEmail } =
      customerData
    dispatch(formLoading())
    return Client.addToWaitingList(
      establishmentId,
      firstName,
      lastName,
      phone,
      email,
      activitiesToSend,
      comment,
      date
    ).then((response: AxiosResponse<ApiAppointmentResponse>) => {
      try {
        const storageObject: IUserData = {
          firstName,
          lastName,
          phone,
          email,
          isEmailSend: sendEmail,
        }
        setUserData(storageObject)
        localStorage.setItem('token', response.data.token)

        if (response.data.online_payment_url) {
          localStorage.setItem('token', response.data.token)
          window.open(response.data.online_payment_url, '_self')
        } else {
          dispatch(setAppointmentStatus('booking_success'))
        }
      } catch (e) {
        throw new Error('processing_error')
      }
    })
  }
}

export function formLoadingDone() {
  return {
    type: FORM_LOADING_DONE,
  }
}

export function cancelWorkshop(
  workshopUuid: string,
  subscriptionUuid: string,
  token: string
) {
  return async (dispatch: any) => {
    await WorkshopClient.cancelWorkshop(workshopUuid, subscriptionUuid, token)

    dispatch({
      type: CANCEL_WORKSHOP_SUBSCRIPTION,
      payload: {
        workshopUuid,
        subscriptionUuid,
      },
    })
  }
}
