import React, { FunctionComponent } from 'react'
import { StyleSheet, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import { IProps } from './IProps'
import {
  NotificationContainer,
  NotificationLink,
  NotificationText,
  NotificationTitle,
  NotificationTextBold,
} from './style'
import { handleLink } from '../../Util'
import { DefaultButtonWithText } from '../ButtonWithText/style'
import { ProximaNova } from '../TextComponent'

export const EngagementNotification: FunctionComponent<IProps> = ({
  renderCheck,
  backgroundColor,
  highlightColor,
}) => {
  const { t } = useTranslation()

  const pickDifferentEmail = () => {
    console.log('make a nice function :)')
  }

  const styles = StyleSheet.create({
    textLink: {
      textDecorationLine: 'underline',
    },
  })

  if (!renderCheck) {
    return null
  }

  return (
    <View>
      <NotificationContainer>
        <NotificationTitle>{t('Attention!')}</NotificationTitle>
        <NotificationLink
          style={styles.textLink}
          onPress={() =>
            handleLink('https://outlook.live.com/mail/0/junkemail')
          }
        >
          {t('Check your junk mail folder.')}
        </NotificationLink>
        <NotificationText>
          {t('This email is proof of your appointment.')}
        </NotificationText>
        <NotificationText>
          {t('At the top, click on')}
          <NotificationTextBold>
            {t('“The message is not spam"')}
          </NotificationTextBold>
          {t('and keep it safe for your visit to the salon.')}
        </NotificationText>
      </NotificationContainer>
      {/* <ProximaNova>{t('Received no email?')}</ProximaNova> */}
      {/* <DefaultButtonWithText */}
      {/*   backgroundcolor={backgroundColor} */}
      {/*   fontcolor={highlightColor} */}
      {/*   hoversensitive */}
      {/*   onPress={() => pickDifferentEmail()} */}
      {/* > */}
      {/*   {t('Enter new email address')} */}
      {/* </DefaultButtonWithText> */}
    </View>
  )
}
