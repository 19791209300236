import React, { useEffect } from 'react'

import { AbsoluteSpinner } from '../../App/Components'
import {
  setAppointmentStatus,
  resetActivity,
} from '../../Appointment/store/Actions'
import { useHistory, useParams, useLocation } from '../../Router'
import { useAppDispatch, useAppSelector } from '../../store/hooks'

export const MainBootstrap: React.FC = () => {
  const history = useHistory()
  const locationPathName = useLocation().pathname
  const dispatch = useAppDispatch()
  const { establishmentId } = useParams<{ [key: string]: string }>()

  const { appointment, settings, selectedLocation } = useAppSelector(
    (state) => ({
      appointment: state.appointment,
      settings: state.settings.values,
      selectedLocation: state.settings.locationSelected,
    })
  )

  useEffect(() => {
    const { appointmentStatus } = appointment
    const { locations, isSelectLocation } = settings

    if (settings) {
      if (appointmentStatus) {
        dispatch(resetActivity())
        dispatch(setAppointmentStatus(undefined))
      }

      if (
        locations.filter((location) => location.onlineBookingEnabled).length >
          1 &&
        typeof selectedLocation === 'undefined' &&
        isSelectLocation
      ) {
        history.push(`/${establishmentId}/select-location`)
        return
      }

      if (locationPathName.includes('/account')) return

      if (settings.isMenuVisible) {
        history.push(`/${establishmentId}/menu`)
        return
      }

      history.push(`/${establishmentId}/activities`)
    }
  }, [
    appointment,
    settings,
    dispatch,
    establishmentId,
    history,
    locationPathName,
    selectedLocation,
  ])

  return <AbsoluteSpinner />
}
