import { Reducer } from 'redux'

import { OPTINS_LOADING, OPTINS_SUCCESS } from './ActionTypes'
import { OptInsState } from './initialState'


const initialState: OptInsState = {
  loading: false,
}
export const reducer: Reducer<OptInsState> = (
  state: OptInsState = initialState,
  action: any
): OptInsState => {
  switch (action.type) {
    case OPTINS_LOADING:
      return {
        ...state,
        loading: true,
      }
    case OPTINS_SUCCESS:
      return {
        ...state,
        loading: false,
        optIns: action.payload.optIns,
      }
    default:
      return state
  }
}
