import React, {FunctionComponent} from 'react'
import { View } from 'react-native'
import { useSelector } from 'react-redux'

import { faUser } from '@fortawesome/pro-light-svg-icons'

import { FaIcon } from '../../../../../App/Components'
import { IAppState } from '../../../../../store/IAppState'
import { AddActivityButton } from '../../../AddActivityButton/AddActivityButton'
import { DisplayActivities } from '../../../DisplayActivities/DisplayActivities'

import { IProps } from './IProps'
import { StyledActivityText } from './style'

export const CustomerAppointment: FunctionComponent<IProps> = ({
  customerIndex,
}) => {
  const { settings } = useSelector((state: IAppState) => ({
    settings: state.settings.values,
  }))

  const isShowAddExtraActivity: boolean =
    settings.isAddExtraActivityVisible || !settings.isMenuVisible

  return (
    <View>
      <StyledActivityText>
        <FaIcon
          size="lg"
          icon={faUser}
          color={settings.highlightColor}
          cssStyle={{ top: '6px', position: 'absolute' }}
        />
        <DisplayActivities customerIndex={customerIndex} />
      </StyledActivityText>
      <AddActivityButton
        showButton={isShowAddExtraActivity}
        customerIndex={customerIndex}
      />
    </View>
  )
}
