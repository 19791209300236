import React, { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'

import { AbsoluteSpinner } from '../../../../../App/Components'
import { deleteActivity } from '../../../../store/Actions'
import { ActivityItem } from '../../../../style'
import { ActivityDuration } from '../ActivityDuration/ActivityDuration'
import { ActivityPrice } from '../ActivityPrice/ActivityPrice'
import { RemoveActivityIcon } from '../RemoveActivityIcon/RemoveActivityIcon'
import { SelectPersonPerActivity } from '../SelectPersonPerActivity/SelectPersonPerActivity'

import {
  ActivityContainer,
  ActivityName,
  EmptyRemoveContainer,
  EmptySecondContainer,
} from './style'
import { getPersons } from '../../../../../SelectPerson/store/Actions'
import { useParams } from '../../../../../Router'
import { isAPersonSelectable } from '../../../../../App/Util/selectPersonChecks/isAPersonSelectable/isAPersonSelectable'
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks'
import { IMenuActivity } from '../../../../../Menu/Store/IMenuActivity'


type Props = {
  activity: IMenuActivity
  customerIndex: number
}

export const DisplayActivity = ({
  activity: activityProp,
  customerIndex,
}: Props) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { establishmentId } = useParams<{ [key: string]: string }>()
  const { settings, persons } = useAppSelector((state) => ({
    settings: state.settings,
    persons: state.persons.persons,
  }))

  const activity = useMemo(() => activityProp, [activityProp])

  const removeActivityContainer = useCallback(() => {
    dispatch(deleteActivity(activity, customerIndex))
  }, [customerIndex, activity, dispatch])

  useEffect(() => {
    if (typeof persons === 'undefined') {
      dispatch(getPersons(establishmentId))
    }
  }, [establishmentId, persons, dispatch])

  if (typeof settings === 'undefined' || typeof persons === 'undefined') {
    return <AbsoluteSpinner />
  }

  const settingsValues = settings.values

  const personsWithKnowledge = isAPersonSelectable(persons, activity.id)

  const isSelectIndividualPerson: boolean =
    settingsValues.isPreferredPersonPerActivity &&
    activity.isPersonSelectable &&
    personsWithKnowledge.length > 0

  const isShowActivityWithNoDuration =
    settingsValues.onlineAppointmentsShowActivitiesWithNoDuration
  const activityDuration = activity.duration

  if (!isShowActivityWithNoDuration && activityDuration === 0) {
    return null
  }

  return (
    <>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'baseline',
          flexWrap: 'wrap',
        }}
      >
        <ActivityItem>
          <ActivityContainer>
            <ActivityName
              numberOfLines={1}
              ellipsizeMode="tail"
              highlightcolor={settingsValues.fontColor}
            >
              {t(`activities;;${activity.name}`)}
            </ActivityName>

            <ActivityPrice
              isShowPrice={settingsValues.showPrice}
              price={
                activity.type === 'menuActivity'
                  ? activity.activityMenuPrice
                  : activity.price
              }
            />
            {activity.price === 0 ? <EmptyRemoveContainer /> : null}
            <ActivityDuration
              isShowDuration={settingsValues.showDuration}
              isShowPrice={settingsValues.showPrice}
              duration={activityDuration}
              waitTime={activity.waitTime}
            />
            {typeof activity.menuId === 'undefined' ||
            activity.isFirstOfMenu ? (
              <RemoveActivityIcon
                removeActivity={removeActivityContainer}
                isShowRemoveIcon
                highlightcolor={settingsValues.highlightColor}
              />
            ) : null}
            {typeof activity.menuId === 'undefined' ||
            activity.isFirstOfMenu ? null : (
              <EmptySecondContainer />
            )}
          </ActivityContainer>
        </ActivityItem>
      </View>
      <SelectPersonPerActivity
        isSelectIndividualPerson={isSelectIndividualPerson}
        activity={activity}
        customerIndex={customerIndex}
        highlightcolor={settingsValues.highlightColor}
      />
    </>
  )
}
