import { useState, useEffect } from 'react'
import { Dimensions } from 'react-native'

const getWindowDimensions = () => {
  const { width: windowWidth, height: windowHeight } = Dimensions.get('window')
  return { windowWidth, windowHeight }
}

export const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  )

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }

    Dimensions.addEventListener('change', handleResize)
    return () => Dimensions.removeEventListener('change', handleResize)
  }, [])

  return windowDimensions
}
