import { IApiPerson, IPerson } from '../interface/IPerson'

export default class PersonTransformer {
    public static transform(apiPerson: IApiPerson): IPerson {
        return {
            activityIds: apiPerson.activity_ids,
            id: apiPerson.id,
            firstName: apiPerson.first_name,
            lastName: apiPerson.last_name,
            isCabine: apiPerson.is_cabine,
            isDeleted: apiPerson.is_deleted,
            avatar: apiPerson.avatar,
            schemaWeeks: apiPerson.schema_weeks,
            isVisibleInCalender: apiPerson.is_visible_in_calendar,
            isVisibleOnline: apiPerson.is_visible_online,
        }
    }

    public static reverseTransform(person: IPerson): IApiPerson {
        return {
            activity_ids: person.activityIds,
            id: person.id,
            first_name: person.firstName,
            last_name: person.lastName,
            avatar: person.avatar,
            is_cabine: person.isCabine,
            is_deleted: person.isDeleted,
            is_visible_in_calendar: person.isVisibleInCalender,
            is_visible_online: person.isVisibleInCalender,
            schema_weeks: person.schemaWeeks,
        }
    }
}
