import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { AbsoluteSpinner, Spinner } from '../../App/Components'
import { StyledScrollView } from '../../App/Components/AppContainer'
import { IAppState } from '../../store/IAppState'
import { ISurvey, SurveyClient } from '../Client'
import { QuestionCard } from '../Components'
import { getSurvey } from '../Store/Actions'
import {
  FormButton,
  FormButtonText,
  FormDescription,
  FormHeading,
  FormTitle,
  MessageText,
  MessageTextStrong,
  MessageWrapper,
} from './style'
import { ISettingValues } from '../../Settings/ISettings'
import { useHistory, useParams } from '../../Router'

export const SurveyForm: React.FC = () => {
  const { control, handleSubmit, errors, setValue } = useForm()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const {
    surveyId,
    customerId,
    customerToken,
    establishmentId,
    invitationUuid,
  } = useParams<{ [key: string]: string }>()
  const history = useHistory()

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const survey = useSelector<IAppState, ISurvey | undefined>(
    (state: IAppState) =>
      state.survey.surveys ? state.survey.surveys[0] : undefined
  )
  const loading = useSelector<IAppState>(
    (state: IAppState) => state.survey.loading
  )

  const settings = useSelector<IAppState, ISettingValues>(
    (state: IAppState) => state.settings.values
  )
  const { fontColor, highlightColor, backgroundColor } = settings

  useEffect(() => {
    dispatch(getSurvey(surveyId))
  }, [])

  if (loading) {
    return <Spinner />
  }

  if (typeof survey === 'undefined') {
    return (
      <StyledScrollView>
        <MessageWrapper>
          <MessageText>
            🚧 {t(`This survey is `)}
            <MessageTextStrong>{t(`no longer`)}</MessageTextStrong>
            {t(` available`)}!{t(`\n Please check your email inbox again`)}.
            {t(`\n There should be a link for another one`)} 🔗
          </MessageText>
        </MessageWrapper>
      </StyledScrollView>
    )
  }

  const catchingSubmit = (values) => {
    const answersToSubmit = Object.entries(values)
      .filter(
        ([key, value]) =>
          key !== '' && typeof key !== 'undefined' && value !== ''
      )
      .flatMap(([key, value]) => {
        if (Array.isArray(value)) {
          return value.map((choiceValue) => ({
            questionId: key,
            value: choiceValue,
          }))
        }
        return {
          questionId: key,
          value,
        }
      })

    if (answersToSubmit.length < survey.questions.length) {
      alert(t('Please fill in the whole form'))
      return
    }

    setIsSubmitting(true)
    SurveyClient.postAnswers(
      {
        customerId: parseInt(customerId, 10),
        token: customerToken,
        answers: answersToSubmit,
      },
      surveyId,
      invitationUuid
    )
      .then(() => {
        history.push(`/${establishmentId}/surveys/success`)
      })
      .catch(() => setIsSubmitting(false))
  }

  return (
    <StyledScrollView>
      <FormHeading>
        <FormTitle fontcolor={fontColor}>{survey.name}</FormTitle>
        <FormDescription fontcolor={fontColor}>
          {survey.description}
        </FormDescription>
      </FormHeading>
      {survey.questions
        .sort((qA, qB) => qA.order - qB.order)
        .map((question, index) => {
          const cardId = question.id
          return (
            <QuestionCard
              key={cardId}
              id={cardId}
              bgcolor={backgroundColor}
              highlight={highlightColor}
              fontcolor={fontColor}
              question={question.question}
              type={question.type}
              isMultiline={question.isMultiline}
              isMultipleChoice={question.isMultipleChoice}
              choices={question.choices}
              number={index + 1}
              control={control}
              errors={errors}
              setValue={setValue}
            />
          )
        })}

      <FormButton
        highlight={highlightColor}
        bgcolor={backgroundColor}
        onPress={handleSubmit(catchingSubmit)}
      >
        {isSubmitting ? (
          <AbsoluteSpinner />
        ) : (
          <FormButtonText highlight={highlightColor}>
            {t('Send')}
          </FormButtonText>
        )}
      </FormButton>
    </StyledScrollView>
  )
}
