import React, { FunctionComponent } from 'react'

import { AppMainView as Container } from './style'
import { useAppSelector } from '../../../../store/hooks'

export const AppMainView: FunctionComponent = ({ children }) => {
  const { settings } = useAppSelector((state) => ({
    settings: state.settings.values,
  }))
  const isSeamless = (settings && settings.isSeamlessIntegrated) || false;

  return (
    <Container seamless={isSeamless}>
      {children}
    </Container>
  )
}
