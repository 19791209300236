import React, { useEffect, useMemo } from 'react'

import { AbsoluteSpinner, Logo } from '../App/Components'
import { StyledScrollView } from '../App/Components/AppContainer'
import { Calendar } from '../Calendar/Calendar/Calendar'
import { useHistory, useParams } from '../Router'

import { OverviewHeader } from './Components'
import { ICustomer } from './store/interface/IAppointmentState'
import { isSelectAPerson } from '../App/Util/selectPersonChecks/isSelectAPerson/isSelectAPerson'
import { useAppSelector } from '../store/hooks'

export const Appointment = () => {
  const { establishmentId } = useParams<{ [key: string]: string }>()
  const history = useHistory()

  const { settings, appointment } = useAppSelector((state) => ({
    appointment: state.appointment,
    settings: state.settings.values,
  }))

  const flatCustomerActivities = useMemo(
    () => appointment.customers.map((customer) => customer.activities).flat(2),
    [appointment]
  )

  useEffect(() => {
    if (!appointment) {
      return
    }

    const currentCustomer: ICustomer =
      appointment.customers[appointment.currentCustomerIndex]
    if (
      typeof currentCustomer === 'undefined' ||
      currentCustomer.activities.length === 0
    ) {
      history.push(`/${establishmentId}`)
    }
  }, [appointment])

  if (typeof settings === 'undefined' || !appointment) {
    return <AbsoluteSpinner />
  }

  return (
    <StyledScrollView autoScroll>
      <Logo />
      <OverviewHeader
        settings={settings}
        establishmentId={establishmentId}
        isSelectAPerson={isSelectAPerson(flatCustomerActivities)}
      />
      <Calendar settings={settings} />
    </StyledScrollView>
  )
}
