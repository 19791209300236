import { TouchableOpacity } from 'react-native'

import styled from 'styled-components/native'

export const TouchableContainer = styled(TouchableOpacity)`
    margin-top: 15px;
    width: 331px;
`

export const AddPersonContainer = styled.View`
    display: flex;
    flex-direction: row;
`
