import { Moment } from 'moment'
import { TAvailabilityFrontend } from '../../../Appointment/store/interface/IAppointmentState'
import { ISettingValues } from '../../../Settings/ISettings'

const formatMoment = (momentDate: Moment): string => momentDate.clone().format('YYYY-MM-DD')

// Logic for old free moment finder to select x spread moments throughout the day. 
// example amountOfTimes = 3, hours = [8:00, 12:00, 16:00] instead of [8:00, 8:15, 8:30]
const filterHours = (
  hours: TAvailabilityFrontend[],
  amountOfTimes: number
): TAvailabilityFrontend[] => {
  const step = Math.max(1, hours.length / (amountOfTimes - 1))
  let currentTime = 0

  return hours.filter((_, index) => {
    if (
      Math.round(currentTime * step) === index ||
      hours.length - 1 === index
    ) {
      currentTime += 1
      return true
    }

    return false
  })
}

export const getSortedAndFilteredAvailabilityForDay = (
  settings: ISettingValues,
  availability: TAvailabilityFrontend[],
  date: Moment,
  amountOfHours: number,
): TAvailabilityFrontend[] => {
  if(settings.isUsingNewFreeMomentFinder === false) {
    // For old free moment finder, just sort chronologically 
    const sortedHours = availability
      .filter((oneAvailability) => formatMoment(oneAvailability.timeSlot) === formatMoment(date))
      .sort((a, b) => a.timeSlot.isAfter(b.timeSlot) ? 1 : -1)
    // And filter the hours in case there are more moments than allowed to show
    if (sortedHours && sortedHours.length > amountOfHours) {
      return filterHours(sortedHours, amountOfHours)
    }
    return sortedHours
  }

  // New free moment finder
  const isSortedDescending =
    settings.onlineAppointmentsFmfFillingConstraint === 'is_adjacent_end' ||
    settings.onlineAppointmentsFmfFillingConstraint === 'end_of_day'

  const sortedByScore = availability
    .filter((oneAvailability) => formatMoment(oneAvailability.timeSlot) === formatMoment(date))
    .sort((a, b) => {
      if (a.score === b.score) {
        if (isSortedDescending) return a.timeSlot.isBefore(b.timeSlot) ? 1 : -1
        return a.timeSlot.isAfter(b.timeSlot) ? 1 : -1
      }

      return a.score < b.score ? 1 : -1
    })

  // Mark the best 3 moments
  if (sortedByScore.length > 3) {
    for(let i = 0; i < 3; i += 1){
      sortedByScore[i].isBest = true
    }
  }

  // Slice array for the allowed amount of moments
  const bestMomentsLimited = sortedByScore.slice(0, amountOfHours)

  // Return if chronological order is not wanted
  if(settings.onlineAppointmentsFmfResultsChronological === false){
    return bestMomentsLimited
  }

  // Sort the best moments chronologically
  return bestMomentsLimited
    .sort((a, b) => a.timeSlot.isAfter(b.timeSlot) ? 1 : -1)
}
