import React from 'react'

import { useTranslation } from 'react-i18next'
import {
  faUser,
  faClock,
  faPaperPlane,
  faBookmark,
} from '@fortawesome/pro-regular-svg-icons'
import moment from 'moment'
import { BookingSuccess, BookingSuccessText, TextWrapper } from './style'
import { FaIcon } from '../../../FaIcon/FaIcon'

export type SuccessMessageInfo = {
  startsAt: string
  customerName: string
  activities: string[]
  isShowActivitiesWithNoDuration: boolean
}

type Props = {
  extraInfo?: SuccessMessageInfo[]
  message: string
}

export const SuccessMessage = ({ message, extraInfo }: Props) => {
  const { t } = useTranslation()
  const iconStyle = { padding: '5px', width: '14px', height: '14px' }
  return (
    <BookingSuccess>
      {!extraInfo ||
        extraInfo.map((info, index) => {
          const customerName =
            index === 0 ? info.customerName : `${t('Customer')} ${index + 1}`
          const uniqueActivities = new Set(info.activities);
          return (
            <>
              <TextWrapper>
                <FaIcon
                  cssStyle={iconStyle}
                  size="sm"
                  color="white"
                  icon={faUser}
                />
                <BookingSuccessText>{customerName}</BookingSuccessText>
              </TextWrapper>
              <TextWrapper>
                <FaIcon
                  cssStyle={iconStyle}
                  size="sm"
                  color="white"
                  icon={faClock}
                />
                <BookingSuccessText>
                  {moment(info.startsAt).format('dddd D MMMM YYYY HH:mm')}
                </BookingSuccessText>
              </TextWrapper>
              {Array.from(uniqueActivities).map((activity) => (
                <TextWrapper>
                  <FaIcon
                    cssStyle={iconStyle}
                    size="sm"
                    color="white"
                    icon={faBookmark}
                  />
                  <BookingSuccessText>{activity}</BookingSuccessText>
                </TextWrapper>
              ))}
            </>
          )
        })}
      <TextWrapper>
        <FaIcon
          cssStyle={iconStyle}
          size="sm"
          color="white"
          icon={faPaperPlane}
        />
        <BookingSuccessText>{t(message)}</BookingSuccessText>
      </TextWrapper>
    </BookingSuccess>
  )
}
