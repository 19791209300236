import React, { useState } from 'react'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'
import { Moment } from 'moment'
import Client from '../../../../PreviousAppointments/Client/Client'
import {
  movedPreviousAppointment,
  setDate,
} from '../../../../Appointment/store/Actions'
import CalendarHour from '../CalendarHour/CalendarHour'
import { AbsoluteSpinner, ButtonWithText } from '../../../../App/Components'
import { useAppDispatch } from '../../../../store/hooks'
import { IPreviousAppointment } from '../../../../PreviousAppointments/IPreviousAppointment'
import { TAvailabilityFrontend } from '../../../../Appointment/store/interface/IAppointmentState'
import { ISettingValues } from '../../../../Settings/ISettings'

type Props = {
  settings: ISettingValues
  emphasizedColorNegative: string
  selectedDate: Moment | null
  sortedHours: TAvailabilityFrontend[] | null
  previousAppointment?: IPreviousAppointment
  establishmentId: string
}

export const DayAvailability = ({
  settings,
  emphasizedColorNegative,
  selectedDate,
  sortedHours,
  previousAppointment,
  establishmentId,
}: Props) => {
  const [disableButtons, setDisableButtons] = useState<boolean>(false)
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useAppDispatch()

  const {
    backgroundColor,
    highlightColor,
    onlineAppointmentsShowWeekdayNames,
  } = settings

  const onPressFullDayButton = () => {
    const formattedDate = selectedDate.clone().format('YYYY-MM-DD HH:mm:ss')
    dispatch(setDate(formattedDate))
    history.push(`/${establishmentId}/waiting-list/book`)
    return null
  }

  if (!selectedDate) {
    return null
  }

  const dayMap = {
    1: 'mon',
    2: 'tue',
    3: 'wed',
    4: 'thu',
    5: 'fri',
    6: 'sat',
    7: 'sun',
  }

  if (
    sortedHours &&
    sortedHours.length === 0 &&
    settings.onlineWaitingList &&
    !settings.closingDays.includes(dayMap[selectedDate.isoWeekday()])
  ) {
    return (
      <ButtonWithText
        backgroundcolor={backgroundColor}
        fontcolor={highlightColor}
        hoversensitive
        onPress={onPressFullDayButton}
      >
        {t('Add me to the waiting list')}
      </ButtonWithText>
    )
  }

  if (disableButtons) {
    return <AbsoluteSpinner />
  }

  return (
    <>
      {sortedHours.map((oneAvailability) => {
        const onPressCalendarHour = () => {
          const formattedDate = oneAvailability.timeSlot.format(
            'YYYY-MM-DD HH:mm:ss'
          )
          if (previousAppointment) {
            setDisableButtons(true)
            Client.moveAppointment(
              establishmentId,
              formattedDate,
              previousAppointment
            ).then((response) => {
              const updatedAppointment: IPreviousAppointment = {
                ...previousAppointment,
                date: formattedDate,
              }
              if (response.status === 200) {
                dispatch(movedPreviousAppointment(updatedAppointment))
                history.push(
                  `/${establishmentId}/appointment/moved/${previousAppointment.id}`
                )
              }
            })
            return null
          }

          dispatch(setDate(formattedDate))
          history.push(`/${establishmentId}/appointment/book`)
          return null
        }

        return (
          <CalendarHour
            key={`calendarHour${oneAvailability}`}
            backgroundColor={backgroundColor}
            highlightColor={highlightColor}
            onPress={onPressCalendarHour}
            fullDate={onlineAppointmentsShowWeekdayNames}
            availability={oneAvailability}
          />
        )
      })}
    </>
  )
}
