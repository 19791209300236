import Client from '../../PreviousAppointments/Client/Client'
import { AppDispatch } from '../../store/store'

export const PAYMENT_LOADING = 'PAYMENT_LOADING'
export const PAYMENT_SUCCESS = 'PAYMENT_SUCCESS'
export const PAYMENT_FAILED = 'PAYMENT_FAILED'
export const PAYMENT_URL_INVALID = 'PAYMENT_URL_INVALID'

function paymentLoading() {
  return {
    type: PAYMENT_LOADING,
  }
}

function paymentSuccess(appointment) {
  return {
    type: PAYMENT_SUCCESS,
    appointment,
  }
}

function paymentFailed() {
  return {
    type: PAYMENT_FAILED,
  }
}

function paymentUrlInvalid() {
  return {
    type: PAYMENT_URL_INVALID,
  }
}

export function isPaymentComplete(
  establishmentId: string,
  email: string,
  token: string,
  appointmentId: string
) {
  return (dispatch: AppDispatch) => {
    dispatch(paymentLoading())

    Client.getPreviousAppointments(establishmentId, email, token).then(
      (previousAppointments) => {
        if (previousAppointments.appointments.length === 0) {
          dispatch(paymentUrlInvalid())
          return null
        }
        const foundAppointment = previousAppointments.appointments.find(
          (appointment) => appointment.id === parseInt(appointmentId, 10)
        )

        const appointmentStatus = foundAppointment.deposit.status
        if (appointmentStatus === 'paid') {
          dispatch(paymentSuccess(foundAppointment))
        } else if (appointmentStatus.includes('failed')
        || appointmentStatus.includes('expired')
        || appointmentStatus.includes('cancelled')
        || appointmentStatus.includes('requested')
        ) { 
          dispatch(paymentFailed())
        }
        return null
      }
    )
  }
}
