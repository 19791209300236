import styled from 'styled-components/native'
import { TouchableOpacity } from "react-native"

export const ListLMContainer = styled(TouchableOpacity)<{
    highlight: string,
    isChild: boolean
}>`
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-grow: 1;
    padding: ${({ isChild }) => isChild ? '5px 35px 5px 15px' : '5px 0 5px 0'};
    margin: 15px 0;
    width: 100%;
`

export const ListLMCTextContainer = styled(TouchableOpacity)`
    flex-grow: 4;
    margin-left: 24px;
    max-width: 200px;
`
