import React, { Suspense } from 'react'
import { I18nextProvider } from 'react-i18next'
import { Provider } from 'react-redux'
import { SENTRY_DSN } from 'react-native-dotenv'

import { BrowserTracing } from '@sentry/tracing'
import { Sentry } from './src/Sentry'

import { AbsoluteSimpleSpinner } from './src/App/Components'
import i18n from './src/i18n'
import Main from './src/Main/Main'
import { Route, Router, Switch } from './src/Router'
import { setupStore } from './src/store/store'

const App = () => {
  Sentry.init({
    dsn: SENTRY_DSN,
    enableInExpoDevelopment: false,
    debug: false,
    enableAutoSessionTracking: true,
    sessionTrackingIntervalMillis: 1000,
    sendClientReports: false,

    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.3,
  })

  return (
    <I18nextProvider i18n={i18n}>
      <Provider store={setupStore()}>
        <Suspense fallback={<AbsoluteSimpleSpinner />}>
          <Router>
            <Switch>
              <Route path="/multi-location/:locationIds" component={Main} />
              <Route path="/:establishmentId" component={Main} />
              <Route path="/" component={Main} />
            </Switch>
          </Router>
        </Suspense>
      </Provider>
    </I18nextProvider>
  )
}

export default App
