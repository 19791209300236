import styled from 'styled-components/native'

import webstyled from 'styled-components'

export const AppContentContainer = styled.View<{ isMobile: boolean }>`
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
  width: ${({ isMobile }) => (isMobile ? '440px' : '100vw')};
  height: ${({ isMobile }) => (isMobile ? '680px' : '100vh')};
  z-index: 1;
  box-shadow: rgb(0 0 0 / 40%) 0 0 10px;
  justify-content: center;
`

export const AppContentContainerWeb = webstyled.div<{ isMobile: boolean, seamless: boolean }>`
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
  width: ${({ isMobile }) => (isMobile ? '100vw' : '440px')};
  height: ${({ isMobile }) => (isMobile ? '100vh' : '680px')};
    z-index: 1;
    justify-content: center;
    box-shadow: ${({ seamless }) => (seamless ? 'none' : `0 0 10px rgb(0 0 0 / 40%), 0 0 0 10px rgb(0 0 0 / 10%)`)} ;
`
