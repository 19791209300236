import { initReactI18next } from 'react-i18next'
import { CUSTOM_TRANSLATIONS_URL, TRANSLATIONS_URL } from 'react-native-dotenv'

import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import HttpApi from 'i18next-http-backend'

const translationLanguages = ['en', 'nl', 'nl_NL', 'fr', 'de']
const pathArray = (window.location || { pathname: '/' }).pathname.split('/')
const establishmentId = pathArray[1]
i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    ns: ['messages', 'activities'],
    defaultNS: 'messages',
    backend: {
      loadPath: (languages, namespaces) => {
        if (namespaces[0] === 'activities' && establishmentId) {
          return `${CUSTOM_TRANSLATIONS_URL}/{{lng}}?establishment_id=${establishmentId}`
        }

        return `${TRANSLATIONS_URL}/{{ns}}/locale/{{lng}}`
      },
      crossDomain: true,
      allowMultiLoading: true,
    },
    keySeparator: false,
    nsSeparator: ';;',
    detection: {
      order: ['localStorage', 'navigator', 'htmlTag'],
      lookupLocalStorage: 'locale',
    },
    react: {
      wait: true,
      useSuspense: false,
    },
    fallbackLng: 'en',
    debug: false,
    whitelist: translationLanguages,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  })

export default i18n
