import React, { PropsWithChildren } from 'react'

import { StatusContainer } from './style'

type Props = {
  success: boolean
}

export const StatusMessageContainer = ({
  success,
  children,
}: PropsWithChildren<Props>) => (
  <StatusContainer success={success}>{children}</StatusContainer>
)
