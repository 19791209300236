import React from 'react'
import { useTranslation } from 'react-i18next'
import { TileTextApp, TileTextStyle, TileTextWebContainer } from './style'

interface IProps {
  isWeb: boolean
  title: string
  link: string
}

export const TileText = ({ isWeb, title, link }: IProps) => {
  const { t } = useTranslation()
  if (isWeb) {
    return (
      <TileTextWebContainer data-url-info={link}>
        <TileTextStyle data-url-info={link}>
          {t(`activities;;${title}`)}
        </TileTextStyle>
      </TileTextWebContainer>
    )
  }
  return (
    <TileTextApp data-url-info={link}>{t(`activities;;${title}`)}</TileTextApp>
  )
}
