import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { CheckBox } from 'react-native'

import { BackButton, InputField, Spinner } from '../App/Components'
import { handleLink, IUserData } from '../App/Util'
import { isDepositCheck } from '../App/Util/isDepositCheck/isDepositCheck'
import { useParams } from '../Router'
import { ISettingValues } from '../Settings/ISettings'

import { DepositNotice } from './Components/DepositNotice/DepositNotice'
import { CustomerFormData } from './ICustomerFormData'
import {
  BackToAppointmentWrapper,
  CheckboxLabel,
  CheckboxWrapper,
  ExtendedButtonWithText,
  FieldContainer,
  LabelWrapper,
  LinkWrapper,
} from './style'
import { GiftCardField } from './Components/GiftCardField/GiftCardField'
import { InputError } from '../App/Components/InputField/style'

import { useAppSelector } from '../store/hooks'
import { StyledModal } from '../App/Components/WelcomeMessage/style'
import { PrivacyPolicy } from '../PrivacyPolicy/PrivacyPolicy'

type Props = {
  submitHandler: (data: CustomerFormData) => void
  deposit?: number
  hasDepositLogic?: boolean
  confirmButtonText?: string
}

export const CustomerForm = ({
  deposit,
  submitHandler,
  confirmButtonText = 'Confirm appointment',
  hasDepositLogic = true,
}: Props) => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
  let userData: IUserData | null = null
  try {
    userData = JSON.parse(localStorage.getItem('userData'))
  } catch (e) {
    console.error('Could not parse user data. ')
  }

  const { control, handleSubmit, errors, setError } = useForm({
    defaultValues: {
      comment: '',
      email: userData ? userData.email : '',
      firstName: userData ? userData.firstName : '',
      lastName: userData ? userData.lastName : '',
      phone: userData ? userData.phone : '',
      street: userData ? userData.street : '',
      city: userData ? userData.city : '',
      zip: userData ? userData.zip : '',
    },
  })
  const { establishmentId } = useParams<{ establishmentId: string }>()
  const { t } = useTranslation()

  const [showErrors, setShowErrors] = useState<boolean>(false)
  const [isSendEmailEnabled, setIsSendEmailEnabled] = useState<boolean>(true)
  const [isPersonalDataSelected, setIsPersonalDataSelected] =
    useState<boolean>(false)
  const [isAnnulationConditionsSelected, setIsAnnulationConditionsSelected] =
    useState<boolean>(false)
  const [isPayWholeAmount, setIsPayWholeAmount] = useState<boolean>(false)
  const [giftCardState, setGiftCardState] = useState<'idle' | 'checkingCard'>(
    'idle'
  )
  const [giftCardInput, setGiftCardInput] = useState<string>('')

  const settings = useAppSelector((state) => state.settings)
  const appointmentStatus = useAppSelector(
    (state) => state.appointment.appointmentStatus
  )

  useEffect(() => {
    if (appointmentStatus === 'invalid_email') {
      setError('email', {
        type: 'invalid_email',
        message: 'Email not valid',
      })
    }
  }, [appointmentStatus])

  if (typeof settings === 'undefined') {
    return <Spinner />
  }
  const settingsValues: ISettingValues = settings.values
  const { onlineAppointmentsRequestAddressInfo } = settings.values
  const isDeposit: boolean = isDepositCheck(settings, deposit)

  const onSubmit = async (data: CustomerFormData) => {
    setShowErrors(true)
    if (
      isPersonalDataSelected === false ||
      giftCardState === 'checkingCard' ||
      (settingsValues.annulationCondition &&
        isAnnulationConditionsSelected === false)
    ) {
      return
    }

    const submitData: CustomerFormData = {
      ...data,
      sendEmail: isSendEmailEnabled,
      giftcardBarcode: giftCardInput || undefined,
      customerPaysFullAmount: isPayWholeAmount && !giftCardInput,
    }
    submitHandler(submitData)
  }

  const togglePersonalDataCheckBox = () =>
    setIsPersonalDataSelected(!isPersonalDataSelected)
  const toggleAnnulationConditionsCheckBox = () =>
    setIsAnnulationConditionsSelected(!isAnnulationConditionsSelected)
  const toggleSendEmailCheckBox = () =>
    setIsSendEmailEnabled(!isSendEmailEnabled)
  const togglePayWholeAmountCheckBox = () =>
    setIsPayWholeAmount(!isPayWholeAmount)

  return (
    <>
      <StyledModal
        backgroundcolor={settings.values.backgroundColor}
        animationIn="slideInDown"
        animationOut="slideOutUp"
        onBackdropPress={() => setIsModalVisible(false)}
        isVisible={isModalVisible}
      >
        <PrivacyPolicy locale={settings.values.locale} />
      </StyledModal>
      <BackToAppointmentWrapper highlightcolor={settingsValues.highlightColor}>
        <BackButton customPath={`/${establishmentId}/appointment`} />
      </BackToAppointmentWrapper>
      <FieldContainer>
        <InputField
          inputLabel="FirstName"
          inputRef="firstName"
          fontColor={settingsValues.fontColor}
          control={control}
          error={errors.firstName ? 'This field is required.' : undefined}
          rules={{ required: true }}
          autoComplete="given-name"
        />
        <InputField
          inputLabel="LastName"
          inputRef="lastName"
          fontColor={settingsValues.fontColor}
          control={control}
          error={errors.lastName ? 'This field is required.' : undefined}
          rules={{ required: true }}
          autoComplete="family-name"
        />
        <InputField
          inputLabel="Cellphone"
          inputRef="phone"
          fontColor={settingsValues.fontColor}
          control={control}
          error={errors.phone ? 'Number not valid' : undefined}
          rules={{ required: true, minLength: 6, maxLength: 18 }}
          fieldType="phone"
        />
        <InputField
          inputLabel="Email"
          inputRef="email"
          fontColor={settingsValues.fontColor}
          control={control}
          error={errors.email ? 'Email not valid' : undefined}
          rules={{ required: true, pattern: /^\S+@\S+$/i }}
          fieldType="email"
        />
        {onlineAppointmentsRequestAddressInfo ? (
          <>
            <InputField
              inputLabel="Street"
              inputRef="street"
              fontColor={settingsValues.fontColor}
              control={control}
              error={errors.street ? 'Street not valid' : undefined}
              rules={{ required: true }}
              fieldType="street"
              autoComplete="street-address"
            />
            <InputField
              inputLabel="City"
              inputRef="city"
              fontColor={settingsValues.fontColor}
              control={control}
              error={errors.city ? 'City not valid' : undefined}
              rules={{ required: true }}
              fieldType="city"
            />
            <InputField
              inputLabel="Zip"
              inputRef="zip"
              fontColor={settingsValues.fontColor}
              control={control}
              error={errors.zip ? 'Zip not valid' : undefined}
              rules={{ required: true }}
              fieldType="zip"
              autoComplete="postal-code"
            />
          </>
        ) : null}
        {settingsValues.onlineAppointmentsRemarksEnabled ? (
          <InputField
            inputLabel="Comment"
            inputRef="comment"
            fontColor={settingsValues.fontColor}
            control={control}
            rules={{ required: false }}
          />
        ) : null}
        <GiftCardField
          isShowGiftCardField={
            isDeposit && settingsValues.giftcardTrackingEnabled
          }
          setGiftCardState={setGiftCardState}
          setGiftCarInput={setGiftCardInput}
          giftCardInput={giftCardInput}
          establishmentId={establishmentId}
          fontColor={settings.values.fontColor}
        />
        <CheckboxWrapper>
          <CheckBox
            value={isSendEmailEnabled}
            onValueChange={toggleSendEmailCheckBox}
          />
          <LabelWrapper>
            <CheckboxLabel
              fontcolor={settingsValues.fontColor}
              onPress={toggleSendEmailCheckBox}
            >
              {t('Send confirmation e-mail')}
            </CheckboxLabel>
          </LabelWrapper>
        </CheckboxWrapper>
        <CheckboxWrapper>
          <CheckBox
            value={isPersonalDataSelected}
            onValueChange={togglePersonalDataCheckBox}
          />
          <LabelWrapper>
            <CheckboxLabel
              fontcolor={settingsValues.fontColor}
              onPress={togglePersonalDataCheckBox}
            >
              {t('Agree to save personal data')}
            </CheckboxLabel>
            <LinkWrapper
              fontcolor={settingsValues.highlightColor}
              onPress={() => setIsModalVisible(true)}
            >
              {t('More info')}
            </LinkWrapper>
          </LabelWrapper>
          <InputError showError={showErrors && !isPersonalDataSelected}>
            {t('This field is required.')}
          </InputError>
        </CheckboxWrapper>
        {settingsValues.annulationCondition ? (
          <CheckboxWrapper>
            <CheckBox
              value={isAnnulationConditionsSelected}
              onValueChange={toggleAnnulationConditionsCheckBox}
            />
            <LabelWrapper>
              <CheckboxLabel
                fontcolor={settingsValues.fontColor}
                onPress={toggleAnnulationConditionsCheckBox}
              >
                {t('I agree with the annulation conditions')}
              </CheckboxLabel>
              <LinkWrapper
                fontcolor={settingsValues.highlightColor}
                onPress={() =>
                  handleLink(`/${establishmentId}/annulation-condition`)
                }
              >
                {t('More info')}
              </LinkWrapper>
            </LabelWrapper>
            <InputError
              showError={showErrors && !isAnnulationConditionsSelected}
            >
              {t('This field is required.')}
            </InputError>
          </CheckboxWrapper>
        ) : null}
        {settingsValues.canCustomerPayWholeAmount &&
        !giftCardInput &&
        hasDepositLogic ? (
          <CheckboxWrapper>
            <CheckBox
              value={isPayWholeAmount}
              onValueChange={togglePayWholeAmountCheckBox}
            />
            <LabelWrapper>
              <CheckboxLabel
                fontcolor={settingsValues.fontColor}
                onPress={togglePayWholeAmountCheckBox}
              >
                {t('I want to pay full amount online')}
              </CheckboxLabel>
            </LabelWrapper>
          </CheckboxWrapper>
        ) : null}
      </FieldContainer>
      <DepositNotice
        isShowDepositNotice={isDeposit && !isPayWholeAmount}
        deposit={deposit}
      />
      <ExtendedButtonWithText
        fontcolor={settingsValues.highlightColor}
        backgroundcolor={settingsValues.backgroundColor}
        onPress={handleSubmit(onSubmit)}
        tagManagerId="confirmAppointmentButton"
      >
        {t(confirmButtonText)}
      </ExtendedButtonWithText>
    </>
  )
}
