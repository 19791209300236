import {
    IAPISurvey,
    IAPISurveyQuestion,
    ISurvey,
    ISurveyQuestion,
} from './ISurvey'

class Transformer {
    public static transform(survey: IAPISurvey): ISurvey {
        return {
            id: survey.id,
            establishment: survey.establishment,
            name: survey.name,
            createdAt: survey.created_at,
            updatedAt: survey.updated_at,
            description: survey.description,
            questions:
                survey.questions === undefined
                    ? []
                    : survey.questions.map((question) => {
                          return {
                              id: question.id,
                              question: question.question,
                              type: question.type,
                              order: question.order,
                              isMultipleChoice:
                                  question.is_multiple_choice !== null
                                      ? question.is_multiple_choice
                                      : false,
                              isMultiline:
                                  question.is_multi_line !== null
                                      ? question.is_multi_line
                                      : false,
                              choices:
                                  question.choices === undefined
                                      ? []
                                      : question.choices.map(
                                            (choice) => choice.name
                                        ),
                          }
                      }),
        }
    }

    public static reverseTransform(survey: ISurvey): IAPISurvey {
        const APISurvey: IAPISurvey = {
            id: survey.id,
            establishment: survey.establishment,
            name: survey.name,
            created_at: survey.createdAt,
            updated_at: survey.updatedAt,
            description: survey.description,
            questions:
                survey.questions === undefined
                    ? []
                    : survey.questions.map(this.reverseTransformQuestion),
        }

        if (null !== survey.id) {
            APISurvey.id = survey.id
        }
        return APISurvey
    }

    public static reverseTransformQuestion(
        question: ISurveyQuestion
    ): IAPISurveyQuestion & { is_multi_line: boolean } {
        return {
            id: question.id,
            question: question.question,
            type: question.type,
            order: question.order,
            is_multi_line: question.isMultiline,
            is_multiple_choice: question.isMultipleChoice,
            choices:
                question.choices === undefined
                    ? []
                    : question.choices.map((choice) => ({ name: choice })),
        }
    }
}

export default Transformer
