import { URL_API } from 'react-native-dotenv'

import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'

import { previousAppointmentTransformer } from './previousAppointmentTransformer'

import {
  IApiPreviousAppointment,
  IPreviousAppointment,
} from '../IPreviousAppointment'
import {
  IApiWorkshopSubscription,
  IWorkshopSubscription,
} from '../../Workshop/store/IWorkshop'
import WorkshopTransformer from '../../Workshop/store/Transformer'

export default class Client {
  public static async getPreviousAppointments(
    establishmentId: string,
    email: string,
    token: string
  ): Promise<{
    appointments: IPreviousAppointment[]
    workshops: IWorkshopSubscription[]
  }> {
    const requestOptions: object = {
      method: 'GET',
      url: `${URL_API}/anonymous/calendar/appointments?establishment_id=${establishmentId}&email=${email}&token=${token}`,
    }

    type TResponse = {
      workshops: IApiWorkshopSubscription[]
      appointments: IApiPreviousAppointment[]
    } & IApiPreviousAppointment[]

    const response: AxiosResponse<TResponse> = await axios(requestOptions)
    if (!response.data.workshops) {
      // @deprecated Until all workshop PRs are merged in client
      return {
        appointments: response.data.map((apiPreviousAppointment) =>
          previousAppointmentTransformer(apiPreviousAppointment)
        ),
        workshops: [],
      }
    }

    return {
      appointments: response.data.appointments.map((apiPreviousAppointment) =>
        previousAppointmentTransformer(apiPreviousAppointment)
      ),
      workshops: response.data.workshops.map(
        (apiWorkshop: IApiWorkshopSubscription) =>
          WorkshopTransformer.transformSubscription(apiWorkshop)
      ),
    }
  }

  public static deleteAppointment(
    activityId: number,
    token: string,
    establishmentId: string
  ) {
    const requestOptions: object = {
      method: 'DELETE',
      url: `${URL_API}/anonymous/calendar/appointments/${activityId}/${token}`,
      data: {
        establishment_id: establishmentId,
      },
    }

    return axios(requestOptions)
  }

  public static moveAppointment(
    establishmentId: string,
    date: string,
    previousAppointment: IPreviousAppointment
  ) {
    const requestOptions: AxiosRequestConfig = {
      method: 'POST',
      url: `${URL_API}/anonymous/appointments/${previousAppointment.id}/move`,
      data: {
        establishment_id: establishmentId,
        starts_at: date,
        appointment_activities: previousAppointment.activities.map(
          (activity) => ({
            activity_id: activity.id,
            category_id: activity.categoryId,
            person_id: activity.person.isPreferred ? activity.person.id : null,
          })
        ),
      },
    }

    return axios(requestOptions)
  }
}
