import { Dispatch } from 'redux'

import { addPersonToAppointment } from '../../Appointment/store/Actions'

export const noPreferenceTilePress = (
    dispatch: Dispatch<any>,
    isPreferredPersonPerActivity: boolean,
    activityId: number,
    customerIndex: number
) => {
    if (isPreferredPersonPerActivity && activityId) {
        dispatch(addPersonToAppointment(customerIndex, undefined, activityId))
        return
    }
    dispatch(addPersonToAppointment(customerIndex, undefined, undefined))
    return
}
