import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { AbsoluteSpinner, Spinner, Tile } from '../../../App/Components'
import { addMenu } from '../../../Appointment/store/Actions'
import { useHistory, useLocation, useParams } from '../../../Router'
import { IMenuCategory } from '../../Store/IMenuCategory'
import { isPersonSelectableCheck } from '../../utils/isPersonSelectableCheck/isPersonSelectableCheck'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { personSelectPathCheck } from '../../../App/Util/personSelectPathCheck'
import { menuCategoryFilter } from '../../../App/Util/menuCategoryFilter'

export const TileStructure = () => {
  const [currentCategories, setCurrentCategories] = useState(null)

  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const location = useLocation()
  const history = useHistory()
  const { establishmentId } = useParams<{ [key: string]: string }>()

  const currentPath = location.pathname

  const {
    isMenuLoading,
    settings,
    categories,
    currentCustomerIndex,
    isPersonsLoading,
    isExistingCustomer,
  } = useAppSelector((state) => ({
    isMenuLoading: state.menu.loading,
    categories: state.menu.categories,
    settings: state.settings.values,
    currentCustomerIndex: state.appointment.currentCustomerIndex,
    isPersonsLoading: state.persons.loading,
    isExistingCustomer: state.settings.isCustomerExisting,
  }))

  useEffect(() => {
    if (!categories) {
      return
    }
    const breadcrumbs: number[] = location.pathname
      .split('/')
      .splice(3)
      .filter(Boolean)
      .map((breadcrumb): number => Number(breadcrumb))
    setCurrentCategories(getCurrentCategories(breadcrumbs, categories))
  }, [categories, location.pathname])

  if (typeof settings === 'undefined' || isMenuLoading) {
    return <Spinner />
  }

  const getCurrentCategories = (
    breadcrumbs: number[],
    menuCategories: IMenuCategory[]
  ): undefined | IMenuCategory[] => {
    if (breadcrumbs.length === 0) {
      return menuCategoryFilter(
        menuCategories,
        settings.isCertainServiceBlock,
        isExistingCustomer
      )
    }

    const menuCategory = menuCategories.find(
      (category: IMenuCategory) => category.id === breadcrumbs[0]
    )
    if (!menuCategory || !menuCategory.categories) {
      if (!menuCategory) {
        history.push(`/${establishmentId}`)
        return null
      }
      if (menuCategory.activities) {
        const isPersonSelectable = isPersonSelectableCheck(menuCategory)
        const pathUrl = personSelectPathCheck(
          settings.isPersonPreferenceMandatory,
          isPersonSelectable,
          establishmentId,
          currentCustomerIndex
        )
        dispatch(addMenu(menuCategory.activities, menuCategory.id))
        history.push(pathUrl)
      }

      return menuCategoryFilter(
        menuCategories,
        settings.isCertainServiceBlock,
        isExistingCustomer
      )
    }

    return getCurrentCategories(breadcrumbs.splice(1), menuCategory.categories)
  }

  if (settings && !settings.isMenuVisible) {
    history.push(`/${establishmentId}/activities`)

    return <Spinner />
  }

  if (
    currentCategories === null ||
    typeof currentCategories === 'undefined' ||
    isPersonsLoading
  ) {
    return <AbsoluteSpinner />
  }

  return currentCategories.map((category: IMenuCategory) => {
    if (!category.categories && !category.activities) {
      return null
    }
    const navigationLink: string = `${currentPath}/${category.id}`.replace(
      '//',
      '/'
    )

    if (category.activities) {
      return (
        <Tile
          key={category.id}
          description={category.description}
          title={t(`activities;;${category.name}`)}
          link={navigationLink}
          onPress={(e) => {
            let isPersonSelectable = false
            e.preventDefault()
            e.stopPropagation()
            category.activities.forEach((activity) => {
              if (activity.isPersonSelectable) {
                isPersonSelectable = true
              }
              return false
            })
            dispatch(addMenu(category.activities, category.id))
            const pathUrl = personSelectPathCheck(
              settings.isPersonPreferenceMandatory,
              isPersonSelectable,
              establishmentId,
              currentCustomerIndex
            )
            history.push(pathUrl)
            return false
          }}
          image={category.image}
        />
      )
    }

    return (
      <Tile
        key={category.id}
        description={category.description}
        title={t(`activities;;${category.name}`)}
        image={category.image}
        link={navigationLink}
        onPress={(event) => {
          event.preventDefault()
          event.stopPropagation()
          history.push(navigationLink)
        }}
      />
    )
  })
}
