import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { ScrollView, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import { faTimes } from '@fortawesome/pro-light-svg-icons'

import { IAppState } from '../../../store/IAppState'
import { DefaultButtonWithText } from '../ButtonWithText/style'
import { FaIcon } from '../FaIcon/FaIcon'
import { ModalMessage, StyledModal } from '../WelcomeMessage/style'

import { IProps } from './IProps'
import { CloseIconWrapper, ModalTitle } from './style'
import { addCustomer } from '../../../Appointment/store/Actions'

export const WarningModal: FunctionComponent<IProps> = ({
  title,
  description,
  isModalOpen,
}) => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { settings } = useSelector((state: IAppState) => ({
    settings: state.settings.values,
  }))

  useEffect(() => {
    if (isModalOpen) {
      setIsModalVisible(true)
    }
  }, [isModalOpen])

  const closeModal = useCallback(() => {
    setIsModalVisible(false)
  }, [])

  const addCustomerToStore = () => {
    dispatch(addCustomer())
    setIsModalVisible(false)
  }

  return (
    <StyledModal
      backgroundcolor={settings.backgroundColor}
      isVisible={isModalVisible}
      animationIn="slideInDown"
      animationOut="slideOutUp"
      onBackdropPress={closeModal}
      swipeDirection="down"
    >
      <ScrollView>
        <ModalTitle highlightcolor={settings.highlightColor}>
          {t(title)}
        </ModalTitle>
        <ModalMessage highlightcolor={settings.highlightColor}>
          {t(description)}
        </ModalMessage>
        <DefaultButtonWithText
          disableHoverOnTouchDevice
          onPress={addCustomerToStore}
          fontcolor={settings.highlightColor}
          backgroundcolor={settings.backgroundColor}
          hoversensitive
        >
          {t('Add person')}
        </DefaultButtonWithText>
      </ScrollView>
      <CloseIconWrapper onPress={closeModal}>
        <FaIcon size="lg" icon={faTimes} color={settings.highlightColor} />
      </CloseIconWrapper>
    </StyledModal>
  )
}
