import { URL_API } from 'react-native-dotenv'

import axios, { AxiosPromise } from 'axios'
import { IApiWorkshop, IWorkshop } from "../store/IWorkshop";
import WorkshopTransformer from "../store/Transformer";
import { getUserToken } from '../../App/Util/localStorageHelper/userToken/setUserToken';
import { CustomerAddress } from '../../CustomerForm/ICustomerFormData';

export default class Client {
  public static async getWorkshops(establishmentId: string): Promise<IWorkshop[]> {
    const url = `${URL_API}/anonymous/calendar/workshops?establishment_id=${establishmentId}`;
    const response = await axios.get<{ list: IApiWorkshop[] }>(url)

    return response.data.list.map(
      (apiWorkshop: IApiWorkshop) => WorkshopTransformer.transform(apiWorkshop)
    )
  }

  public static subscribeToWorkshop(
    establishmentId: string,
    workshopUuid: string,
    firstName: string,
    lastName: string,
    phone: string,
    email: string,
    comment: string,
    sendEmail: boolean,
    address?: CustomerAddress
  ): AxiosPromise {
    const customerToken = getUserToken(establishmentId, email)
    const requestOptions: object = {
      method: 'POST',
      url: `${URL_API}/anonymous/calendar/workshops/${workshopUuid}/subscribe`,
      data: {
        establishment_id: establishmentId,
        remark: comment,
        first_name: firstName,
        last_name: lastName,
        email,
        phone,
        send_email: sendEmail,
        street: address ? address.street : undefined,
        city: address ? address.city : undefined,
        zip: address ? address.zip : undefined,
        token: customerToken,
      },
    }

    return axios(requestOptions)
  }

  public static cancelWorkshop(
    workshopUuid: string,
    subscriptionUuid: string,
    token: string
  ): AxiosPromise {
    const requestOptions: object = {
      method: 'DELETE',
      url: `${URL_API}/anonymous/calendar/workshops/${workshopUuid}/subscriptions/${subscriptionUuid}/${token}`,
    }

    return axios(requestOptions)
  }
}
