import { Reducer } from 'redux'
import { IAccountState } from './IAccountState'
import { SET_ACCOUNT_SETTINGS, DELETE_ACCOUNT_SETTINGS } from './Action'

const initialState: IAccountState = {
  accountInfo: undefined,
}

export const reducer: Reducer<IAccountState> = (
  state: IAccountState = initialState,
  action
): IAccountState => {
  switch (action.type) {
    case SET_ACCOUNT_SETTINGS:
      return {
        ...state,
        accountInfo: action.payload,
      }
    case DELETE_ACCOUNT_SETTINGS:
      return {
        ...state,
        accountInfo: undefined,
      }
    default:
      return state
  }
}
