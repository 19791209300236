import React from 'react'

import moment from 'moment'
import { useHistory } from 'react-router'
import { stringToHsl } from '../../Util'

import { ListCardText, ListColor } from '../ListCard/style'
import { ListLMContainer, ListLMCTextContainer } from './style'
import { LastMinutePrice } from '../../../LastMinutes/LastMinutePrice/LastMinutePrice'
import { CategorySection } from '../../../ExtraActivity/Components/ListStructure/Components/CategorySection/CategorySection'
import { useAppSelector } from '../../../store/hooks'

type Props = {
  title: string
  image?: string
}

export const LastMinuteListHeader: React.FC<Props> = ({ title, image }) => {
  const settings = useAppSelector((state) => state.settings.values)
  const lastMinuteList = useAppSelector((state) => state.lastMinute.list)

  const {
    fontColor,
    showPrice,
    highlightColor,
    backgroundColor,
    onlineAppointmentsShowActivitiesWithNoDuration,
  } = settings

  const history = useHistory()

  let customImage: string | undefined
  if (image) {
    customImage = image
    if (typeof image === 'string' && image.includes('files.optios.')) {
      customImage += '&w=48&h=48&fit=crop'
    }

    if (typeof image === 'string' && image.startsWith('//')) {
      customImage = `https:${customImage}`
    }
  }

  if (typeof lastMinuteList === 'undefined') {
    return null
  }

  const lastMinuteListRender = lastMinuteList.map((lastMinute) => {
    const regularTotal = lastMinute.activities.reduce(
      (total, nextActivity) => total + nextActivity.price,
      0
    )
    const bookAppointment = () => {
      history.push(`last-minutes/${lastMinute.id}/book`)
    }
    return (
      <ListLMContainer onPress={bookAppointment} isChild>
        <ListColor color={stringToHsl(title, 50, 50)} isChild />
        <ListLMCTextContainer onPress={bookAppointment}>
          <ListCardText fontcolor={highlightColor}>
            {lastMinute.description}
          </ListCardText>
          <ListCardText fontcolor={fontColor}>
            {moment(lastMinute.startsAt).format('LLLL')}
          </ListCardText>
          {lastMinute.activities.map((lmActivity) => {
            if (
              onlineAppointmentsShowActivitiesWithNoDuration === false &&
              lmActivity.startsAt.isSame(lmActivity.endsAt)
            ) {
              return null
            }

            return (
              <ListCardText fontcolor={fontColor}>
                {lmActivity.name}
              </ListCardText>
            )
          })}
          <LastMinutePrice
            isShowPrice={showPrice}
            fontColor={fontColor}
            regularTotal={regularTotal}
            lastMinutePrice={lastMinute.lastMinutePrice}
          />
        </ListLMCTextContainer>
      </ListLMContainer>
    )
  })

  return (
    <CategorySection
      title={title}
      list={lastMinuteListRender}
      fontcolor={fontColor}
      image={customImage}
      lastMinuteMenuLine
      bgColor={backgroundColor}
    />
  )
}
