import React, { useEffect } from 'react'
import ReactPixel from 'react-facebook-pixel'
import { useTranslation } from 'react-i18next'
import { Linking } from 'react-native'
import { URL_API } from 'react-native-dotenv'
import {
  BackButton,
  ButtonWithText,
  Spinner,
  StatusMessageContainer,
  StatusText,
} from '../../App/Components'
import { StyledScrollView } from '../../App/Components/AppContainer'
import { useParams } from '../../Router'
import { isPaymentComplete } from '../store/Actions'
import { useAppDispatch, useAppSelector } from '../../store/hooks'

export const PaymentFallback = () => {
  const { establishmentId, appointmentId, token, email } =
    useParams<{ [key: string]: string }>()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const { settings, payment } = useAppSelector((state) => ({
    settings: state.settings.values,
    payment: state.payment,
  }))

  const downloadAppointment = () => {
    Linking.openURL(`${URL_API}/anonymous/ics/${payment.paidAppointment.id}`)
  }

  useEffect(() => {
    let timer
    if (payment.status !== 'paid' && payment.loading === false) {
      timer = setTimeout(() => {
        dispatch(
          isPaymentComplete(establishmentId, email, token, appointmentId)
        )
      }, 2000)
    }

    return () => clearTimeout(timer)
  }, [settings])

  ReactPixel.pageView()

  if (payment.status === 'failed') {
    return (
      <StyledScrollView>
        <BackButton customPath={`/${establishmentId}`} />
        <StatusMessageContainer success={false}>
          <StatusText>
            {t('Your payment has failed, please try again.')}
          </StatusText>
        </StatusMessageContainer>
      </StyledScrollView>
    )
  }

  if (payment.status === 'invalidUrl') {
    return (
      <StyledScrollView>
        <BackButton customPath={`/${establishmentId}`} />
        <StatusMessageContainer success={false}>
          <StatusText>{t('This URL has no payment confirmation.')}</StatusText>
        </StatusMessageContainer>
      </StyledScrollView>
    )
  }

  if (payment.status === 'paid') {
    return (
      <StyledScrollView>
        <BackButton customPath={`/${establishmentId}/menu`} />
        <StatusMessageContainer success>
          <StatusText>{t('Appointment confirmation sent.')}</StatusText>
        </StatusMessageContainer>
        <ButtonWithText
          onPress={downloadAppointment}
          backgroundcolor={settings.backgroundColor}
          fontcolor={settings.highlightColor}
          hoversensitive
        >
          {t('Add to calendar')}
        </ButtonWithText>
      </StyledScrollView>
    )
  }

  return <Spinner />
}
