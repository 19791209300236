import { TouchableOpacity } from 'react-native'

import styled from 'styled-components/native'

import { ProximaNova } from '../TextComponent'

import { ButtonWithText } from './ButtonWithText'

export const ButtonContainer = styled(TouchableOpacity)<{
  backgroundcolor: string
  buttonstate: boolean
  fontcolor: string
  isInline: boolean
  custompadding: string
  isMaxWidth: boolean
}>`
  padding: ${({ custompadding }) => custompadding || '8px'};
  margin: 15px 3px 3px 3px;
  margin-top: ${({ isInline }) => (isInline ? '3px' : '15px')};
  align-self: center;
  align-items: center;
  width: ${({ isInline }) => (isInline ? 'auto' : '95%')};
  border-color: ${({ fontcolor }) => fontcolor};
  border-width: 2px;
  max-width: ${({ isMaxWidth }) => (isMaxWidth ? '332px' : 'none')};
  background-color: ${({ backgroundcolor, fontcolor, buttonstate }) =>
    buttonstate ? fontcolor : backgroundcolor};
  border-radius: 50px;
`

export const DefaultButtonWithText = styled(ButtonWithText)<{
  customWidth?: string
}>`
  border-width: 2px;
  border-radius: 30px;
  margin: 1.5rem 10px;
  width: ${({ customWidth }) => customWidth || '320px'};
  padding: 13px 12px;
`

export const ButtonText = styled(ProximaNova)<{
  fontcolor: string
  buttonstate: boolean
  activefontcolor: string
}>`
  text-align: center;
  font-size: 16px;
  color: ${({ activefontcolor, buttonstate, fontcolor }) =>
    buttonstate ? activefontcolor : fontcolor};
`
