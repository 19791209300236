import { IAccountInfo } from '../../AccountInfo/IAccountInfo'

export const SET_ACCOUNT_SETTINGS = 'SET_ACCOUNT_SETTINGS'
export const DELETE_ACCOUNT_SETTINGS = 'DELETE_ACCOUNT_SETTINGS'

export const setAccountInfo = (info: IAccountInfo) => ({
  type: SET_ACCOUNT_SETTINGS,
  payload: info,
})

export const deleteAccountInfoStore = () => ({
  type: DELETE_ACCOUNT_SETTINGS,
})
