import React from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'

import { AbsoluteSimpleSpinner, BackButton } from '../../App/Components'
import { StyledScrollView } from '../../App/Components/AppContainer'
import { IWorkshop } from '../store/IWorkshop'

import { TextNoWorkshops, ViewNoWorkshops } from './style'
import { WorkshopCard } from '../WorkshopCard/WorkshopCard'
import { useAppSelector } from '../../store/hooks'

export const WorkshopOverview: React.FC = () => {
  const { t } = useTranslation()

  const { workshops, settings } = useAppSelector((state) => ({
    settings: state.settings.values,
    workshops: state.workshops.list,
  }))

  if (!workshops) {
    return (
      <StyledScrollView>
        <BackButton extraMargin />
        <View>
          <AbsoluteSimpleSpinner />
        </View>
      </StyledScrollView>
    )
  }

  if (workshops.length === 0) {
    return (
      <StyledScrollView>
        <BackButton extraMargin />
        <ViewNoWorkshops>
          <TextNoWorkshops>{t('No workshops found')}</TextNoWorkshops>
        </ViewNoWorkshops>
      </StyledScrollView>
    )
  }

  return (
    <StyledScrollView>
      <BackButton extraMargin />
      <View>
        {workshops.map((workshop: IWorkshop) => (
          <WorkshopCard
            key={workshop.uuid}
            workshop={workshop}
            fontColor={settings.fontColor}
            backgroundColor={settings.backgroundColor}
            highlightColor={settings.highlightColor}
          />
        ))}
      </View>
    </StyledScrollView>
  )
}
