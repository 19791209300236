import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { AbsoluteSpinner, ButtonWithText } from '../../../App/Components'
import { deleteAppointment } from '../../../Appointment/store/Actions'
import { useHistory, useParams } from '../../../Router'
import Client from '../../Client/Client'

import { ConfirmationModal } from '../../../App/Components/modal/ConfirmationModal/ConfirmationModal'
import { IButtonSettings, TConfirmationState } from './TConfirmationState'
import { useAppDispatch } from '../../../store/hooks'
import { IPreviousAppointment } from '../../IPreviousAppointment'

type Props = {
  backgroundcolor: string
  highlight: string
  previousAppointment: IPreviousAppointment
  cantCancel: boolean
}

export const CancelAppointmentButton: React.FC<Props> = ({
  backgroundcolor,
  highlight,
  previousAppointment,
  cantCancel,
}) => {
  const [showDeleteSpinner, setShowDeleteSpinner] = useState<boolean>(false)
  const [confirmationModal, setConfirmationModal] =
    useState<TConfirmationState>('idle')
  const history = useHistory()
  const { establishmentId } = useParams<{ [key: string]: string }>()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const buttonSettings: IButtonSettings = {
    cancel: { text: t('No, don’t cancel'), visible: true },
    confirm: { text: t('Yes'), visible: true },
  }

  useEffect(() => {
    if (confirmationModal !== 'confirm') {
      return
    }
    setShowDeleteSpinner(true)
    Client.deleteAppointment(
      previousAppointment.id,
      previousAppointment.token,
      establishmentId
    ).then((response) => {
      if (response.data === 'ok') {
        dispatch(deleteAppointment(previousAppointment.id))
        history.push(`/${establishmentId}/previous-appointments`)
      }
    })
  }, [confirmationModal])

  if (cantCancel) {
    return null
  }
  if (showDeleteSpinner) {
    return <AbsoluteSpinner />
  }

  return (
    <>
      <ConfirmationModal
        description={
          confirmationModal === 'open'
            ? t('Are you sure you want to cancel this appointment?')
            : null
        }
        title={t('Cancel appointment')}
        confirmationState={confirmationModal}
        setConfirmation={setConfirmationModal}
        buttonSettings={buttonSettings}
      />
      <ButtonWithText
        backgroundcolor="transparent"
        activefontcolor={backgroundcolor}
        fontcolor={highlight}
        hoversensitive
        onPress={() => setConfirmationModal('open')}
      >
        {t('Cancel')}
      </ButtonWithText>
    </>
  )
}
