export const calculateCalenderFontColor = (backgroundColor: string) => {
    let hexColor: string =
        backgroundColor.charAt(0) === '#'
            ? backgroundColor.substring(1, 7)
            : backgroundColor

    if (hexColor.length === 3) {
        hexColor = hexColor
            .split('')
            .map((hex) => {
                return hex + hex
            })
            .join('')
    }

    const r: number = parseInt(hexColor.slice(0, 2), 16)
    const g: number = parseInt(hexColor.slice(2, 4), 16)
    const b: number = parseInt(hexColor.slice(4, 6), 16)

    const needADarkFont: boolean = r * 0.299 + g * 0.587 + b * 0.114 > 186

    const emphasizedColor: string = needADarkFont
        ? 'rgba(12,12,12, 0.9)'
        : 'rgba(238,238,238, 0.9)'
    const fadedColor: string = needADarkFont
        ? 'rgba(12,12,12, 0.5)'
        : 'rgba(238,238,238, 0.5)'
    const emphasizedColorNegative: string = needADarkFont
        ? 'rgba(238,238,238, 0.9)'
        : 'rgba(12,12,12, 0.9)'
    const fadedColorNegative: string = needADarkFont
        ? 'rgba(238,238,238, 0.5)'
        : 'rgba(12,12,12, 0.5)'

    return {
        emphasizedColor,
        fadedColor,
        emphasizedColorNegative,
        fadedColorNegative,
    }
}
