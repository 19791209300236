import { IExtraActivity } from "../../ExtraActivity/Client/IExtraActivity"

export const activityFilter = (
  activities:IExtraActivity[],
  isCertainServiceBlock: boolean,
  isExistingCustomer: boolean
): IExtraActivity[] => activities.filter((activity) => {
  if (!activity.isOnline) return false
  if (!isCertainServiceBlock) return true
  if (activity.existingCustomersOnly) return isExistingCustomer
  return true
} )
