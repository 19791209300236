
import { IOptIns } from '../Client/IOptIns'
import OptInClient from '../Client/Client'
import {
  OPTINS_LOADING,
  OPTINS_SUCCESS,
  OptInsLoading,
  OptInsSuccess
} from './ActionTypes'

export function getOptInsLoading(): OptInsLoading {
  return {
    type: OPTINS_LOADING,
  }
}

function getOptInsSuccess(optIns: IOptIns): OptInsSuccess {
  return {
    type: OPTINS_SUCCESS,
    payload: {
      optIns,
    },
  }
}

export function getOptIns(customerUuid: string, customerToken: string) {
  return (dispatch: any) => {
    dispatch(getOptInsLoading())

    return OptInClient.getOptInsFromClient(customerUuid, customerToken).then(
      (response: IOptIns) => {
        dispatch(getOptInsSuccess(response))
      }
    )
  }
}

export function submitOptin(
  customerUuid: string, 
  customerToken: string, 
  isTransactional: boolean, 
  isMarketing: boolean) {
  return () => OptInClient.submitOptInToClient(customerUuid, customerToken, isTransactional, isMarketing)
}

