import {IAvailabilityStyle, IMarkerTextStyle, ISelectedStyle} from "./interface/IAvailabilityStyle";
import StyleVariables from "../../../../StyleVariables";
import {TSelectedColor} from "./interface/TSelectedColor";

export const setCalendarStyle = (
  disableColorCode: boolean,
  isSelected: boolean,
  isEnable: boolean,
  markerRadius: number,
  currentColor: TSelectedColor
): IAvailabilityStyle => {

  const textStyle: IMarkerTextStyle = disableColorCode
    ? { color: isEnable ? 'black' : 'grey', fontWeight:'normal'}
    : undefined

  const selectedStyle: ISelectedStyle = disableColorCode
    ? { selected: isSelected, selectedColor: isSelected ? '#9a9a9a' : 'none' }
    : { selected: true, selectedColor: StyleVariables[currentColor], }

  return ({
    ...selectedStyle,
    disabled: !isEnable,
    disableTouchEvent: !isEnable,
    customStyles: {
      container: {
        borderRadius: markerRadius,
      },
      text: textStyle
    },
  }
  )

}