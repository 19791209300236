import styled from 'styled-components/native'

export const CalendarContainer = styled.View<{
    backgroundcolor: string
}>`
    background-color: ${({ hightlightcolor }) => hightlightcolor};
    padding: 0 10px;
    width: 340px;
    align-self: center;
`

export const ToggleDates = styled.Text<{
    backgroundcolor: string
    highlightcolor: string
}>`
    padding: 8px;
    margin: 15px 3px 3px 3px;
    color: ${({ highlightcolor }) => highlightcolor};
    align-self: center;
    text-align: center;
    width: 42.5%;
    background-color: ${({ backgroundcolor }) => backgroundcolor};
`

export const ExtraCalendarMargin = styled.View<{ isRender: boolean }>`
  display: ${({isRender})=> isRender ? 'block' : 'none'};
  height: 46px;
`

export const LabelsContainer = styled.View<{isDisableColor: boolean}>`
    display: ${({ isDisableColor })=> isDisableColor ? 'none' : 'flex'};
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 5px;
`

export const ExtraLabelContainer = styled.View<{isDisableColor: boolean}>`
    display: ${({ isDisableColor })=> isDisableColor ? 'none' : 'flex'};
    flex-direction: row;
    justify-content: center;
    padding-left: 5px;
    padding-top: 16px;
`

export const StarEmoji = styled.Text`
    margin-right: 8px;
`
