import React from 'react'

import { Platform } from 'react-native'

import {
  AppContentContainer as NativeContainer,
  AppContentContainerWeb,
} from './style'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import { useMobileCheck } from '../../../Util/useMobileCheck'
import { addTileUrl, toggleContextMenu } from '../../ContextMenu/store/action'

export const AppContentContainer = ({ children }) => {
  const settings = useAppSelector((state) => state.settings.values)
  const contextMenuUrl = useAppSelector((state) => state.contextMenu.isActive)

  const dispatch = useAppDispatch()

  const isSeamless = (settings && settings.isSeamlessIntegrated) || false
  const mobileCheck = useMobileCheck()

  const handleRightClick = (e: any) => {
    if (e.nativeEvent.button === 0 && contextMenuUrl) {
      dispatch(toggleContextMenu(false))
    }
    if (e.nativeEvent.button === 2) {
      // const containerRect = e.currentTarget.getBoundingClientRect()
      const tileUrl = e.target.getAttribute('data-url-info')
      if (tileUrl) {
        // const x = e.clientX - containerRect.x // x position within the element.
        // const y = e.clientY - containerRect.x // y position within the element.
        dispatch(addTileUrl(true, { x: 153, y: 340 }, tileUrl))
      }
    }
  }

  if (Platform.OS === 'web') {
    return (
      <AppContentContainerWeb
        onMouseDown={(e) => handleRightClick(e)}
        seamless={isSeamless}
        isMobile={mobileCheck}
      >
        {children}
      </AppContentContainerWeb>
    )
  }

  return <NativeContainer isMobile={mobileCheck}>{children}</NativeContainer>
}
