import styled from 'styled-components/native'

import { ModalCloseWrapper, ModalMessage } from '../WelcomeMessage/style'

export const ModalTitle = styled(ModalMessage)`
  font-size: 22px;
  padding: 1em;
  text-align: center;
  letter-spacing: 0.6px;
`

export const CloseIconWrapper = styled(ModalCloseWrapper)`
  position: absolute;
  right: 20px;
  top: 15px;
  z-index: 10;
  border-radius: 14px;
`
