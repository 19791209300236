import styled from 'styled-components/native'

import { ProximaNova } from '../TextComponent'

export const TextWrapper = styled.View``

export const BackButtonText = styled(ProximaNova)<{
  color: string
  extraMargin: boolean
}>`
  text-align: center;
  color: ${({ color }) => color || '#fff'};
  align-self: center;
  color: ${({ color }) => color || '#fff'};
  padding: 0 1.5em;
  margin: 0 auto 1.5em;
  margin-top: ${({ extraMargin }) => (extraMargin ? 1.5 : 0)}em;
`
