import { Reducer } from 'redux'

import { IWorkshopState} from './IWorkshopState'
import {GET_WORKSHOPS_SUCCESS} from "./Actions";

const initialState: IWorkshopState = {
  loading: false
}

export const reducer: Reducer<IWorkshopState> = (
  state: IWorkshopState = initialState,
  action: any
): IWorkshopState => {
  switch (action.type) {
    case GET_WORKSHOPS_SUCCESS:
      return {
        ...state,
        list: action.list
      };
    default:
      return state
  }
}
