import React, { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import { StatusMessageContainer, StatusText } from '../../App/Components'
import { StyledScrollView } from '../../App/Components/AppContainer'

export const SurveySuccess: FunctionComponent = () => {
  const { t } = useTranslation()

  return (
    <StyledScrollView>
      <StatusMessageContainer success>
        <StatusText>{t('Thank you for completing this survey.')}</StatusText>
      </StatusMessageContainer>
    </StyledScrollView>
  )
}
