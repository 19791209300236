import styled from 'styled-components/native'

import { ButtonWithText } from '../App/Components'

export const AccountsContainer = styled.View`
  display: flex;
  flex-direction: column;
`

export const AccountInfo = styled.View`
  display: flex;
  flex-direction: row;
  padding: 16px 0;
  justify-content: center;
`

export const InfoText = styled.Text`
  padding: 0 8px;
  font-max-size: 20px;
`
export const StyledButtonWithText = styled(ButtonWithText)`
  padding: 6px 60px;
`
