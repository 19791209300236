import React from 'react'
import { useTranslation } from 'react-i18next'

import { useHistory, useParams } from '../../../Router'
import { DefaultButtonWithText } from '../ButtonWithText/style'
import { AbsoluteSpinner } from '../Spinners'

import { IProps } from './IProps'
import { useAppSelector } from '../../../store/hooks'

export const PreviousAppointmentsButton: React.FunctionComponent<IProps> = ({
  rootPathCheck,
}) => {
  const { establishmentId } = useParams()
  const { t } = useTranslation()
  const history = useHistory()
  const { settings } = useAppSelector((state) => ({
    settings: state.settings.values,
  }))

  if (typeof settings === 'undefined') {
    return <AbsoluteSpinner />
  }

  const goToPreviousAppointments = () =>
    history.push(`/${establishmentId}/previous-appointments`)

  return rootPathCheck ? (
    <DefaultButtonWithText
      backgroundcolor={settings.backgroundColor}
      fontcolor={settings.highlightColor}
      hoversensitive
      onPress={goToPreviousAppointments}
    >
      {t('Manage my appointments')}
    </DefaultButtonWithText>
  ) : null
}
