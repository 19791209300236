import { Reducer } from 'redux'

import { GET_CATEGORIES_SUCCESS, MENU_LOADING } from './Actions'
import { IMenuState } from './IMenuState'

const initialState: IMenuState = {
    loading: false,
}
export const reducer: Reducer<IMenuState> = (
    state: IMenuState = initialState,
    action: any
): IMenuState => {
    switch (action.type) {
        case MENU_LOADING:
            return {
                ...state,
                loading: true,
            }
        case GET_CATEGORIES_SUCCESS:
            return {
                ...state,
                loading: false,
                categories: action.categories,
            }
        default:
            return state
    }
}
