import styled from 'styled-components/native'

export const LogoImg = styled.Image`
    padding: 0 1.5em;
    margin: 1.5em auto 0 auto;
    max-width: 380px;
    width: 75%;
    height: 100px;
    align-self: center;
`
