import React, { useEffect, useState } from 'react'
import ReactPixel from 'react-facebook-pixel'
import { useDispatch } from 'react-redux'
import { matchPath } from 'react-router'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import TagManager from 'react-gtm-module'

import { Spinner, WelcomeMessage } from '../App/Components'
import {
  AppContentContainer,
  AppMainView,
  AppWrapper,
} from '../App/Components/AppContainer'
import { getExtraActivities } from '../ExtraActivity'
import { getLastMinutes } from '../LastMinutes'
import { getCategories } from '../Menu'
import NoSalon from '../NoSalon/NoSalon'
import { useHistory, useLocation, useParams } from '../Router'
import {
  grandUserAccess,
  getSettings,
  removeUserAccess,
} from '../Settings/Store/Action'
import { SnackBar } from '../SnackBar/SnackBar'
import Waiting from '../Waiting/Waiting'

import { Helmet } from './Helmet'
import { OnlineBookingClosed } from './OnlineBookingClosed/OnlineBookingClosed'
import { OnlineBookingOpen } from './OnlineBookingOpen/OnlineBookingOpen'
import { AppBackgroundImage } from './style'
import { AccountIcon } from './AccountIcon/AccountIcon'
import { getPersons } from '../SelectPerson/store/Actions'
import { getWorkshops } from '../Workshop/store/Actions'
import { MultiLocations } from './MultiLocations/MultiLocations'
import { NoMultiLocations } from './MultiLocations/NoMultiLocations'
import { ContextMenu } from '../App/Components/ContextMenu/ContextMenu'
import { useAppSelector } from '../store/hooks'

library.add(fas)

const Main: React.FC = () => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)

  const { establishmentId, locationIds } =
    useParams<{ [key: string]: string }>()
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()

  const surveyPath = `/${establishmentId}/surveys/`
  const checkIfSurveyPath: boolean =
    history.location.pathname.includes(surveyPath)

  const { settings, isInWaitingQueue, hasErrorLoading, isAccessGranted } =
    useAppSelector((state) => ({
      hasErrorLoading: state.settings.error,
      isAccessGranted: state.settings.isAccessGranted,
      isInWaitingQueue: state.settings.inWaitingQueue,
      settings: state.settings.values,
    }))

  document.addEventListener('contextmenu', (event) => event.preventDefault())

  useEffect(() => {
    if (!establishmentId) {
      return
    }
    dispatch(removeUserAccess())
    dispatch(getSettings(establishmentId))
    dispatch(getExtraActivities(establishmentId))
    dispatch(getCategories(establishmentId))
    dispatch(getLastMinutes(establishmentId))
    dispatch(getWorkshops(establishmentId))
    dispatch(getPersons(establishmentId))
  }, [establishmentId, dispatch])

  useEffect(() => {
    if (settings) {
      if (settings.message && !checkIfSurveyPath) {
        setIsModalVisible(true)
      } else setIsModalVisible(false)
      if (settings.containerIdGTM) {
        const tagManagerArgs = {
          gtmId: settings.containerIdGTM,
        }
        TagManager.initialize(tagManagerArgs)
      }
    }
  }, [settings])

  const backgroundImage = settings ? { uri: settings.backgroundImage } : null

  const options = {
    autoConfig: true,
    debug: false,
  }
  if (typeof settings !== 'undefined' && settings.facebookPixel !== null) {
    ReactPixel.init(settings.facebookPixel, undefined, options)
    ReactPixel.pageView()
  }

  const getContent = () => {
    if (isInWaitingQueue) {
      return <Waiting />
    }

    if (establishmentId === 'multi-location') {
      return <NoMultiLocations />
    }

    if (!establishmentId && locationIds) {
      return <MultiLocations />
    }

    if (!establishmentId) {
      return <NoSalon />
    }

    if (typeof settings === 'undefined') {
      return hasErrorLoading ? <NoSalon /> : <Spinner />
    }

    if (!settings.isActive) {
      return <OnlineBookingClosed />
    }

    if (
      matchPath(location.pathname, {
        path: '/:establishmentId/appointment/:appointmentId/payment-status/:token/:email',
        exact: true,
      })
    ) {
      return <OnlineBookingOpen />
    }
    if (
      matchPath(location.pathname, {
        path: '/:establishmentId/account',
        exact: true,
      })
    ) {
      return <OnlineBookingOpen />
    }

    if (
      !settings.onlineAppointmentsExistingCustomersOnly &&
      !settings.onlineAppointmentsDepositOnlyForNewCustomers &&
      !settings.depositForSpecificCustomers &&
      !isAccessGranted &&
      !settings.isCertainServiceBlock
    ) {
      dispatch(grandUserAccess())
    }

    return <OnlineBookingOpen />
  }

  return (
    <AppMainView>
      <SnackBar />
      <AppBackgroundImage source={backgroundImage} />
      <AppContentContainer>
        <AppWrapper settingsProtection={false}>
          <ContextMenu settingValues={settings} />
          <WelcomeMessage isVisible={isModalVisible} />
          <AccountIcon settingValues={settings} />
          <Helmet settings={settings} />
          {getContent()}
        </AppWrapper>
      </AppContentContainer>
    </AppMainView>
  )
}

export default Main
