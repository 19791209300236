import React from 'react'

import { ActivityMetaDuration } from '../style'

interface IProps {
  isShowDuration: boolean
  isShowPrice: boolean
  duration: number
  waitTime: number
}

export const ActivityDuration = ({
  isShowDuration,
  isShowPrice,
  duration,
  waitTime,
}: IProps) => {
  if (!isShowDuration || !duration) {
    return null
  }
  const totalTime: number = duration + waitTime

  return (
    <ActivityMetaDuration
      isShowPrice={isShowPrice}
      isShowDuration={isShowDuration}
    >
      {`${totalTime} min`}
    </ActivityMetaDuration>
  )
}