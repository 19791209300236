import React, { FunctionComponent } from 'react'
import { Text } from 'react-native'
import { useTranslation } from 'react-i18next'
import { IProps } from './IProps'
import { ToggleDates } from '../../style'

export const ToggleMomentList: FunctionComponent<IProps> = ({
  selectedDate,
  bgColor,
  emphasizedColor,
  showAllButtonVisible,
  setShowAllHours,
}) => {
  const { t } = useTranslation()

  if (!selectedDate || !showAllButtonVisible) {
    return null
  }
  return (
    <ToggleDates
      backgroundcolor={bgColor}
      highlightcolor={emphasizedColor}
      onPress={() => setShowAllHours(true)}
    >
      <Text>{t('Show all')}</Text>
    </ToggleDates>
  )
}
