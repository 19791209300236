import { IApiMenuActivity, IMenuActivity } from '../../Menu/Store/IMenuActivity'

export default class Transformer {
  public static transform(
    apiActivity: IApiMenuActivity,
    establishmentId: string,
    menuId: number,
    index: number
  ): IMenuActivity {
    const establishmentDetails = apiActivity.establishment_details.find(
      (establishment) =>
        establishment.establishment_id === parseInt(establishmentId, 10)
    )
    return {
      categoryId: apiActivity.parent_id,
      deposit: establishmentDetails.deposit,
      description: apiActivity.description
        ? apiActivity.description
        : undefined,
      establishmentId: establishmentDetails.establishment_id,
      id: apiActivity.id,
      image: apiActivity.image,
      isPersonSelectable: establishmentDetails.is_person_selectable,
      name: apiActivity.name,
      order: apiActivity.order,
      personsAmount: apiActivity.persons_amount,
      price: establishmentDetails.price,
      vatPercentage: establishmentDetails.vat_percentage,
      type: 'menuActivity',
      duration: apiActivity.duration,
      waitTime: apiActivity.wait_time || 0,
      uuid: apiActivity.uuid,
      menuId: menuId,
      isFirstOfMenu: index === 0,
      activityMenuPrice: apiActivity.activity_menu_price,
    }
  }
}
