import { URL_API } from 'react-native-dotenv'

import axios  from 'axios'

import { ISettingValues } from '../ISettings'

import Transformer from './Transformer'

export default class Client {
  public static getSettings(establishmentId: string): Promise<ISettingValues> {
    const requestOptions: object = {
      method: 'GET',
      url: `${URL_API}/anonymous/online-booking-info?establishment_id=${establishmentId}`,
    }

    return axios(requestOptions).then(
      (response): ISettingValues => Transformer.transform(response.data)
    )
  }

  public static validateEmail(establishmentId: string, email: string) {
    const requestOptions: object = {
      method: 'GET',
      url: `${URL_API}/anonymous/calendar/existingcustomer?email=${email}&establishment_id=${establishmentId}`,
    }

    return axios(requestOptions)
  }
}
