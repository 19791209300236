import { StyleSheet } from 'react-native'
import styled from 'styled-components/native'

export const StyledText = styled.Text<{fontcolor: string}>`
  color: ${({ fontcolor }) => fontcolor};
`

export const Style = (fontColor: string) => StyleSheet.create({
  inputContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    marginLeft: 36,
    marginRight: 36,
  },
  input: {
    width: '85%',
    marginBottom: 5,
    borderWidth: 1,
    textAlign: 'left',
    padding: 8,
    borderRadius: 8,
    borderColor: fontColor,
    color: fontColor
  },
  inputError: {
    color: 'white',
    position: 'absolute',
    backgroundColor: '#D24444',
    borderRadius: 3,
    paddingTop: 3,
    paddingRight: 5,
    paddingBottom: 3,
    paddingLeft: 5,
    right: 50,
    top: -10,
  },
  hasError: {
    borderColor: '#D24444',
  },
  conditionWrapper: {
    flexDirection: 'row',
  },
  checkBox: {
    marginRight: 7,
  },
})





