import React, { FunctionComponent } from 'react'

import { useParams } from '../../../Router'

import { ConditionalLink } from './Components/ConditionalLink/ConditionalLink'
import { useAppSelector } from '../../../store/hooks'
import {Image, Text} from 'react-native'

export const Logo: FunctionComponent = () => {
  const {
    isAppointmentLoading,
    isLastMinuteLoading,
    logo,
    isSeamlessIntegrated,
  } = useAppSelector((state) => ({
    isAppointmentLoading: state.appointment.loading,
    isLastMinuteLoading: state.lastMinute.loading,
    logo: state.settings.values ? state.settings.values.logo : null,
    isSeamlessIntegrated: state.settings.values
      ? state.settings.values.isSeamlessIntegrated
      : false,
  }))

  const { establishmentId } = useParams()
  const rootPath = `/${establishmentId}`

  if (isSeamlessIntegrated || !logo) {
    return null
  }

  return (
    <ConditionalLink
      to={rootPath}
      condition={!isAppointmentLoading && !isLastMinuteLoading}
      logo={logo}
    />

  )
}
