import React from 'react'

import { CustomerAppointment } from './Components/CustomerAppointment/CustomerAppointment'
import { useAppSelector } from '../../../store/hooks'

export const CustomerAppointmentList = () => {
  const customers = useAppSelector((state) => state.appointment.customers)

  const mappedCustomers = customers.map((customer, customerIndex) => (
    <CustomerAppointment
      customerIndex={customerIndex}
      key={`customer${customerIndex}`}
    />
  ))

  return <>{mappedCustomers}</>
}
