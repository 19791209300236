import React from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'
import { StyledScrollView } from '../App/Components/AppContainer'
import { ProximaNova } from '../App/Components'
import { useAppSelector } from '../store/hooks'

export const AnnulationCondition: React.FunctionComponent = () => {
  const { t } = useTranslation()

  const { settings } = useAppSelector((state) => ({
    settings: state.settings.values,
  }))

  const transformAnnulationCondition = (annulationCondition) => {
    const stringBlocks = annulationCondition.split(/\r?\n/)
    return stringBlocks.map((stringBlock) => <p>{stringBlock}</p>)
  }

  return (
    <StyledScrollView>
      <View
        style={{
          marginTop: '20px',
          marginRight: '20px',
          marginLeft: '20px',
        }}
      >
        <ProximaNova textAlign="left" width="100%">
          {settings.annulationCondition ? (
            transformAnnulationCondition(settings.annulationCondition)
          ) : (
            <p>
              {t(
                'No annulation condition found. Define one in calendar settings under online appointments'
              )}
            </p>
          )}
        </ProximaNova>
      </View>
    </StyledScrollView>
  )
}
