import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router'
import {
  StyledScrollView,
  TileContainer,
} from '../../../App/Components/AppContainer'
import { BackButton, Tile } from '../../../App/Components'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { editUpdatePreviousAppointment } from '../../../Appointment/store/Actions'
import { EligibleEditPerson } from '../EligibleEditPerson/EligibleEditPerson'

export const EditPerson = () => {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const { t } = useTranslation()
  const { establishmentId, activityId } = useParams<{ [key: string]: string }>()

  const { persons, settings, previousAppointment } = useAppSelector(
    (state) => ({
      persons: state.persons.persons,
      settings: state.settings.values,
      previousAppointment: state.appointment.editPreviousAppointment,
    })
  )

  const { allowNoPreferenceSelection } = settings

  if (!previousAppointment) {
    history.push(`/${establishmentId}`)
    return null
  }

  const foundActivity = previousAppointment.activities.find(
    (activity) => activity.id === Number(activityId)
  )

  const onTilePress = () => {
    dispatch(editUpdatePreviousAppointment(null, Number(activityId)))
    history.push(`/${establishmentId}/appointment/move/${activityId}`)
  }

  return (
    <StyledScrollView>
      <BackButton extraMargin goBack={-1} isAppointment={false} />
      <TileContainer>
        <Tile
          title={t('No preference')}
          image="https://client.optios.net/img/avatar-female.png"
          onPress={onTilePress}
          noPreferenceSelection={allowNoPreferenceSelection}
        />
        <EligibleEditPerson
          activity={foundActivity}
          persons={persons}
          activityId={activityId}
          establishmentId={establishmentId}
        />
      </TileContainer>
    </StyledScrollView>
  )
}
