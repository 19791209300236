import styled from 'styled-components/native'

export const SpinnerWrapper = styled.View`
    align-content: center;
    justify-content: center;
`
export const OptiosActivityIndicator = styled.ActivityIndicator`
    margin-bottom: 20px;
    margin-top: 20px;
`
export const SpinnerText = styled.Text`
    text-align: center;
    margin-bottom: 20px;
`
