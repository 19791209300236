import ActivityTransformer from '../../Appointment/store/activityTransformer'

import { IApiMenuCategory, IMenuCategory } from './IMenuCategory'

export default class Transformer {
  public static transform(
    categoriesArray: IApiMenuCategory[],
    establishmentId: string
  ): IMenuCategory[] {
    return categoriesArray.map((apiCategory) => {
      const category: IMenuCategory = {
        id: apiCategory.id,
        image: apiCategory.image,
        name: apiCategory.name,
        order: apiCategory.order,
        parentId: apiCategory.parent_id,
        isMenu: apiCategory.is_menu,
        isOnline: apiCategory.is_online,
        description: apiCategory.description ? apiCategory.description : undefined,
        type: 'category',
        existingCustomersOnly: apiCategory.only_for_existing_customers,
      }
      if (apiCategory.categories) {
        category.categories = Transformer.transform(
          apiCategory.categories,
          establishmentId,
        )
      }
 
      if (apiCategory.activities) {
        category.activities = apiCategory.activities.map(
          (apiActivity, index) => ActivityTransformer.transform(
            apiActivity, establishmentId, category.id, index
          )
        )
      }

      return category
    })
  }
}
