import { TextInput } from 'react-native'

import styled from 'styled-components/native'

export const FieldInputContainer = styled.View`
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 35px 0 05px 0;
    border-bottom-width: 1px;
    border-bottom-color: #a9a9a9;
`

export const FieldLabel = styled.Text<{
    fontcolor: string
}>`
    width: 40%;
    padding: 5px 5px 5px 35px;
    margin-bottom: 5px;
    color: ${({ fontcolor }) => fontcolor};
    font-size: 16px;
`

export const FieldInput = styled(TextInput)<{
    fontcolor: string
}>`
    width: 60%;
    margin-bottom: 5px;
    padding-right: 35px;
    border-width: 0;
    text-align: right;
    color: ${({ fontcolor }) => fontcolor};
    outline-style: none;
`

export const InputError = styled.Text<{showError: boolean, statusColor?: string }>`
  display: ${({ showError })=> showError ? 'block' : 'none'};
  color: white;
  position: absolute;
  background-color: ${({statusColor})=>statusColor || '#d24444'};
  border-radius: 3px;
  padding: 3px 5px;
  right: 5px;
  bottom: 35px;
`
