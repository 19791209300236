import React, { useCallback, useEffect, useMemo, useState } from 'react'
import ReactPixel from 'react-facebook-pixel'

import { useTranslation } from 'react-i18next'
import TagManager from 'react-gtm-module'
import { AbsoluteSpinner } from '../App/Components'
import { BookingSuccess } from '../App/Components/BookingSuccess/BookingSuccess'
import { setAppointmentStatus } from '../Appointment/store/Actions'
import { IMenuActivity } from '../Menu/Store/IMenuActivity'
import { useHistory, useParams } from '../Router'
import { useAppDispatch, useAppSelector } from '../store/hooks'
import { CustomerFormData } from '../CustomerForm/ICustomerFormData'
import Client from '../Appointment/Client/Client'
import { IUserData, setUserData } from '../App/Util'
import { IAPIWaitingListResponse } from './IAPIWaitingListResponse'
import { bookingFormErrorHandling } from '../App/Util/bookingFormErrorHandling'
import { CustomerForm } from '../CustomerForm/CustomerForm'
import { StyledScrollView } from '../App/Components/AppContainer'

export const BookWaitingList = () => {
  const [isLoading, setLoading] = useState<boolean>(false)
  const [successData, setSuccessData] = useState<IAPIWaitingListResponse>()

  const { establishmentId } = useParams<{ [key: string]: string }>()
  const dispatch = useAppDispatch()
  const history = useHistory()
  const { t } = useTranslation()
  const pagePath = history.location.pathname

  const { appointment } = useAppSelector((state) => ({
    appointment: state.appointment,
  }))

  const activities: IMenuActivity[] = useMemo(
    () => appointment.customers.map((a) => a.activities).flat(1),
    [appointment]
  )

  const totalDeposit: number = useMemo(
    () =>
      activities
        .map((activity) => (activity.deposit ? activity.deposit : 0))
        .reduce(
          (accumulatedDeposit, currentDeposit) =>
            accumulatedDeposit + currentDeposit,
          0
        ),
    [activities]
  )

  const responseType = useMemo(
    () => ({
      type: 'waitingList' as const,
      response: null,
    }),
    []
  )

  useEffect(() => {
    if (activities.length === 0) {
      history.push(`/${establishmentId}`)
    }
  }, [activities.length, history, establishmentId])

  const handleSubmit = useCallback(
    async (customerData: CustomerFormData) => {
      try {
        setLoading(true)

        const { customers, date } = appointment

        const activitiesToSend: IMenuActivity[][] = customers.map(
          (customer) => customer.activities
        )

        const {
          firstName,
          lastName,
          phone,
          email,
          comment,
          sendEmail,
          street,
          zip,
          city,
        } = customerData

        const response = await Client.addToWaitingList(
          establishmentId,
          firstName,
          lastName,
          phone,
          email,
          activitiesToSend,
          comment,
          date
        )

        const storageObject: IUserData = {
          firstName,
          lastName,
          phone,
          email,
          isEmailSend: sendEmail,
          street,
          city,
          zip,
        }

        setUserData(storageObject)
        dispatch(setAppointmentStatus('booking_success'))
        setSuccessData(response.data)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        bookingFormErrorHandling(
          establishmentId,
          customerData.email,
          error,
          dispatch,
          history,
          t
        )
      }
    },
    [appointment, dispatch, t, history, establishmentId]
  )

  if (successData) {
    TagManager.dataLayer({
      dataLayer: {
        event: 'booking_success',
        pagePath: pagePath,
        pageTitle: t('Booking success'),
      },
    })

    ReactPixel.pageView()
    return (
      <StyledScrollView>
        <BookingSuccess
          isWaitingList
          responseType={responseType}
          successMessage="You have been added to the waiting list."
        />
      </StyledScrollView>
    )
  }

  if (isLoading)
    return (
      <StyledScrollView>
        <AbsoluteSpinner />
      </StyledScrollView>
    )

  return (
    <StyledScrollView>
      <CustomerForm submitHandler={handleSubmit} hasDepositLogic={false} />
    </StyledScrollView>
  )
}
