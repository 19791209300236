import styled from 'styled-components/native'
import { Paragraph, ProximaNova } from '../../App/Components'

export const OptInFormContainer = styled.View`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 25px;
`

export const OptInFormHeading = styled(Paragraph)`
  margin-bottom: 32px;
`

export const CheckboxWrapper = styled.View`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 32px;
  align-items: center;
`

export const CheckboxLabel = styled(ProximaNova)<{
  fontcolor: string
}>`
  margin-left: 16px;
  text-align: left;
  color: ${({ fontcolor }) => fontcolor};
`
