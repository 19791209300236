import React, {FunctionComponent, useRef} from 'react'
import {TextInput} from "react-native";
import { FieldInput } from '../../style'
import { IProps } from './IProps'

export const PhoneInput: FunctionComponent<IProps> = ({
  onChange,
  onBlur,
  value,
  fontColor,
}) => {
  const ref = useRef<TextInput>()
  const regexOnChangeText = (input: string) => {
    const checkIfPhoneNumber = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\.0-9]*$|^$/g
    if (!checkIfPhoneNumber.test(input)) {
      return
    }
    onChange(input)
  }
  return (
    <FieldInput
      fontcolor={fontColor}
      onBlur={onBlur}
      onChangeText={regexOnChangeText}
      value={value}
      ref={ref}
      onSubmitEditing={()=>ref}
      autoComplete="tel"
      keyboardType='phone-pad'
    />
  )
}
