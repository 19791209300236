import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'
import { Calendar as WixCalendar, LocaleConfig } from 'react-native-calendars'
import { DateData } from 'react-native-calendars/src/types'
import { useHistory } from 'react-router'
import moment, { Moment } from 'moment'
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-light-svg-icons'

import { AbsoluteSpinner, FaIcon } from '../../App/Components'
import { calculateCalenderFontColor, getUserData } from '../../App/Util'
import {
  addAvailability,
  addNewAvailability,
} from '../../Appointment/store/Actions'
import { useParams } from '../../Router'
import { CircleLabel } from '../CircleLabel/CircleLabel'

import './config/calendarLocaleConfig'
import './config/freeMomentsLocale'
import {
  CalendarContainer,
  ExtraCalendarMargin,
  ExtraLabelContainer,
  LabelsContainer,
  StarEmoji,
} from './style'
import i18n from '../../i18n'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { changeSnackBarState } from '../../SnackBar/store/Action'
import { DayAvailability } from './Components/DayAvailability/DayAvailability'
import { ToggleMomentList } from './Components/ToggleMomentList/ToggleMomentList'
import { ICustomerBlock } from '../../Appointment/Client/interface/ICustomerBlock'
import { transformToCustomerBlock } from './functions/transformToCustomerBlock'
import { ISettingValues } from '../../Settings/ISettings'
import { TAvailabilityFrontend } from '../../Appointment/store/interface/IAppointmentState'
import { getSortedAndFilteredAvailabilityForDay } from './functions/getSortedAndFilteredAvailabilityForDay'
import { getAvailabilityMap } from './functions/getAvailabilityMap/getAvailaibiltyMap'
import { LabelText } from '../CircleLabel/style'
import { IPreviousAppointment } from '../../PreviousAppointments/IPreviousAppointment'
import { checkIfCustomDuration } from '../../App/Util/checkIfCustomDuration'
import { getUserToken } from '../../App/Util/localStorageHelper/userToken/setUserToken'

const amountOfHoursToShow = (settings: ISettingValues, showAllHours: boolean) =>
  showAllHours || !settings.isShowAllButtonVisible
    ? settings.maxFreeMoments
    : settings.maxFreeMoments < 6
    ? settings.maxFreeMoments
    : 6

type Props = {
  settings: ISettingValues
  previousAppointment?: IPreviousAppointment
}

export const Calendar = ({ settings, previousAppointment }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [monthIndex, setMonthIndex] = useState<number>(0) // 0 === current month
  const [selectedDate, setSelectedDate] = useState<Moment | null>(null)
  const [calendarDate, setCalendarDate] = useState<Moment>(moment)
  const [extraMargin, setExtraMargin] = useState<boolean>()
  const [showAllHours, setShowAllHours] = useState<boolean>(false)
  const amountOfHours = amountOfHoursToShow(settings, showAllHours)

  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useAppDispatch()
  const { establishmentId } = useParams<{ [key: string]: string }>()
  const userData = getUserData()
  const selectedDateStringKey = selectedDate
    ? selectedDate.clone().format('YYYY-MM-DD')
    : null

  const { customers, loadingAvailability } = useAppSelector((state) => ({
    customers: state.appointment.customers,
    loadingAvailability: state.appointment.loading,
  }))

  const availability: TAvailabilityFrontend[] = useAppSelector((state) =>
    state.appointment.availability
      ? state.appointment.availability.map(
          (item): TAvailabilityFrontend => ({
            ...item,
            timeSlot: moment(item.timeSlot),
          })
        )
      : undefined
  )

  const customerTokenForFmf = checkIfCustomDuration(settings)
    ? getUserToken(establishmentId, userData.email)
    : null

  const { backgroundColor, highlightColor } = settings
  const plannedActivities: ICustomerBlock[] = !previousAppointment
    ? transformToCustomerBlock({
        type: 'newAppointment',
        payload: customers.map((customer) => customer.activities),
      })
    : transformToCustomerBlock({
        type: 'prevAppointment',
        payload: previousAppointment,
      })

  useEffect(() => {
    LocaleConfig.defaultLocale = i18n.language
  }, [i18n.language])

  useMemo(() => {
    const numberOfCalendarDays = calendarDate
      .clone()
      .endOf('month')
      .endOf('isoWeek')
      .diff(calendarDate.clone().startOf('month').startOf('isoWeek'), 'day')
    setExtraMargin(numberOfCalendarDays === 34)
  }, [calendarDate])

  useEffect(() => {
    if (!plannedActivities || plannedActivities.length === 0) {
      return
    }

    dispatch(
      settings && settings.isUsingNewFreeMomentFinder
        ? addNewAvailability(
            establishmentId,
            monthIndex,
            plannedActivities,
            customerTokenForFmf
          )
        : addAvailability(establishmentId, monthIndex, plannedActivities)
    ).then(
      () => null,
      () => {
        history.push(`/${establishmentId}`)
        dispatch(
          changeSnackBarState(
            'error',
            t('Please try making your selection again.'),
            `${t('Server error.')} 😵️`,
            5000
          )
        )
      }
    )
  }, [customers])

  const hasActivities =
    (previousAppointment && typeof availability !== 'undefined') ||
    (plannedActivities && plannedActivities.length > 0)

  if (
    isLoading ||
    typeof settings === 'undefined' ||
    typeof availability === 'undefined' ||
    typeof customers === 'undefined' ||
    !hasActivities
  ) {
    return <AbsoluteSpinner />
  }

  const renderArrows = (direction) =>
    direction === 'left' ? (
      monthIndex > 0 ? (
        <FaIcon color={highlightColor} icon={faChevronLeft} />
      ) : null
    ) : (
      <FaIcon color={highlightColor} icon={faChevronRight} />
    )

  const contrastColor: {
    emphasizedColor: string
    fadedColor: string
    emphasizedColorNegative: string
    fadedColorNegative: string
  } = calculateCalenderFontColor(backgroundColor)

  const sortedAvailabilities =
    selectedDate === null
      ? null
      : getSortedAndFilteredAvailabilityForDay(
          settings,
          availability,
          selectedDate,
          amountOfHours
        )

  const showAllButtonVisible = () => {
    if (selectedDate === null) return false

    const momentsForDay = availability.filter(
      (oneAvailability) =>
        oneAvailability.timeSlot.format('YYYY-MM-DD') ===
        selectedDate.format('YYYY-MM-DD')
    )

    if (
      !showAllHours &&
      settings.isShowAllButtonVisible &&
      sortedAvailabilities &&
      momentsForDay.length > amountOfHours &&
      settings.maxFreeMoments > 6
    ) {
      return true
    }
    return false
  }

  const minDate: string = moment().format('YYYY-MM-DD')
  const maxDate: string = moment()
    .add(settings.maxMonthsInAdvance, 'months')
    .subtract(1, 'days')
    .format('YYYY-MM-DD')

  const onPressArrowRightFunction = (addMonth) => {
    addMonth()
    setCalendarDate(calendarDate.clone().add(1, 'month'))
    setSelectedDate(null)
    setShowAllHours(false)
  }

  const onPressArrowLeftFunction = (subtractMonth) => {
    subtractMonth()
    setCalendarDate(calendarDate.clone().subtract(1, 'month'))
    setSelectedDate(null)
    setShowAllHours(false)
  }

  const onMonthChangeFunction = (date: DateData) => {
    const newMonthIndex = moment(date.dateString).diff(
      moment().startOf('month'),
      'months'
    )
    setMonthIndex(newMonthIndex)
    if (settings.isUsingNewFreeMomentFinder) {
      dispatch(
        addNewAvailability(
          establishmentId,
          newMonthIndex,
          plannedActivities,
          customerTokenForFmf
        )
      ).then(
        () => null,
        () => {
          history.push(`/${establishmentId}`)
          dispatch(
            changeSnackBarState(
              'error',
              t('Please try making your selection again.'),
              `${t('Server error.')} 😵️`,
              5000
            )
          )
        }
      )
      return null
    }
    dispatch(
      addAvailability(establishmentId, newMonthIndex, plannedActivities)
    ).then(
      () => null,
      () => {
        history.push(`/${establishmentId}`)
        dispatch(
          changeSnackBarState(
            'error',
            t('Please try making your selection again.'),
            `${t('Server error.')} 😵️`,
            5000
          )
        )
      }
    )
    setMonthIndex(newMonthIndex)
    return null
  }

  const onDayPressFunction = (day: DateData) => {
    setSelectedDate(moment(day.dateString))
    setShowAllHours(false)
  }

  const mapAndStyleDays = getAvailabilityMap(
    availability.map((availabilityDetails) =>
      availabilityDetails.timeSlot.format('YYYY-MM-DD HH:mm')
    ),
    monthIndex,
    settings.numberOfMomentsIndicatingBusy,
    settings.closingDays,
    moment()
      .add(settings.maxMonthsInAdvance, 'months')
      .subtract(1, 'days')
      .endOf('day'),
    selectedDateStringKey,
    settings.disableColor,
    settings.disableCircles ? 0 : 16
  )

  return (
    <CalendarContainer backgroundcolor={backgroundColor}>
      <View>
        {loadingAvailability ? <AbsoluteSpinner /> : null}
        <WixCalendar
          style={{
            backgroundColor,
          }}
          theme={{
            backgroundColor,
            calendarBackground: backgroundColor,
            monthTextColor: contrastColor.emphasizedColor,
            dayTextColor: contrastColor.fadedColor,
            textDisabledColor: contrastColor.fadedColor,
            textDayHeaderFontSize: 14,
            textDayHeaderFontWeight: 'bold',
            textSectionTitleColor: contrastColor.emphasizedColor,
            selectedDayTextColor: contrastColor.emphasizedColor,
          }}
          showWeekNumbers={false}
          minDate={minDate}
          maxDate={maxDate}
          firstDay={1}
          markingType="custom"
          markedDates={mapAndStyleDays}
          hideArrows={false}
          onPressArrowRight={onPressArrowRightFunction}
          onPressArrowLeft={onPressArrowLeftFunction}
          disableArrowLeft={monthIndex === 0}
          onMonthChange={onMonthChangeFunction}
          renderArrow={renderArrows}
          onDayPress={onDayPressFunction}
        />
        <ExtraCalendarMargin isRender={extraMargin} />
        <LabelsContainer isDisableColor={settings.disableColor}>
          <CircleLabel
            labelText="Available"
            labelColor="rgba(105,211,33,0.5)"
            fontColor={contrastColor.emphasizedColor}
          />
          <CircleLabel
            labelText="Busy"
            labelColor="rgba(251,176,59,0.5)"
            fontColor={contrastColor.emphasizedColor}
          />
          <CircleLabel
            labelText="Full"
            labelColor="rgba(210,68,68,0.5)"
            fontColor={contrastColor.emphasizedColor}
          />
        </LabelsContainer>
        <DayAvailability
          emphasizedColorNegative={contrastColor.emphasizedColorNegative}
          selectedDate={selectedDate}
          sortedHours={sortedAvailabilities}
          establishmentId={establishmentId}
          settings={settings}
          previousAppointment={previousAppointment}
        />
        {selectedDate ? (
          <ExtraLabelContainer isDisableColor={settings.disableColor}>
            <StarEmoji>⭐</StarEmoji>
            <LabelText fontcolor={contrastColor.emphasizedColor}>
              {t('Best choice')}
            </LabelText>
          </ExtraLabelContainer>
        ) : null}
        <ToggleMomentList
          selectedDate={selectedDate}
          showAllButtonVisible={showAllButtonVisible()}
          bgColor={backgroundColor}
          emphasizedColor={contrastColor.emphasizedColor}
          setShowAllHours={setShowAllHours}
        />
      </View>
    </CalendarContainer>
  )
}
