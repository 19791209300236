import moment from 'moment'
import React from 'react'
import { useHistory } from 'react-router'
import {
  ListLMContainer,
  ListLMCTextContainer,
} from '../../App/Components/LastMinuteListHeader/style'
import { ListColor, ListCardText } from '../../App/Components/ListCard/style'
import { stringToHsl } from '../../App/Util'
import { CategorySection } from '../../ExtraActivity/Components/ListStructure/Components/CategorySection/CategorySection'
import { useAppSelector } from '../../store/hooks'

type Props = {
  title: string
  image?: string
}

export const WorkshopListView: React.FC<Props> = ({ title, image }) => {
  const { workshops, settings } = useAppSelector((state) => ({
    settings: state.settings.values,
    workshops: state.workshops.list,
  }))

  const { fontColor, highlightColor, backgroundColor } = settings

  const history = useHistory()

  let customImage: string | undefined
  if (image) {
    customImage = image
    if (typeof image === 'string' && image.includes('files.optios.')) {
      customImage += '&w=48&h=48&fit=crop'
    }

    if (typeof image === 'string' && image.startsWith('//')) {
      customImage = `https:${customImage}`
    }
  }

  if (typeof workshops === 'undefined') {
    return null
  }

  const workshopListRender = workshops.map((workshop) => {
    const bookAppointment = () => {
      history.push(`workshops/${workshop.uuid}/book`)
    }
    return (
      <ListLMContainer onPress={bookAppointment} isChild>
        <ListColor color={stringToHsl(title, 50, 50)} isChild />
        <ListLMCTextContainer onPress={bookAppointment}>
          <ListCardText fontcolor={highlightColor}>
            {workshop.description}
          </ListCardText>
          <ListCardText fontcolor={fontColor}>
            {moment(workshop.startsAt).format('LLLL')}
          </ListCardText>
        </ListLMCTextContainer>
      </ListLMContainer>
    )
  })

  return (
    <CategorySection
      title={title}
      list={workshopListRender}
      fontcolor={fontColor}
      image={image}
      lastMinuteMenuLine
      bgColor={backgroundColor}
    />
  )
}
