import { URL_API } from 'react-native-dotenv'

import axios from 'axios'
export default class Client {
    public static getPersons(establishmentId: string): Promise<any> {
        const requestOptions: object = {
            method: 'GET',
            url: `${URL_API}/anonymous/persons?establishment_id=${establishmentId}`,
        }

        return axios(requestOptions)
    }
}
