import React, { CSSProperties } from 'react'
import { useTranslation } from 'react-i18next'

import { faEnvelopeCircleCheck } from '@fortawesome/pro-regular-svg-icons'
import {
  BackButton,
  ButtonWithText,
  FaIcon,
  Logo,
  StatusMessageContainer,
  StatusText,
} from '../../../../../App/Components'
import { StyledScrollView } from '../../../../../App/Components/AppContainer'

import { InfoText } from './style'
import { useAppDispatch } from '../../../../../store/hooks'
import { setAuthState } from '../../../../../Settings/Store/Action'

type Props = {
  establishmentId: string
  backgroundColor: string
  fontColor: string
}

export const VerificationCheck: React.FC<Props> = ({
  establishmentId,
  backgroundColor,
  fontColor,
}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const goBackToEmailCheck = () => dispatch(setAuthState('emailCheck'))

  const iconStyle: CSSProperties = {
    alignSelf: 'center',
    marginBottom: '20px',
    fontSize: '5em',
  }

  return (
    <StyledScrollView>
      <Logo />
      <BackButton
        resetAuthState
        extraMargin
        customPath={`/${establishmentId}`}
      />
      <StatusMessageContainer success>
        <FaIcon
          cssStyle={iconStyle}
          icon={faEnvelopeCircleCheck}
          color="white"
        />
        <StatusText>
          {t('A verification e-mail has been sent.')}
          <br />
          {t('Click on the "Show account" button in the email.')}
        </StatusText>
      </StatusMessageContainer>
      <InfoText>
        {t(
          'Did you not receive the e-mail? Make sure it is not in your junk folder.'
        )}
      </InfoText>
      <ButtonWithText
        isMaxWidth
        backgroundcolor={backgroundColor}
        fontcolor={fontColor}
        onPress={goBackToEmailCheck}
      >
        {t('Try again')}
      </ButtonWithText>
    </StyledScrollView>
  )
}
