import React, { useEffect, useMemo, useState, useCallback } from 'react'
import ReactPixel from 'react-facebook-pixel'

import { useTranslation } from 'react-i18next'
import { Linking } from 'react-native'
import TagManager from 'react-gtm-module'
import { AbsoluteSpinner } from '../App/Components'
import { BookingSuccess } from '../App/Components/BookingSuccess/BookingSuccess'
import { setAppointmentStatus } from '../Appointment/store/Actions'
import { IMenuActivity } from '../Menu/Store/IMenuActivity'
import { useHistory, useParams } from '../Router'

import { useAppDispatch, useAppSelector } from '../store/hooks'
import { CustomerForm } from '../CustomerForm/CustomerForm'
import { StyledScrollView } from '../App/Components/AppContainer'
import { CustomerFormData } from '../CustomerForm/ICustomerFormData'
import Client from '../Appointment/Client/Client'
import { IUserData, setUserData } from '../App/Util'
import { setUserToken } from '../App/Util/localStorageHelper/userToken/setUserToken'
import { bookingFormErrorHandling } from '../App/Util/bookingFormErrorHandling'
import { ApiAppointmentResponse } from '../Appointment/store/interface/IApiAppointmentResponse'
import { isNativeCheck } from '../App/Util/isNativeCheck'

interface ISuccessData {
  response: ApiAppointmentResponse
  downloadId?: number
  message?: string
  type: 'appointment'
}

export const BookAppointment = () => {
  const [isLoading, setLoading] = useState<boolean>(false)
  const [successData, setSuccessData] = useState<ISuccessData | undefined>()
  const isNative = isNativeCheck()
  const inFrame = window.self !== window.top
  const { establishmentId } = useParams<{ establishmentId: string }>()
  const dispatch = useAppDispatch()
  const history = useHistory()
  const { t } = useTranslation()
  const pagePath = history.location.pathname

  const appointment = useAppSelector((state) => state.appointment)

  const activities: IMenuActivity[] = useMemo(
    () => appointment.customers.map((a) => a.activities).flat(1),
    [appointment]
  )

  const totalDeposit: number = useMemo(
    () =>
      activities
        .map((activity) => (activity.deposit ? activity.deposit : 0))
        .reduce(
          (accumulatedDeposit, currentDeposit) =>
            accumulatedDeposit + currentDeposit,
          0
        ),
    [activities]
  )

  useEffect(() => {
    if (activities.length === 0) {
      history.push(`/${establishmentId}`)
    }
  }, [activities.length, history, establishmentId])

  const handleSubmit = useCallback(
    async (customerData: CustomerFormData) => {
      try {
        setLoading(true)

        const { customers, date } = appointment

        const activitiesToSend: IMenuActivity[][] = customers.map(
          (customer) => customer.activities
        )

        const {
          firstName,
          lastName,
          phone,
          email,
          comment,
          sendEmail,
          customerPaysFullAmount,
          giftcardBarcode,
          street,
          city,
          zip,
        } = customerData

        const response = await Client.postAppointment(
          establishmentId,
          activitiesToSend,
          firstName,
          lastName,
          phone,
          email,
          comment,
          sendEmail,
          date,
          customerPaysFullAmount,
          giftcardBarcode,
          street,
          city,
          zip
        )
        const storageObject: IUserData = {
          firstName,
          lastName,
          phone,
          email,
          isEmailSend: sendEmail,
          street,
          city,
          zip,
        }
        setUserData(storageObject)
        setUserToken(establishmentId, response.data.token, email)
        const mollieUrl = response.data.online_payment_url
        if (mollieUrl) {
          if (isNative) {
            // Checking if the link is supported for links with custom URL scheme.
            if (!inFrame && (await Linking.canOpenURL(mollieUrl))) {
              // Opening the link with some app, if the URL scheme is "http" the web link should be opened
              // by some browser in the mobile
              await Linking.openURL(mollieUrl)
              dispatch(setAppointmentStatus('booking_success'))
              setSuccessData({
                downloadId: response.data.appointments[0].id,
                response: response.data,
                type: 'appointment',
              })
              setLoading(false)
              return
            }
            setSuccessData({
              message: 'You received a mail with a deposit payment request.',
              response: response.data,
              type: 'appointment',
            })
            setLoading(false)
            return
          }
          window.open(mollieUrl, '_self')
        }
        dispatch(setAppointmentStatus('booking_success'))
        setSuccessData({
          downloadId: response.data.appointments[0].id,
          response: response.data,
          type: 'appointment',
        })
        setLoading(false)
      } catch (error) {
        setLoading(false)
        bookingFormErrorHandling(
          establishmentId,
          customerData.email,
          error,
          dispatch,
          history,
          t
        )
      }
    },
    [appointment, dispatch, t, history, establishmentId]
  )

  if (successData) {
    TagManager.dataLayer({
      dataLayer: {
        event: 'booking_success',
        pagePath: pagePath,
        pageTitle: t('Booking success'),
      },
    })

    ReactPixel.pageView()
    return (
      <StyledScrollView>
        <BookingSuccess
          responseType={successData}
          downloadId={successData.downloadId}
          successMessage={successData.message}
        />
      </StyledScrollView>
    )
  }

  if (isLoading)
    return (
      <StyledScrollView>
        <AbsoluteSpinner />
      </StyledScrollView>
    )

  return (
    <StyledScrollView>
      <CustomerForm submitHandler={handleSubmit} deposit={totalDeposit} />
    </StyledScrollView>
  )
}
