import { deDE } from './de_DE'
import { enBE } from './en_BE'
import { frBE } from './fr_BE'
import { frFR } from './fr_FR'
import { frLU } from './fr_LU'
import { nlBE } from './nl_BE'
import { nlNL } from './nl_NL'

export const privacyPolicy = {
  nlBE,
  enBE,
  frBE,
  frLU,
  nlNL,
  deDE,
  frFR,
}
