export const deDE = `**Datenschutzerklärung OPTIOS**
===============================

_Version 2022.01_

Diese Datenschutzerklärung gilt, wenn Sie die Website [www.optios.net](http://optios.squarespace.com) besuchen oder sich auf der Optios Plattform (im Folgenden "Optios" genannt) registrieren. Sie erklärt, warum die BV OPTIOS (im Folgenden als "Optios BV", "wir" oder "uns" bezeichnet) Ihre personenbezogenen Daten verarbeitet, wenn Sie unsere Dienste nutzen.

Inhalt

[1\. Wer ist der Kontrolleur?](#_Toc101864129)

[2\. Besuch der Website](#_Toc101864130)

[3\. Antragsteller](#_Toc101864131)

[4\. Optios](#_Toc101864132)

[4.1 Ein Konto erstellen](#_Toc101864133)

[4.2 Verwendung von Optios](#_Toc101864134)

5\. Arten von persönlichen und sensiblen Nutzerdaten, auf die die App zugreift, die sie sammelt und verwendet

[6\. Mit wem teilen wir Ihre persönlichen Daten?](#_Toc101864135)

[7\. Wie schützen wir Ihre persönlichen Daten?](#_Toc101864136)

[8\. Welche Rechte haben Sie und wie können Sie sie ausüben?](#_Toc101864137)

[9\. Hyperlinks](#_Toc101864138)

1\. Wer ist der Kontrolleur?
----------------------------

Der für die Verarbeitung Ihrer personenbezogenen Daten Verantwortliche ist:

                  **BV OPTIOS**

                  Antwerpener Steenweg 19

                  9080 Lochristi

                  0629.718.060

Wenn Sie Fragen über die Verarbeitung Ihrer persönlichen Daten durch Optios BV oder über den Inhalt dieser Datenschutzerklärung haben, nehmen Sie bitte Kontakt mit uns auf unter help@optios.net.

Optios BV behält sich das Recht vor, diese Datenschutzerklärung von Zeit zu Zeit zu ändern oder zu überarbeiten. Wenn Sie bei Optios registriert sind, werden wir Sie benachrichtigen, bevor diese Änderungen oder Überarbeitungen in Kraft treten.

2\. Besuch der Website
----------------------

Wenn Sie unsere Website besuchen, können unter bestimmten Umständen personenbezogene Daten von Ihnen mittels Cookies erhoben werden (siehe unsere Cookie-Erklärung).

Unsere Website bietet Ihnen auch verschiedene Möglichkeiten, uns Ihre persönlichen Daten mitzuteilen. Sie können zum Beispiel eine Beratung anfordern oder uns über ein Kontaktformular kontaktieren.

In diesen Fällen bitten wir Sie um die Informationen, die erforderlich sind, um Ihnen die gewünschten Informationen zukommen zu lassen, wie z. B. Ihren Namen und Ihre E-Mail-Adresse. Diese Verarbeitungen beruhen stets auf unserem berechtigten Interesse, das darin besteht, auf Ihre verschiedenen Anfragen zu reagieren.  

Unsere Website bietet Ihnen auch die Möglichkeit, über den Chatbot mit uns in Kontakt zu treten. In diesem Fall werden die Daten, die Sie hinterlassen, verarbeitet. Diese Verarbeitung ist Teil unseres berechtigten Interesses, Ihre Fragen zu beantworten.

3\. Antragsteller
-----------------

Wenn Sie sich um eine Stelle bei Optios bewerben, erfassen wir folgende Daten: Ihren Namen, Ihre E-Mail-Adresse, Ihre Telefonnummer, eventuell ein Foto, Ihren Lebenslauf, Ihr Anschreiben und alle anderen Informationen, die Sie uns in Ihrer Korrespondenz mitteilen.

Optios BV wird die oben genannten Informationen nur für interne Rekrutierungszwecke verwenden. Wenn Ihre Bewerbung nicht berücksichtigt wird, werden wir Ihre personenbezogenen Daten für maximal 30 Tage aufbewahren. Wenn wir Ihre Bewerbung in unserer Einstellungsreserve aufbewahren möchten, werden wir Sie immer um Ihr Einverständnis bitten. Ihre Daten werden für maximal 2 Jahre in unserer Einstellungsreserve aufbewahrt. 

4\. Optios
----------

### 4.1 Ein Konto erstellen

Wenn Sie Ihr Optios-Konto anlegen, werden Ihr Name, Ihr Firmenname und Ihre E-Mail-Adresse abgefragt.

Die Verarbeitung dieser personenbezogenen Daten ist erforderlich, um den Vertrag zwischen Ihnen und uns über die Nutzung von Optios zu erfüllen. Wenn Sie Optios BV diese personenbezogenen Daten nicht zur Verfügung stellen möchten, können Sie Optios nicht nutzen, da diese Verarbeitung notwendig ist, um Ihnen den Zugang zu Optios und dessen Nutzung zu erleichtern.

### 4.2 Verwendung von Optios

Wenn Sie Optios nutzen, erfassen wir die folgenden zusätzlichen personenbezogenen Daten:

Handynummer, eventuelle weitere Telefonnummern, Geschlecht und die Sprache, in der wir mit Ihnen kommunizieren. Im Falle eines Einzelunternehmens verarbeiten wir auch Ihre Adresse, Kontonummer und Umsatzsteuernummer.

Wir verarbeiten diese personenbezogenen Daten aus den folgenden Gründen:

§ Um unsere Dienstleistungen kontinuierlich zu verbessern und neue Funktionen zu entwickeln. Die Verarbeitung erfolgt auf der Grundlage unseres berechtigten Interesses, insbesondere um unsere Dienstleistungen besser an die Bedürfnisse unserer Kunden anzupassen;

§ Die Sicherheit von Optios für Sie und alle unsere Nutzer. Diese Verarbeitung beruht auf unserem berechtigten Interesse, eine sichere Plattform für alle Beteiligten bereitzustellen;

§ Überwachen, wie Optios genutzt wird und ob die Optios-Nutzungsbedingungen missbraucht werden. Diese Verarbeitung ist mit der ordnungsgemäßen Erfüllung des Vertrags zwischen Ihnen und Optios vereinbar;

§ Die korrekte Abrechnung der erbrachten Leistungen.

5\. Arten von persönlichen und sensiblen Nutzerdaten, auf die die App zugreift, die sie sammelt und verwendet
-------------------------------------------------------------------------------------------------------------

Bei der Nutzung der Optios Online-Reservierung (App) wird der Nutzer aufgefordert, persönliche Daten einzugeben, um seinen Termin zu bestätigen. Nämlich:

\- Vornamen

\- Nachname

\- Telefon

\- E-Mail Adresse

Diese Daten werden zusammen mit den Terminangaben (Datum, Uhrzeit und Dienstleistungen) in der Datenbank von Optios gespeichert. Diese Daten können vom Saloninhaber für Marketing- und Kommunikationszwecke verwendet werden.

Die mit der Optios-App gesammelten Daten werden nicht an andere Parteien als Optios und den Salon selbst weitergegeben.

6\. Mit wem teilen wir Ihre persönlichen Daten?
-----------------------------------------------

Ihre personenbezogenen Daten werden ausschließlich zu den oben genannten Zwecken verarbeitet.

Optios BV kann für die vorgenannten Zwecke auch auf Dritte zurückgreifen, wie z.B. für die Softwareintegration (z.B. Softwarelieferanten) und für Hosting-Zwecke (z.B. Cloud-Provider, externe Datenzentren...), aber auch auf eine externe Personalvermittlung.

Ihre persönlichen Daten werden unter keinen Umständen zu kommerziellen Zwecken an Dritte weitergegeben.

Darüber hinaus werden Ihre Daten nicht in Länder außerhalb des Europäischen Wirtschaftsraumes übermittelt. Sollte sich dies in Zukunft ändern, werden wir sicherstellen, dass wir angemessene Sicherheitsvorkehrungen und zusätzliche Maßnahmen getroffen haben, wie es die Datenschutzgesetze verlangen.

7\. Wie schützen wir Ihre persönlichen Daten?
---------------------------------------------

Optios BV ergreift alle angemessenen technischen und organisatorischen Maßnahmen, um den Schutz Ihrer personenbezogenen Daten vor Missbrauch, Verlust oder unberechtigtem Zugriff zu gewährleisten. Daher wird der Zugang zu Ihren persönlichen Daten nur auf einer Need-to-know-Basis gewährt. Diese Maßnahmen beruhen immer auf dem aktuellen Stand der Technik. Die Sicherheit Ihrer persönlichen Daten ist jedoch immer eine Verpflichtung, für die wir uns nach besten Kräften einsetzen und für die wir keine absolute Garantie geben können.

8\. Welche Rechte haben Sie und wie können Sie sie ausüben?
-----------------------------------------------------------

Wenn Sie eine Beschwerde, eine Frage oder ein Problem mit der Art und Weise haben, wie wir Ihre personenbezogenen Daten verarbeiten, können Sie sich über die unter Punkt 1 genannten Kontaktdaten an uns wenden.

Wenn Sie sich mit uns in Verbindung setzen, um Ihre Rechte geltend zu machen, werden wir Ihnen innerhalb eines Monats antworten. In Ausnahmefällen kann dies länger dauern (bis zu maximal 3 Monaten), aber in diesem Fall werden wir Sie innerhalb eines Monats informieren. Ob Sie Ihre Rechte ausüben können oder nicht, hängt immer mit der Verarbeitung selbst und der Rechtsgrundlage zusammen.

Wenn Sie von Ihren Rechten Gebrauch machen möchten, bitten wir Sie, stets deutlich zu beschreiben, welches Recht Sie ausüben möchten und wie (z. B. per E-Mail, per Post...) Sie die gewünschten Informationen erhalten möchten. Außerdem haben wir die Pflicht, uns zu identifizieren. Wir müssen daher immer überprüfen, ob Sie tatsächlich berechtigt sind, die angeforderten Informationen zu erhalten, weshalb wir Sie gegebenenfalls um eine Kopie Ihres Personalausweises bitten. 

**Recht auf Zugang und Berichtigung**

Wenn Sie wissen möchten, welche persönlichen Daten Optios BV verarbeitet und zu welchen Zwecken, nehmen Sie bitte Kontakt mit uns auf. Wir können Ihnen kostenlos eine Kopie der von uns verarbeiteten personenbezogenen Daten zur Verfügung stellen. Wenn Sie der Meinung sind, dass Ihre personenbezogenen Daten nicht korrekt oder aktuell sind, können Sie uns auffordern, diese zu korrigieren.

**Recht auf Löschung und Einschränkung**

Sie haben das Recht, uns aufzufordern, Ihre personenbezogenen Daten zu löschen. Sollten wir jedoch gesetzlich verpflichtet sein, bestimmte personenbezogene Daten länger aufzubewahren, können wir dieser Bitte nicht nachkommen. In diesem Fall werden wir Sie darüber informieren.

Wenn Sie gute Gründe dafür haben, können Sie uns auffordern, die Verarbeitung Ihrer personenbezogenen Daten einzuschränken. Zum Beispiel, weil Sie die Richtigkeit Ihrer Daten anzweifeln.

**Recht auf Zurückbehaltung**

Wenn die Verarbeitung Ihrer personenbezogenen Daten auf unserem berechtigten Interesse beruht oder Teil der Erfüllung einer Aufgabe von allgemeinem Interesse oder einer öffentlichen Behörde ist, können Sie der Verarbeitung Ihrer personenbezogenen Daten in begründeter Weise widersprechen.

**Recht auf Datenübertragbarkeit**

Wenn wir Ihre personenbezogenen Daten auf der Grundlage Ihrer (ausdrücklichen) Einwilligung oder im Rahmen der Erfüllung des Vertrags zwischen Ihnen und uns verarbeiten, können Sie verlangen, dass wir die uns von Ihnen zur Verfügung gestellten personenbezogenen Daten in einer strukturierten, gängigen und maschinenlesbaren Form bereitstellen oder sie an einen anderen für die Verarbeitung Verantwortlichen übermitteln, sofern die Verarbeitung mit automatisierten Mitteln erfolgt.

**Recht auf Widerruf Ihrer Zustimmung**

Wenn die Verarbeitung Ihrer personenbezogenen Daten durch Optios BV auf Ihrer (ausdrücklichen) Zustimmung beruht, haben Sie das Recht, diese Zustimmung jederzeit zu widerrufen. Der Widerruf Ihrer Einwilligung berührt jedoch nicht die Rechtmäßigkeit der Verarbeitung, die vor dem Widerruf Ihrer Einwilligung erfolgt ist.

**Recht auf Beschwerde**

Wenn Sie mit der Art und Weise, wie Optios BV Ihre personenbezogenen Daten verarbeitet, nicht zufrieden sind oder wenn Sie das Gefühl haben, dass Sie von Optios BV in Bezug auf Ihre Datenschutzbeschwerden nicht ausreichend angehört wurden, haben Sie jederzeit das Recht, eine Beschwerde bei der belgischen Aufsichtsbehörde einzureichen:

                  **Datenschutzbehörde**

                  Pressestraße 35                

                  1000 Brüssel

                  Tel. 02/274.48.00

                  Fax. 02/274.48.35

            [contact@apd-gba.be](mailto:contact@apd-gba.be)

9\. Hyperlinks
--------------

Diese Website kann Hyperlinks zu Websites anderer Parteien enthalten. Wenn Sie auf diese Hyperlinks klicken, verlassen Sie die Website von Optios BV. Optios BV ist in keiner Weise verantwortlich für die Datenschutzpraktiken dieser Websites. Es wird immer empfohlen, die Datenschutzerklärungen dieser externen Websites sorgfältig zu lesen.`