import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from '../Router'
import { getAccountList } from './client/getAccountList'
import { Logo, Spinner } from '../App/Components'
import { StyledScrollView } from '../App/Components/AppContainer'
import {
  AccountInfo,
  AccountsContainer,
  InfoText,
  StyledButtonWithText,
} from './style'
import { useAppDispatch, useAppSelector } from '../store/hooks'
import { setUserData } from '../App/Util'
import { setUserToken } from '../App/Util/localStorageHelper/userToken/setUserToken'
import { grandUserAccess, setAuthState } from '../Settings/Store/Action'

interface IAccountData {
  token: string
  first_name: string
  last_name: string
  email: string
  age: string
}

export const AccountSelection = () => {
  const [accountList, setAccountList] = useState<IAccountData[]>([])

  const settings = useAppSelector((state) => state.settings.values)

  const { userToken, establishmentId } =
    useParams<{ userToken: string; establishmentId: string }>()

  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useAppDispatch()

  useEffect(() => {
    const fetchData = async () => {
      const response = await getAccountList(userToken, establishmentId)
      const responseData = await response.data.list
      setAccountList(responseData)
    }
    fetchData().catch((e) => console.log(e))
  }, [])

  if (accountList.length === 0) {
    return <Spinner />
  }

  const chooseAccount = (account: IAccountData) => {
    setUserData({
      email: account.email,
      firstName: account.first_name,
      lastName: account.last_name,
    })
    setUserToken(establishmentId, account.token, account.email)
    dispatch(setAuthState('authenticated'))
    dispatch(grandUserAccess())
    return history.push(`/${establishmentId}`)
  }

  return (
    <StyledScrollView autoScroll>
      <Logo />
      <AccountsContainer>
        {accountList.map((account) => (
          <>
            <AccountInfo>
              <InfoText>{account.first_name}</InfoText>
              <InfoText>{account.last_name}</InfoText>
              <InfoText>{account.age}</InfoText>
            </AccountInfo>
            <StyledButtonWithText
              onPress={() => chooseAccount(account)}
              backgroundcolor={settings.backgroundColor + 15}
              fontcolor={settings.fontColor}
              activefontcolor={settings.backgroundColor}
              hoversensitive
            >
              {t('Choose this account')}
            </StyledButtonWithText>
          </>
        ))}
      </AccountsContainer>
    </StyledScrollView>
  )
}
