import styled from 'styled-components/native'

import { TRedirectStatus } from '../../../Appointment/store/interface/IAppointmentState'

const statusColorSwitch = (status: TRedirectStatus) => {
  switch (status) {
    case 'contact_support':
    case 'blocked':
      return '#d24444'
    default:
      return '#fbb03b'
  }
}

export const BookingStatusContainer = styled.View<{
  status: TRedirectStatus
}>`
  width: 100%;
  background-color: ${({ status }) => statusColorSwitch(status)};
  padding: 30px 0;
  margin: 20px 0;
`

export const StatusText = styled.Text`
  color: white;
  text-align: center;
`
