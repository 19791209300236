import styled from 'styled-components/native'

import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'

import StyleVariables from '../../../../../StyleVariables'

export const CategorySectionContainer = styled.View`
    margin: 22px 0 0 0;
`

export const CategoryHeading = styled.View<{
    bgColor: string
}>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin: 0 0 11px 0;
    padding: 8px;
    border-radius: 4px;
    background-color: ${({ bgColor }) => bgColor};
`

export const CategoryColor = styled.View<{
    color: string
}>`
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: ${({ color }) => color};
    margin-right: 22px;
`

export const CategoryImage = styled.Image`
    width: 48px;
    height: 48px;
    border-radius: 24px;
    margin-right: 22px;
`

export const CategoryTitle = styled.Text<{
    fontcolor: string
}>`
    font-size: 22px;
    font-weight: bold;
    color: ${({ fontcolor }) =>
    fontcolor || StyleVariables.colorGrey};
`

export const CategoryIcon = styled(FontAwesomeIcon)`
    position: absolute;
    right: 16px;
`

export const CategoryActivitiesContainer = styled.View<{
    open: boolean
}>`
    height: ${({ open }) => (open ? 'auto' : 0)};
    overflow: hidden;
`
