import React, { PropsWithChildren } from 'react'
import { Platform } from 'react-native'
import { useSelector } from 'react-redux'

import { IAppState } from '../../../../store/IAppState'
import { AbsoluteSpinner } from '../../Spinners'

import { AppContainer as Container } from './style'
import { useMobileCheck } from '../../../Util/useMobileCheck'

interface IProps {
  settingsProtection?: boolean
}

export const AppWrapper = ({
  children,
  settingsProtection = true,
}: PropsWithChildren<IProps>) => {
  const settings = useSelector((state: IAppState) => state.settings.values)
  const mobileCheck = useMobileCheck()

  if (!settings && settingsProtection) {
    return <AbsoluteSpinner />
  }

  return (
    <Container
      backgroundcolor={settings ? settings.backgroundColor : '#ffffff'}
      web={Platform.OS === 'web'}
      isMobile={mobileCheck}
    >
      {children}
    </Container>
  )
}
