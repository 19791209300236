import React from 'react'
import { ScrollView } from 'react-native'
import styled from 'styled-components'
import Markdown from 'react-native-markdown-display'
import { ProximaNova, ProximaNovaBold } from '../App/Components/TextComponent'
import { privacyPolicy } from './data'

const StyledScrollView = styled(ScrollView)`
  padding: 20px;
`

const PageTitle = styled(ProximaNovaBold)`
  font-size: 25px;
`

const PointTitle = styled(ProximaNovaBold)`
  font-size: 23px;
  line-height: 23px;
  width: 100%;
  padding: 12px 0;
  display: flex;
`

const ItalicText = styled(ProximaNova)`
  padding: 12px 0;
`

const TextParagraph = styled(ProximaNova)`
  padding: 12px 0;
  width: 100%;
  text-align: left;
`

const rules = {
  heading1: (node, children) => (
    <PageTitle key={node.key}>{children}</PageTitle>
  ),
  heading2: (node, children) => (
    <PointTitle key={node.key}>{children}</PointTitle>
  ),
  link: (node, children) => <ItalicText key={node.key}>{children}</ItalicText>,
  body: (node, children) => (
    <TextParagraph key={node.key}>{children}</TextParagraph>
  ),
}

interface IProps {
  locale: string
}

// When adding update or new version of privacy policy, go to the file containing the markdown const.
// Here press cmd + f, then search for \\. and replace all with blank.
export const PrivacyPolicy = ({ locale }: IProps) => {
  let loadedLocale = privacyPolicy.enBE

  switch (locale) {
    case 'nl_BE': {
      loadedLocale = privacyPolicy.nlBE
      break
    }
    case 'fr_BE': {
      loadedLocale = privacyPolicy.frBE
      break
    }
    case 'nl_NL': {
      loadedLocale = privacyPolicy.nlNL
      break
    }
    case 'fr_LU': {
      loadedLocale = privacyPolicy.frLU
      break
    }
    case 'de_DE': {
      loadedLocale = privacyPolicy.deDE
      break
    }
    case 'fr_FR': {
      loadedLocale = privacyPolicy.frFR
      break
    }
    case 'en_BE':
    default: {
      loadedLocale = privacyPolicy.enBE
      break
    }
  }

  return (
    <StyledScrollView>
      <Markdown rules={rules}>{loadedLocale}</Markdown>
    </StyledScrollView>
  )
}
