import React from 'react'
import { useTranslation } from 'react-i18next'

import { ButtonWithText } from '../../App/Components'
import { useHistory } from '../../Router'

import {
  CardData,
  InnerCardContainer,
  OuterCardContainer,
  StyledText,
} from './style'
import { LastMinutePrice } from '../LastMinutePrice/LastMinutePrice'
import { ISettingValues } from '../../Settings/ISettings'
import { ILastMinute } from '../store/ILastMinute'

type Props = {
  lastMinute: ILastMinute
  settings: ISettingValues
}

export const LastMinuteCard = ({ settings, lastMinute }: Props) => {
  const history = useHistory()

  const { t } = useTranslation()
  const { fontColor, showPrice, highlightColor, backgroundColor } = settings
  const { activities, description, lastMinutePrice, id } = lastMinute

  const startDate = activities[0].startsAt
  const hour = startDate.format('LT')
  const dateFull = startDate.format('dddd, D MMM YYYY')
  const isShowActivitiesWithNoDuration =
    settings.onlineAppointmentsShowActivitiesWithNoDuration

  const regularTotal = activities.reduce(
    (total, activity) => total + activity.price,
    0
  )

  const bookAppointment = () => {
    history.push(`last-minutes/${id}/book`)
  }

  return (
    <OuterCardContainer backgroundcolor={highlightColor}>
      <InnerCardContainer>
        <CardData>
          {!description || <StyledText>{description}</StyledText>}
          <StyledText fontcolor={fontColor}>
            {`${t(dateFull)} ${t('at')} ${hour}`}
          </StyledText>
          {activities.map((activity) => {
            if (
              isShowActivitiesWithNoDuration === false &&
              activity.startsAt.isSame(activity.endsAt)
            )
              return null
            return (
              <StyledText fontcolor={fontColor}>{activity.name}</StyledText>
            )
          })}
          <LastMinutePrice
            isShowPrice={showPrice}
            fontColor={fontColor}
            regularTotal={regularTotal}
            lastMinutePrice={lastMinutePrice}
          />
        </CardData>
        <ButtonWithText
          backgroundcolor="transparent"
          activefontcolor={backgroundColor}
          fontcolor={highlightColor}
          hoversensitive
          onPress={bookAppointment}
        >
          {t('Book')}
        </ButtonWithText>
      </InnerCardContainer>
    </OuterCardContainer>
  )
}
