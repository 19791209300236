import { Action } from 'redux'

import Client from '../Client/Client'
import { IMenuCategory } from './IMenuCategory'

export const MENU_LOADING = 'MENU_LOADING'
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS'

export function getCategories(establishmentId: string) {
  return (dispatch: any) => {
    dispatch(menuLoading())

    Client.getCategories(establishmentId).then((response: IMenuCategory[]) => {
      dispatch(getCategoriesSuccess(response))
    })
  }
}

export type MenuLoading = Action<typeof MENU_LOADING>

export function menuLoading(): MenuLoading {
  return {
    type: MENU_LOADING,
  }
}

function getCategoriesSuccess(categories: any) {
  return {
    type: GET_CATEGORIES_SUCCESS,
    categories,
  }
}
