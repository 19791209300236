import React from 'react'
import { useSelector } from 'react-redux'

import { useLocation, useParams } from '../../../../Router'
import { IAppState } from '../../../../store/IAppState'
import { LangDropDown } from '../../LangDropDown/LangDropDown'
import { PreviousAppointmentsButton } from '../../PreviousAppointmentsButton/PreviousAppointmentsButton'

import { OptionsContainer as Container } from './style'

type Props = {
  isHidePreviousAppointments?: boolean
}

export const OptionsContainer: React.FC<Props> = ({
  isHidePreviousAppointments,
}) => {
  const settings = useSelector((state: IAppState) => state.settings.values)
  const location = useLocation()
  const { establishmentId } = useParams<{ establishmentId?: string }>()

  const menuRootPath = `/${establishmentId}/menu`
  const activitiesRootPath = `/${establishmentId}/activities`

  return (
    <Container>
      {!isHidePreviousAppointments ? (
        <PreviousAppointmentsButton
          rootPathCheck={
            location.pathname === menuRootPath ||
            location.pathname === `${menuRootPath}/` ||
            location.pathname === activitiesRootPath ||
            location.pathname === `${activitiesRootPath}/`
          }
        />
      ) : null}
      <LangDropDown
        highlightColor={settings ? settings.highlightColor : '#fff'}
        backgroundColor={settings ? settings.backgroundColor : 'gray'}
      />
    </Container>
  )
}
