import React from 'react'
import { useTranslation } from 'react-i18next'
import { ScrollView, Text } from 'react-native'
import { useSelector } from 'react-redux'

import { LangDropDown } from '../App/Components'
import { StyledScrollView } from '../App/Components/AppContainer'
import { ISettingValues } from '../Settings/ISettings'
import { IAppState } from '../store/IAppState'
import {useParams} from "react-router";

function NoSalon() {
  const { t } = useTranslation()
  const {locationIds} = useParams()

  const settings: undefined | ISettingValues = useSelector<
    IAppState,
    ISettingValues
  >((state) => (state.settings.values ? state.settings.values : undefined))

  return (
    <StyledScrollView>
      <ScrollView>
        <Text style={{ textAlign: 'center' }}>
          {t("We're sorry, we could not find a salon linked to this address")}
        </Text>
      </ScrollView>
      <LangDropDown
        highlightColor={settings ? settings.highlightColor : '#fff'}
        backgroundColor={settings ? settings.backgroundColor : 'gray'}
      />
    </StyledScrollView>
  )
}

export default NoSalon
