import { URL_API } from 'react-native-dotenv'

import axios, { AxiosResponse } from 'axios'
import { TAuthTarget } from '../TAuthState'
import { removeUserToken } from '../../../../App/Util/localStorageHelper/userToken/setUserToken'


type Response = {
  status: 'valid' | 'verification_mail_sent' | 'no_account_exists' | 'account_confirmed'
  token?: string
}

export const checkUserEmailAndToken = async (
  establishmentId: string,
  email: string,
  authTarget: TAuthTarget,
  token?: string | undefined
): Promise<AxiosResponse<Response>> => {
  const appleMail = `apple${establishmentId}@optios.net`
  if (email === appleMail) {
    removeUserToken(establishmentId, email)
  }

  const requestOptions: object = {
    method: 'POST',
    url: `${URL_API}/anonymous/customers/verify`,
    data: {
      establishment_id: establishmentId,
      email,
      token: token || undefined,
      redirect_target: authTarget,
    },
  }

  return axios(requestOptions)
}
