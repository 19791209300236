import React from 'react'
import { useTranslation } from 'react-i18next'

import { useHistory } from 'react-router'
import { LastMinuteListHeader, Tile } from '../../App/Components'
import { useLocation, useParams } from '../../Router'

import { useAppSelector } from '../../store/hooks'

type Props = {
  isListView: boolean
}

export const LastMinutesItem: React.FC<Props> = ({ isListView }) => {
  const history = useHistory()
  const location = useLocation()
  const { t } = useTranslation()
  const { establishmentId } = useParams<{ [key: string]: string }>()

  const lastMinutes = useAppSelector((state) => state.lastMinute.list)

  const currentPath = location.pathname.replace(/\/+$/, '')
  const isRootPath: boolean =
    currentPath === `/${establishmentId}/menu` ||
    currentPath === `/${establishmentId}/activities`

  const hasLastMinutes: boolean =
    typeof lastMinutes !== 'undefined' && lastMinutes.length > 0 && isRootPath

  if (!hasLastMinutes) {
    return null
  }

  if (isListView) {
    return (
      <LastMinuteListHeader
        key="LastMinutesTile"
        title={t('Last minutes')}
        image="//files.optios.net/?id=1046740"
      />
    )
  }

  return (
    <Tile
      key="LastMinutesTile"
      title={t('Last minutes')}
      onPress={(event) => {
        event.preventDefault()
        event.stopPropagation()
        history.push(`/${location.pathname.split('/')[1]}/last-minutes`)
      }}
      image="//files.optios.net/?id=1046740"
    />
  )
}
