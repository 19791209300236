import styled from 'styled-components/native'
import { ProximaNova } from '../../App/Components'

export const OuterCardContainer = styled.View<{ backgroundcolor: string }>`
  margin-top: 30px;
  background-color: ${({ backgroundcolor }) => backgroundcolor + 15};
`

export const InnerCardContainer = styled.View`
  margin: 30px 70px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  min-width: 200px;
`

export const CardData = styled.View`
  padding-left: 8px;
  width: 100%;
`

export const StyledText = styled(ProximaNova)<{
  fontcolor: string
  textDecoration: boolean
}>`
  color: ${({ fontcolor }) => fontcolor};
  text-decoration: ${({ textDecoration }) =>
    textDecoration ? 'line-through' : 'none'};
  text-align: left;
`

export const DescriptionContainer = styled.View`
  display: flex;
  flex-direction: row;
  width: 100%;
`

export const StyledDescription = styled(StyledText)`
  margin-bottom: 5px;
`
