import styled from 'styled-components/native'

export const ActivityContainer = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 5px 0;
  max-width: 330px;
  text-align: left;
  align-items: center;
`

export const ActivityName = styled.Text<{
  highlightcolor: string
}>`
  color: ${({ highlightcolor }) => highlightcolor || '#fff'};
  flex-grow: 1;
  margin: 4px 6px 0 28px;
  width: 300px;
  max-width: 100%;
  overflow: hidden;
`

export const EmptyRemoveContainer = styled.View`
  width: 25%;
  height: 12.5px;
`

export const EmptySecondContainer = styled.View`
  width: 22px;
`
