import React from 'react'
import { useHistory } from 'react-router'
import { editUpdatePreviousAppointment } from '../../../Appointment/store/Actions'
import { Tile } from '../../../App/Components'
import { IPerson } from '../../../SelectPerson/interface/IPerson'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { IPreviousAppointmentActivity } from '../../../PreviousAppointments/IPreviousAppointment'

type Props = {
  activity: IPreviousAppointmentActivity
  persons: IPerson[]
  establishmentId: string
  activityId: string | undefined
}

export const EligibleEditPerson = ({
  activity,
  persons,
  establishmentId,
  activityId,
}: Props) => {
  const history = useHistory()
  const dispatch = useAppDispatch()

  const previousAppointment = useAppSelector(
    (state) => state.appointment.editPreviousAppointment
  )

  const eligibleTilePress = (person: IPerson) => {
    dispatch(editUpdatePreviousAppointment(person, Number(activityId)))
    history.push(`/${establishmentId}/appointment/move/${activityId}`)
  }

  const filteredPersons = persons.filter((person) => {
    if (
      person.isCabine ||
      !person.isVisibleOnline ||
      !person.isVisibleInCalender
    ) {
      return false
    }
    if (activityId) return person.activityIds.includes(activity.id)

    const activityIds = previousAppointment.activities.map(
      (aActivity) => aActivity.id
    )
    return activityIds.some((id) => person.activityIds.includes(id))
  })

  if (filteredPersons.length === 0) {
    return null
  }

  const eligiblePersons = filteredPersons.map((person) => {
    if (
      typeof activityId !== 'undefined' &&
      !person.activityIds.includes(activity.id)
    ) {
      return null
    }

    const newImageUri = person.avatar.slice(6)

    return (
      <Tile
        key={person.id}
        title={person.firstName}
        image={newImageUri}
        onPress={() => eligibleTilePress(person)}
      />
    )
  })
  return <>{eligiblePersons}</>
}
