import React from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from 'react-native'
import { StyledScrollView } from '../../App/Components/AppContainer/StyledScrollView/StyledScrollView'
import { LocationsWrapper } from './style'

export const NoMultiLocations = () => {
  const { t } = useTranslation()

  return (
    <StyledScrollView>
      <LocationsWrapper>
        <Text>{t(`Please provide multiple establishment id's`)}</Text>
      </LocationsWrapper>
    </StyledScrollView>
  )
}
