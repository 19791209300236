import styled from 'styled-components/native'
import webstyled from 'styled-components'

export const TileTextStyle = styled.Text`
  color: white;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
`

export const TileTextApp = styled(TileTextStyle)`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(1, 1, 1, 0.2);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 0.8em 0.5em 0.5em;
`

export const TileTextWebContainer = webstyled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  color: white;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  padding: 0.8em 0.5em 0.5em;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.3) 50%
  );
`
