import React, { FunctionComponent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { faPlusCircle } from '@fortawesome/pro-light-svg-icons'

import { FaIcon, ProximaNova } from '../../../App/Components'
import { IAppState } from '../../../store/IAppState'

import { IProps } from './IProps'
import { AddPersonContainer, TouchableContainer } from './style'
import { WarningModal } from '../../../App/Components/WarningModal/WarningModal'

export const AddCustomerButton: FunctionComponent<IProps> = ({
  isMultipleCustomer,
}) => {
  const [isWarningOpen, setIsWarningOpen] = useState<boolean>(false)
  const { t } = useTranslation()

  const { settings } = useSelector((state: IAppState) => ({
    settings: state.settings.values,
  }))

  const openWarningModal = () => {
    setIsWarningOpen(true)
    setTimeout(() => {
      setIsWarningOpen(false)
    }, 500)
  }

  if (!isMultipleCustomer) {
    return null
  }

  return (
    <TouchableContainer onPress={openWarningModal}>
      <WarningModal
        description="By clicking on 'Add Person' you will add a additional person to this appointment. If you wish to cancel this, click the cross icon."
        title="Add a other person"
        isModalOpen={isWarningOpen}
      />
      <AddPersonContainer>
        <FaIcon
          size="lg"
          icon={faPlusCircle}
          color={settings.highlightColor}
          cssStyle={{
            marginRight: '10px',
            alignSelf: 'baseline',
          }}
        />
        <ProximaNova>{t('Add extra person')}</ProximaNova>
      </AddPersonContainer>
    </TouchableContainer>
  )
}
