import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'

import { BackButton, Logo, Spinner, WelcomeMessage } from '../App/Components'
import { StyledScrollView } from '../App/Components/AppContainer'
import { PreviousAppointmentList } from './Components'
import { NoAppointment } from './Components/NoAppointment/NoAppointment'
import { getUserData, IUserData } from '../App/Util'
import { useAppDispatch, useAppSelector } from '../store/hooks'
import {
  cancelWorkshop,
  getPreviousAppointments,
  removeUpdatePreviousAppointment,
} from '../Appointment/store/Actions'
import { WorkshopSubscriptionCard } from '../Workshop/WorkshopSubscriptionCard/WorkshopSubscriptionCard'
import { IWorkshopSubscription } from '../Workshop/store/IWorkshop'
import { SuccessMessage } from '../App/Components/BookingSuccess/Components/SuccessMessage/SuccessMessage'
import { getUserToken } from '../App/Util/localStorageHelper/userToken/setUserToken'

export const PreviousAppointments = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [successMessage, setSuccessMessage] = useState<undefined | string>()

  const dispatch = useAppDispatch()
  const { establishmentId } = useParams<{ [key: string]: string }>()

  const userData: IUserData = getUserData()
  const userEmail = userData.email
  const token = getUserToken(establishmentId, userEmail)

  const { settings, previousAppointments, workshops, previousAppointmentEdit } =
    useAppSelector((state) => ({
      settings: state.settings.values,
      previousAppointments: state.appointment.previousAppointments,
      previousAppointmentEdit: state.appointment.editPreviousAppointment,
      workshops: state.appointment.subscribedWorkshops,
    }))

  useEffect(() => {
    if (previousAppointmentEdit) dispatch(removeUpdatePreviousAppointment())
  }, [])

  useEffect(() => {
    if (typeof previousAppointments === 'undefined') {
      setIsLoading(true)
      dispatch(getPreviousAppointments(establishmentId, userEmail, token))
    } else {
      setIsLoading(false)
    }
  }, [previousAppointments])

  if (!previousAppointments || !workshops || isLoading) {
    return <Spinner />
  }

  if (previousAppointments.length === 0 && workshops.length === 0) {
    return <NoAppointment />
  }

  const onCancelWorkshop = (workshopSubscription: IWorkshopSubscription) => {
    dispatch(
      cancelWorkshop(
        workshopSubscription.uuid,
        workshopSubscription.subscriptionUuid,
        token
      )
    )
  }

  return (
    <StyledScrollView>
      <WelcomeMessage />
      <Logo />
      <BackButton extraMargin customPath={`/${establishmentId}/account`} />
      {!successMessage ? null : <SuccessMessage message={successMessage} />}
      <PreviousAppointmentList
        previousAppointments={previousAppointments}
        hoursToCancel={settings.hoursToCancel}
        hoursBeforeNotAllowedToMove={settings.hoursBeforeNotAllowedToMove}
      />
      {workshops.map((workshop) => (
        <WorkshopSubscriptionCard
          workshop={workshop}
          fontColor={settings.fontColor}
          highlightColor={settings.highlightColor}
          backgroundColor={settings.backgroundColor}
          token={token}
        />
      ))}
    </StyledScrollView>
  )
}
