import { Reducer } from 'redux'

import { SURVEY_LOADING, SURVEY_SUCCESS } from './ActionTypes'
import { ISurveyState } from './IState'

const initialState: ISurveyState = {
    loading: false,
}
export const reducer: Reducer<ISurveyState> = (
    state: ISurveyState = initialState,
    action: any
): ISurveyState => {
    switch (action.type) {
        case SURVEY_LOADING:
            return {
                ...state,
                loading: true,
            }
        case SURVEY_SUCCESS:
            return {
                ...state,
                loading: false,
                surveys: [action.payload.survey],
            }
        default:
            return state
    }
}
