import Client from '../../../Settings/Client/Client'

export const getLocationData = async (locationIds: string[]) =>
  Promise.all(
    locationIds.map(async (locationId) => {
      const clientSettings = await Client.getSettings(locationId)
      return clientSettings.locations.find(
        (details) => details.id.toString() === locationId
      )
    })
  )
