import styled from 'styled-components/native'

import { ButtonWithText } from '../App/Components'

export const FormContainer = styled.View`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
`

export const ExtendedButtonWithText = styled(ButtonWithText)`
    width: 70%;
`
