import styled from 'styled-components/native'

export const BookingError = styled.View`
  width: 100%;
  background-color: #d24444;
  padding: 30px 0;
  margin: 20px 0;
`

export const BookingErrorText = styled.Text`
  color: white;
  text-align: center;
`
