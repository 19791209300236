import React, { useCallback, useEffect, useMemo, useState } from 'react'
import ReactPixel from 'react-facebook-pixel'
import TagManager from 'react-gtm-module'

import { useTranslation } from 'react-i18next'
import { StyledScrollView } from '../../App/Components/AppContainer'
import { BookingSuccess } from '../../App/Components/BookingSuccess/BookingSuccess'
import { setAppointmentStatus } from '../../Appointment/store/Actions'
import { CustomerFormData } from '../../CustomerForm/ICustomerFormData'
import { useHistory, useParams } from '../../Router'
import { IAppState } from '../../store/IAppState'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import Client from '../Client/Client'
import { IApiLastMinuteResponse } from '../store/IAPILastMinuteResponse'
import { IUserData, setUserData } from '../../App/Util'
import { setUserToken } from '../../App/Util/localStorageHelper/userToken/setUserToken'
import { bookingFormErrorHandling } from '../../App/Util/bookingFormErrorHandling'
import { AbsoluteSpinner } from '../../App/Components'
import { CustomerForm } from '../../CustomerForm/CustomerForm'

export const BookLastMinute = () => {
  const [isLoading, setLoading] = useState<boolean>(false)
  const [successData, setSuccessData] = useState<IApiLastMinuteResponse>()

  const { establishmentId, lastMinuteId } =
    useParams<{ [key: string]: string }>()
  const dispatch = useAppDispatch()
  const history = useHistory()
  const { t } = useTranslation()
  const pagePath = history.location.pathname

  const { lastMinutes } = useAppSelector((state: IAppState) => ({
    lastMinutes: state.lastMinute.list,
  }))

  const responseType = useMemo(
    () => ({
      type: 'lastMinute' as const,
      response: successData,
    }),
    [successData]
  )

  useEffect(() => {
    if (typeof lastMinutes === 'undefined') {
      history.push(`/${establishmentId}`)
    }
  }, [lastMinutes, history, establishmentId])

  const handleSubmit = useCallback(
    async (customerData: CustomerFormData) => {
      const { firstName, lastName, email, phone, comment, street, zip, city } =
        customerData
      try {
        setLoading(true)

        const response = await Client.bookLastMinute(
          establishmentId,
          lastMinuteId,
          firstName,
          lastName,
          Number(phone),
          email,
          comment,
          street,
          city
        )

        // TODO do we have info about isEmailSend for lastMinutes?
        const storageObject: IUserData = {
          firstName,
          lastName,
          phone: phone.toString(),
          email,
          isEmailSend: false,
          street,
          city,
          zip,
        }
        setUserData(storageObject)
        setUserToken(establishmentId, response.data.token, email)
        if (response.data.online_payment_url) {
          window.open(response.data.online_payment_url, '_self')
        }
        dispatch(setAppointmentStatus('booking_success'))
        setSuccessData(response.data)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        bookingFormErrorHandling(
          establishmentId,
          email,
          error,
          dispatch,
          history,
          t
        )
      }
    },
    [dispatch, t, history, establishmentId, lastMinuteId]
  )

  if (successData) {
    TagManager.dataLayer({
      dataLayer: {
        event: 'booking_success',
        pagePath: pagePath,
        pageTitle: t('Booking success'),
      },
    })

    ReactPixel.pageView()
    return (
      <StyledScrollView>
        <BookingSuccess
          responseType={responseType}
          downloadId={successData.appointment.id}
        />
      </StyledScrollView>
    )
  }

  if (isLoading)
    return (
      <StyledScrollView>
        <AbsoluteSpinner />
      </StyledScrollView>
    )

  return (
    <StyledScrollView>
      <CustomerForm submitHandler={handleSubmit} />
    </StyledScrollView>
  )
}
