import { TAuthTarget } from "../../Authenticated/components/AuthenticationField/TAuthState"

export const redirectTarget = (currentLocation: string): TAuthTarget => {
  if (currentLocation.includes('account-info')) {
    return 'account-info'
  }
  if (currentLocation.includes('account')) {
    return 'account'
  }
  if (currentLocation.includes('previous-appointments')) {
    return 'planned-appointments'
  }
  return `home`
}