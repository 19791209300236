import styled from 'styled-components/native'

export const TileContainer = styled.View`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-left: auto;
    margin-right: auto;
    width: 340px;
`
