import React, { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import {
  BackButton,
  StatusMessageContainer,
  StatusText,
} from '../../../App/Components'
import { StyledScrollView } from '../../../App/Components/AppContainer'
import { useParams } from '../../../Router'

export const NoAppointment: FunctionComponent = () => {
  const { establishmentId } = useParams()
  const { t } = useTranslation()

  return (
        <StyledScrollView>
            <BackButton extraMargin customPath={`/${establishmentId}`} />
            <StatusMessageContainer success={false}>
                <StatusText>{t('No appointments found')}</StatusText>
            </StatusMessageContainer>
        </StyledScrollView>
  )
}
