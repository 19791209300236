import React from 'react'
import { faCircleUser } from '@fortawesome/pro-regular-svg-icons'
import { useHistory, useParams } from '../../Router'
import { IconWrapper } from './style'
import { FaIcon } from '../../App/Components'
import { ISettingValues } from '../../Settings/ISettings'

interface IProps {
  settingValues: ISettingValues
}

export const AccountIcon = ({ settingValues }: IProps) => {
  const history = useHistory()
  const { establishmentId } = useParams()

  if (typeof settingValues === 'undefined') {
    return null
  }

  return (
    <IconWrapper
      isMessage={settingValues.message}
      onPress={() => history.push(`/${establishmentId}/account`)}
    >
      <FaIcon
        size="lg"
        icon={faCircleUser}
        color={settingValues.highlightColor}
      />
    </IconWrapper>
  )
}
