import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ScrollView } from 'react-native'

import { faInfoCircle, faTimes } from '@fortawesome/pro-light-svg-icons'

import { useHistory } from '../../../../Router'
import { DefaultButtonWithText } from '../../ButtonWithText/style'
import { FaIcon } from '../../FaIcon/FaIcon'
import { ModalMessage, StyledModal } from '../../WelcomeMessage/style'

import { CloseIconWrapper, DescriptionIconWrapper, ModalTitle } from '../style'
import { useAppSelector } from '../../../../store/hooks'

type Props = {
  title: string
  description: string
  link: string
}

export const DescriptionModal = ({ title, description, link }: Props) => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
  const { t } = useTranslation()
  const history = useHistory()
  const { settings } = useAppSelector((state) => ({
    settings: state.settings.values,
  }))

  const openModal = useCallback(() => {
    setIsModalVisible(true)
  }, [])

  const closeModal = useCallback(() => {
    setIsModalVisible(false)
  }, [])

  const pushToLink = useCallback(() => {
    history.push(link)
  }, [link])

  if (!description) {
    return null
  }

  return (
    <>
      <StyledModal
        backgroundcolor={settings.backgroundColor}
        isVisible={isModalVisible}
        animationIn="slideInDown"
        animationOut="slideOutUp"
        onBackdropPress={closeModal}
        swipeDirection="down"
      >
        <ScrollView>
          <ModalTitle highlightcolor={settings.highlightColor}>
            {t(`activities;;${title}`)}
          </ModalTitle>
          <ModalMessage highlightcolor={settings.highlightColor}>
            {t(`activities;;${description}`)}
          </ModalMessage>
          <DefaultButtonWithText
            disableHoverOnTouchDevice
            onPress={pushToLink}
            fontcolor={settings.highlightColor}
            backgroundcolor={settings.backgroundColor}
            hoversensitive
          >
            {t('Continue')}
          </DefaultButtonWithText>
        </ScrollView>
        <CloseIconWrapper onPress={closeModal}>
          <FaIcon size="lg" icon={faTimes} color={settings.highlightColor} />
        </CloseIconWrapper>
      </StyledModal>
      <DescriptionIconWrapper data-url-info={link} onPress={openModal}>
        <FaIcon
          size="lg"
          icon={faInfoCircle}
          color={settings.highlightColor}
          cssStyle={{ right: 0, top: 0, margin: '10px' }}
        />
      </DescriptionIconWrapper>
    </>
  )
}
