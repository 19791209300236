import React, { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from 'react-native'
import { useDispatch } from 'react-redux'

import { useHistory, useParams } from '../../../../../Router'
import { setCurrentCustomerIndex } from '../../../../store/Actions'

import { IProps } from './IProps'
import { SelectPersonContainer, SelectPersonText } from './style'

export const SelectPersonPerActivity: FunctionComponent<IProps> = ({
  isSelectIndividualPerson,
  customerIndex,
  activity,
  highlightcolor,
}) => {
  const { t } = useTranslation()
  const { establishmentId } = useParams()
  const history = useHistory()
  const dispatch = useDispatch()

  if (!isSelectIndividualPerson) {
    return null
  }

  const onSelectPerson = () => {
    dispatch(setCurrentCustomerIndex(customerIndex))
    history.push(
      `/${establishmentId}/select-person/${customerIndex}/${activity.id}`
    )
  }

  return (
    <SelectPersonContainer highlightcolor={highlightcolor}>
      <Text style={{ color: highlightcolor }} onPress={onSelectPerson}>
        {activity.personSelected ? (
          activity.personSelected.firstName
        ) : (
          <SelectPersonText highlightcolor={highlightcolor}>
            {t('Select preferred employee')}
          </SelectPersonText>
        )}
      </Text>
    </SelectPersonContainer>
  )
}
