import { TSnackBarMessage } from './interface/ISnackBarState'

export const CHANGE_SNACKBAR_STATE = 'CHANGE_SNACKBAR_STATE'

export function changeSnackBarState(
  snackBarState: TSnackBarMessage | 'unmount',
  message: string,
  title: string,
  timeTillUnmount: number
) {
  return {
    type: CHANGE_SNACKBAR_STATE,
    payload: {
      snackBarState,
      message,
      title,
      timeTillUnmount,
    },
  }
}
