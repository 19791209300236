import React from 'react'

import {
  ListCardPrice,
  ListCardText,
  ListCardTextContainer,
  ListCardTitle,
  ListCardContainer,
  ContentLine,
} from './style'
import { ToucheWrapper } from './ToucheWrapper'
import { ImageSwitch } from './ImageSwitch'
import { shadeColor } from '../../Util/shadeColor'
import { useAppSelector } from '../../../store/hooks'

export type LinkNavigation = 'workshop' | 'menu'
export type AddToCart = 'activity'
type ListCardType = LinkNavigation | AddToCart

type Props = {
  title: string
  link?: string
  image?: string
  price?: number
  description?: string
  onPress?: () => void
  isChild?: boolean
  type: ListCardType
  isActivityActive?: boolean
}

export const ListCard: React.FC<Props> = ({
  title,
  image,
  link,
  onPress,
  price,
  description,
  isChild = false,
  type,
  isActivityActive,
}) => {
  const settings = useAppSelector((state) => state.settings.values)
  let customImage: string | undefined
  if (image) {
    customImage = image
    if (typeof image === 'string' && image.includes('files.optios.')) {
      customImage += '&w=48&h=48&fit=crop'
    }

    if (typeof image === 'string' && image.startsWith('//')) {
      customImage = `https:${customImage}`
    }
  }

  const toucheProps =
    type === 'activity' ? { navType: type, onPress } : { navType: type, link }
  return (
    <ToucheWrapper navType={type} {...toucheProps}>
      <ListCardContainer highlight={settings.highlightColor} isChild={isChild}>
        <ImageSwitch
          title={title}
          image={customImage}
          isChild={isChild}
          isShowCheckBox={typeof isActivityActive !== 'undefined'}
          isCheckBoxActive={isActivityActive || false}
        />
        <ListCardTextContainer onPress={() => (onPress ? onPress() : null)}>
          <ListCardTitle highlight={settings.highlightColor}>
            {title}
          </ListCardTitle>
          <ListCardText fontcolor={settings.fontColor}>
            {description}
          </ListCardText>
          {price ? (
            <ListCardPrice highlight={settings.highlightColor}>
              {`€${price}`}
            </ListCardPrice>
          ) : null}
        </ListCardTextContainer>
      </ListCardContainer>
      <ContentLine lineColor={shadeColor(settings.backgroundColor, -3)} />
    </ToucheWrapper>
  )
}
