import { URL_API } from 'react-native-dotenv'

import axios, { AxiosResponse } from 'axios'
import { IAccountInfo } from '../IAccountInfo'
import { accountInfoTransformer } from './transformer'

export const getAccountInfo = (
  establishmentId: string,
  authToken: string
): Promise<IAccountInfo> => {
  const requestOptions: object = {
    method: 'GET',
    url: `${URL_API}/anonymous/customers/token/${authToken}?establishment_id=${establishmentId}`,
  }
  return axios(requestOptions).then(
    (response): IAccountInfo => accountInfoTransformer(response.data.data)
  )
}

export const deleteAccountInfo = (
  establishmentId: string,
  authToken: string
): Promise<'success' | 'error'> => {
  const requestOptions: object = {
    method: 'DELETE',
    url: `${URL_API}/anonymous/customers/token/${authToken}?establishment_id=${establishmentId}`,
  }
  return axios(requestOptions).then(
    (response: AxiosResponse<{ status: 'success' | unknown }>) =>
      response.data.status === 'success' ? 'success' : 'error'
  )
}
