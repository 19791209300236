export const nlNL = `**Privacyverklaring OPTIOS**
============================

_Versie 2022.01_

Deze Privacyverklaring is van toepassing wanneer u de website [www.optios.net](http://optios.squarespace.com) bezoekt of wanneer u zich registreert op het Optios-platform (hierna genoemd “Optios”). Hierin wordt uitgelegd waarom de BV OPTIOS (hierna genoemd “Optios BV”, “wij” of “ons”) uw persoonsgegevens verwerkt wanneer u gebruik maakt van onze diensten.

Inhoud

[1 Wie is de verwerkingsverantwoordelijke?](#_Toc101864129)

[2 Bezoek aan de website](#_Toc101864130)

[3 Sollicitanten](#_Toc101864131)

[4 Optios](#_Toc101864132)

[4.1 Aanmaken account](#_Toc101864133)

[4.2 Gebruik van Optios](#_Toc101864134)

5 Soorten persoonlijke & gevoelige gebruikersgegevens die de app opent, verzamelt & gebruikt

[6 Met wie delen wij uw persoonsgegevens?](#_Toc101864135)

[7 Hoe beschermen wij uw persoonsgegevens?](#_Toc101864136)

[8 Wat zijn uw rechten en hoe kan u deze uitoefenen?](#_Toc101864137)

[9 Hyperlinks](#_Toc101864138)

1 Wie is de verwerkingsverantwoordelijke?
-------------------------------------------

De verantwoordelijke voor de verwerking van uw persoonsgegevens is:

                  **BV OPTIOS**

                  Antwerpse Steenweg 19

                  9080 Lochristi

                  0629.718.060

Indien u vragen heeft omtrent de verwerking van uw persoonsgegevens door Optios BV of over de inhoud van deze Privacyverklaring, kan u steeds contact met ons opnemen via help@optios.net.

Optios BV behoudt zich het recht voor om deze Privacyverklaring van tijd tot tijd te wijzigen of te herzien. Indien u geregistreerd bent via Optios zullen wij u hiervan op de hoogte brengen alvorens deze wijzigingen of herzieningen van kracht worden.

2 Bezoek aan de website
-------------------------

Wanneer u onze website bezoekt, dan kunnen er in bepaalde omstandigheden persoonsgegevens van u verzameld worden aan de hand van cookies (zie hieromtrent onze cookieverklaring).

Via onze website beschikt u verder over diverse manieren om persoonsgegevens met ons te delen. Zo kan u een gratis demo aanvragen of ons contacteren aan de hand van een contactformulier.

In deze gevallen vragen wij de informatie die nodig is om u de gewenste informatie te bezorgen zoals onder andere uw naam en e-mailadres. Deze verwerkingen zijn steeds gebaseerd op ons gerechtvaardigd belang, namelijk het reageren op uw diverse vragen.  

Onze website biedt u ook de mogelijkheid om ons te contacteren via de chatbot. In dat geval worden de gegevens die u daar achterlaat verwerkt. Deze verwerking kadert in ons gerechtvaardigd belang, met name reageren op de door u gestelde vragen.

3 Sollicitanten
-----------------

Wanneer u solliciteert bij Optios, verzamelen wij volgende gegevens: uw naam, e-mailadres, telefoonnummer, eventueel een foto, CV, motivatiebrief en alle andere informatie die u in uw correspondentie met ons bezorgt.

Optios BV gebruikt bovenstaande informatie louter voor interne wervingsdoeleinden. Indien uw kandidatuur niet wordt weerhouden houden wij uw persoonsgegevens maximaal 30 dagen bij. Indien wij uw kandidatuur graag in onze wervingsreserve houden, vragen wij hiervoor steeds uw toestemming. Uw gegevens worden in ons wervingsreserve maximaal 2 jaar bewaard. 

4 Optios
----------

### 4.1 Aanmaken account

Bij de aanmaak van uw account in Optios wordt uw naam, bedrijfsnaam en e-mailadres gevraagd.

De verwerking van deze persoonsgegevens is noodzakelijk om de overeenkomst tussen u en ons omtrent het gebruik van Optios uit te voeren. Indien u deze persoonsgegevens niet aan Optios BV wenst over te maken, dan kan u ook geen gebruik maken van Optios gezien deze verwerking nodig is om uw toegang tot en het gebruik van Optios te faciliteren.

### 4.2 Gebruik van Optios

Wanneer u gebruik maakt van Optios verzamelen wij bijkomend volgende persoonsgegevens:

Een gsm-nummer, eventueel bijkomend telefoonnummer, geslacht en de taal waarin we met u communiceren. In het geval van een éénmanszaak verwerken wij tevens uw adres, rekeningnummer en btw-nummer.

Deze persoonsgegevens verwerken wij om de volgende redenen:

§  Het voortdurend verbeteren van onze diensten en op die manier nieuw functionaliteiten te ontwikkelen. De verwerking is gebaseerd op ons gerechtvaardigd belang, met name het beter afstemmen van onze diensten op de noden van onze klanten;

§  Optios veilig te houden voor u en al onze  gebruikers. Deze verwerking is gebaseerd op ons gerechtvaardigd belang, met name het voorzien van een veilig platform voor alle betrokkenen;

§  Bij te houden hoe Optios wordt gebruikt en of er geen misbruik wordt gemaakt van de Gebruiksvoorwaarden Optios. Deze verwerking is verenigbaar met het feit dat de overeenkomst tussen u en Optios correct wordt uitgevoerd;

§  Het correct factureren van de geleverde diensten.

5 Soorten persoonlijke & gevoelige gebruikersgegevens die de app opent, verzamelt & gebruikt
----------------------------------------------------------------------------------------------

Wanneer de Optios online boeking (app) wordt gebruikt, wordt de gebruiker gevraagd om persoonlijke gegevens in te voeren om zijn afspraak te bevestigen. Namelijk:

 Voornaam

 Achternaam

 Telefoon

 E-mailadres

Deze gegevens worden samen met de afspraakgegevens (datum, tijd en diensten) opgeslagen in de database van Optios. Deze gegevens kunnen door de salonhouder worden gebruikt voor marketing- en communicatiedoeleinden.

De gegevens die worden verzameld met de Optios app zullen niet worden verspreid aan andere partijen dan Optios en de salon zelf.

6 Met wie delen wij uw persoonsgegevens?
------------------------------------------

Uw persoonsgegevens worden enkel en alleen verwerkt met het oog op bovenstaande doeleinden.

Optios BV kan in het kader van de hogervermelde doeleinden eveneens beroep doen op derde partijen, zoals bijvoorbeeld voor software integratie (bv. softwareleveranciers) en hostingdoeleinden (bv. cloudproviders, externe data centers…), maar ook een extern rekruteringskantoor.

In geen geval worden uw persoonsgegevens doorgegeven aan derden met het oog op commerciële doeleinden.

Uw gegevens worden bovendien niet doorgegeven aan landen buiten de Europese Economische Ruimte. Indien dit in de toekomst zou veranderen, zullen wij ervoor zorgen dat wij de nodige passende waarborgen en aanvullende maatregelen genomen hebben, zoals vereist door de wetgeving inzake gegevensbescherming.

7 Hoe beschermen wij uw persoonsgegevens?
-------------------------------------------

Optios BV neemt alle redelijke technische en organisatorische maatregelen om de bescherming van uw persoonsgegevens te waarborgen zodat deze beschermd worden tegen misbruik, verlies of onbevoegde toegang. Zo wordt alleen toegang verleend tot uw persoonsgegevens op een “need-to-know” basis.  Deze maatregelen zijn steeds op basis van de huidige stand van de techniek. De beveiliging en veiligheid van uw persoonsgegevens is echter steeds een inspanningsverbintenis waarvoor wij geen absolute garanties kunnen bieden.

8 Wat zijn uw rechten en hoe kan u deze uitoefenen?
-----------------------------------------------------

Als u een klacht, een vraag of een probleem heeft met de manier waarop wij uw persoonsgegevens verwerken dan kan u ons contacteren via de contactgegevens zoals omschreven in punt 1.

Als u ons contacteert om uw rechten uit te oefenen dan antwoorden wij binnen 1 maand. Uitzonderlijk kan dit langer duren (tot maximum 3 maanden), maar dan verwittigen wij u hiervan binnen 1 maand. Het al dan niet kunnen uitoefenen van uw rechten is steeds gekoppeld aan de verwerking zelf en de rechtsgrond.

Indien u uw rechten wenst uit te oefenen, dan willen we u vragen om steeds duidelijk te omschrijven welk recht u wenst uit te oefenen en op welke manier (vb. per e-mail, per post…) u de gevraagde informatie wenst te verkrijgen. Bovendien rust er een identificatieplicht op ons. Zo dienen wij steeds te verifiëren of u effectief gerechtigd bent om de gevraagde informatie te bekomen, om die reden kunnen wij om een kopie van uw identiteitskaart vragen. 

**Recht op inzage en correctie**

Indien u wil weten welke persoonsgegevens Optios BV van u verwerkt en voor welke doeleinden, dan kun u contact met ons opnemen. Wij kunnen u een gratis kopie geven van de persoonsgegevens die wij van u verwerken. Indien u meent dat uw persoonsgegevens niet juist of actueel zijn, dan kan u ons verzoeken om deze te corrigeren.

**Recht op verwijdering en beperking**

U heeft het recht om ons te verzoeken uw persoonsgegevens te verwijderen. Indien we evenwel wettelijk verplicht zijn om bepaalde persoonsgegeven toch langer bij te houden, dan kunnen we niet aan dit verzoek voldoen. In dat geval zullen wij u hiervan verwittigen.

Wanneer u hiertoe gegronde redenen heeft, kan u ons verzoeken om de verwerking van uw persoonsgegevens te beperken. Bijvoorbeeld omdat u de juistheid van uw gegevens betwist.

**Recht van bewaar**

Wanneer de verwerking van uw persoonsgegevens gebaseerd is op ons gerechtvaardigd belang of kadert in vervulling van een taak van algemeen belang of van openbaar gezag kan u op gemotiveerde wijze bezwaar maken tegen de verwerking van uw persoonsgegevens.

**Recht op gegevensoverdraagbaarheid**

Indien wij uw persoonsgegevens verwerken op basis van uw (uitdrukkelijke) toestemming of in het kader van de uitvoering van de overeenkomst tussen u en ons, dan kan u ons verzoeken dat wij de persoonsgegevens die u aan ons verstrekt heeft op een gestructureerde, gangbare en machine leesbare vorm aan u bezorgen of deze overdagen aan een andere verwerkingsverantwoordelijke, op voorwaarde dat de verwerking via geautomatiseerde procedés wordt verricht.

**Recht om uw toestemming in te trekken**

Wanneer de verwerking van uw persoonsgegevens door Optios BV gebaseerd is op uw (uitdrukkelijke) toestemming, heeft u te allen tijde het recht om deze toestemming opnieuw in te trekken. De intrekking van uw toestemming doet evenwel geen afbreuk aan de rechtmatigheid van de verwerking vóór de intrekking van uw toestemming.

**Recht om klacht in te dienen**

Indien u niet tevreden bent over de wijze waarop Optios BV uw persoonsgegevens verwerkt of indien u meent onvoldoende gehoord te worden door Optios BV m.b.t. tot uw privacyklachten, dan beschikt u steeds over het recht om klacht in te dienen bij de Belgische toezichthoudende autoriteit via:

                  **Gegevensbeschermingsautoriteit**

                  Drukpersstraat 35                

                  1000 Brussel

                  Tel. 02/274.48.00

                  Fax. 02/274.48.35

            [contact@apd-gba.be](mailto:contact@apd-gba.be)

9 Hyperlinks
--------------

Deze website kan hyperlinks bevatten naar websites van andere partijen. Wanneer u op deze hyperlinks klikt, verlaat u de website van Optios BV. Optios BV is in geen geval verantwoordelijk voor het gehanteerde privacybeleid van deze websites. Het is steeds aangewezen om de privacyverklaring van deze externe websites zorgvuldig na te lezen.`