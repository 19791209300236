import { Dimensions } from 'react-native'

import styled from 'styled-components/native'

import { ProximaNova, ProximaNovaBold } from '../App/Components'
import {
    changeModalMargin,
    modalWindowDimensions,
} from '../App/Components/WelcomeMessage/style'

import { TSnackBarMessage } from './store/interface/ISnackBarState'

export const snackTypeColorSwitch = (type: TSnackBarMessage) => {
    switch (type) {
        case 'error':
            return '#d24444'
        case 'info':
            return '#2b89e9'
        case 'warning':
            return '#ffbc22'
        case 'success':
            return '#4cd16d'
        default:
            return '#2b89e9'
    }
}

export const SnackBarCentered = styled.View`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 2;
`

export const SnackFixedContainer = styled.View`
    width: ${modalWindowDimensions.width > 640
        ? 440
        : modalWindowDimensions.width - 20};
    margin: ${changeModalMargin ? '0 0 0 50%' : '-340px 0 0 50%'};
    top: ${changeModalMargin ? 0 : '50%'};
    left: ${modalWindowDimensions.width > 640
        ? -440 / 2
        : -(modalWindowDimensions.width - 20) / 2};
    position: fixed;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
`

export const SnackBarContainer = styled.View<{ snackType: TSnackBarMessage }>`
    width: 100%;
    max-width: 440px;
    border: solid 1px #e0e7ff;
    background-color: #ffffff;
    padding: 1rem 2rem;
    border-left-width: 4px;
    border-left-color: ${({ snackType }) => snackTypeColorSwitch(snackType)};
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
`

export const SnackFlexView = styled.View`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-width: 100%;
`

export const SnackFlexText = styled(SnackFlexView)`
    flex-direction: column;
    align-items: flex-start;
    max-width: 335px;
`

export const SnackBarInfoContainer = styled(SnackFlexView)``

export const SnackTitle = styled(ProximaNovaBold)`
    margin-left: 4px;
    align-self: flex-start;
    font-size: 15px;
    font-weight: 500;
    color: #2e384d;
`

export const SnackMessage = styled(ProximaNova)`
    align-self: flex-start;
    font-size: 15px;
    color: #2e384dce;
    margin-bottom: 0;
    text-align: left;
`
