import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from 'react-native'
import { useParams } from 'react-router'
import { StyledScrollView } from '../../App/Components/AppContainer/StyledScrollView/StyledScrollView'
import { LocationsWrapper } from './style'
import { Spinner, Tile } from '../../App/Components'
import { TileContainer } from '../../App/Components/AppContainer'
import { useHistory } from '../../Router'
import { getLocationData } from './util/getLocationData'
import { ILocation } from '../../Settings/ILocations'

export const MultiLocations = () => {
  const [locationDetails, setLocationDetails] = useState<ILocation[]>([])
  const { t } = useTranslation()
  const { locationIds } = useParams()
  const history = useHistory()

  const locationsData: string[] = locationIds.split(',').map((id) => id.trim())

  useEffect(() => {
    getLocationData(locationsData).then((details) =>
      setLocationDetails(details)
    )
  }, [])

  const handleTilePress = (locationId: string) => history.push(`/${locationId}`)

  return (
    <StyledScrollView>
      <LocationsWrapper>
        <Text>{t(`Multi locations`)}</Text>
        {locationDetails.length > 0 ? (
          <TileContainer>
            {locationDetails.map((location) => (
              <Tile
                key={location.id}
                title={location.name}
                image={location.image}
                onPress={() => handleTilePress(location.id.toString())}
              />
            ))}
          </TileContainer>
        ) : (
          <Spinner />
        )}
      </LocationsWrapper>
    </StyledScrollView>
  )
}
