export interface IContextMenuState {
  isActive: boolean
  position: { x: number; y: number }
  menuItems: { tileUrl?: string }
}
export const initialContextMenuState: IContextMenuState = {
  isActive: false,
  position: { x: 0, y: 0 },
  menuItems: { tileUrl: '' },
}
