import styled from 'styled-components/native'

export const BookingSuccess = styled.View`
  width: 100%;
  background-color: #69d321;
  padding: 30px 0 30px 65px;
  margin: 20px 0;
`

export const BookingSuccessText = styled.Text`
  color: white;
  padding: 3px 12px;
`

export const TextWrapper = styled.View`
  display: flex;
  width: fit-content;
  flex-direction: initial;
  padding: 3px 10px;
  align-items: center;
`
