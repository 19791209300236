import styled from 'styled-components/native'
import { Modal, TouchableOpacity, Text } from 'react-native'

export const ContextModal = styled(Modal)<{
  highlight: string
  display: boolean
  position: { x: number; y: number }
}>`
  position: absolute;
  display: ${({ display }) => (display ? 'flex' : 'none')};
  flex-direction: column;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgb(64 64 64 / 5%);
  padding: 10px 0;
  border: ${({ highlight }) => highlight || 'black'};
  z-index: 999;
  top: ${({ position }) => position.y || 0};
  left: ${({ position }) => position.x || 0}; ;
`

export const MenuOptionText = styled(Text)<{
  highlight: string
}>`
  margin-left: 10px;
  color: ${({ highlight }) => highlight || 'black'};
`

export const MenuOption = styled(TouchableOpacity)`
  border: 0;
  padding: 10px 30px 10px 15px;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  flex-direction: row;
  color: #000;
  background-color: #fff;
  font-weight: 500;
`
