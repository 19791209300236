import { Action } from 'redux'

import { ISurvey } from '../Client'

export const SURVEY_LOADING = 'SURVEY_LOADING'
export const SURVEY_SUCCESS = 'SURVEY_SUCCESS'

export interface SurveyLoading extends Action<typeof SURVEY_LOADING> {}

export interface SurveySuccess extends Action<typeof SURVEY_SUCCESS> {
    payload: {
        survey: ISurvey
    }
}
