import React, { useState } from 'react'

import { faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons'

import { stringToHsl } from '../../../../../App/Util'

import {
  CategoryActivitiesContainer,
  CategoryColor,
  CategoryHeading,
  CategoryIcon,
  CategoryImage,
  CategorySectionContainer,
  CategoryTitle,
} from './style'
import { ContentLine } from '../../../../../App/Components/ListCard/style'
import { shadeColor } from '../../../../../App/Util/shadeColor'

type Props = {
  title: string
  list: JSX.Element[]
  fontcolor: string
  image?: string
  lastMinuteMenuLine?: boolean
  bgColor: string
}

export const CategorySection: React.FC<Props> = ({
  title,
  list,
  fontcolor,
  image,
  lastMinuteMenuLine,
  bgColor,
}) => {
  const [openActivityList, setOpenActivityList] = useState<boolean>(false)

  const toggleActivityList = () => setOpenActivityList(!openActivityList)

  return (
    <>
      <CategorySectionContainer>
        <CategoryHeading bgColor={bgColor} onClick={toggleActivityList}>
          {image ? (
            <CategoryImage source={`${image}&w=48&h=48&fit=crop`} />
          ) : (
            <CategoryColor color={stringToHsl(title, 50, 50)} />
          )}
          <CategoryTitle fontcolor={fontcolor}>{title}</CategoryTitle>
          <CategoryIcon icon={openActivityList ? faChevronDown : faChevronUp} />
        </CategoryHeading>
        <CategoryActivitiesContainer open={openActivityList}>
          {list}
        </CategoryActivitiesContainer>
      </CategorySectionContainer>
      {!!lastMinuteMenuLine && (
        <ContentLine lineColor={shadeColor(bgColor, -3)} />
      )}
    </>
  )
}
