import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  deleteCustomerIndex,
  deleteLastAdded,
} from '../../../Appointment/store/Actions'

import { Link, useHistory, useParams } from '../../../Router'

import { BackButtonText, TextWrapper } from './style'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { setAuthState } from '../../../Settings/Store/Action'

type Props = {
  customPath?: string
  goBack?: number
  isAppointment?: boolean
  rootPathCheck?: boolean
  extraMargin?: boolean
  resetAuthState?: boolean
}

export const BackButton = ({
  customPath,
  goBack,
  isAppointment,
  rootPathCheck = false,
  extraMargin = false,
  resetAuthState = false,
}: Props) => {
  const { establishmentId } = useParams<{ [key: string]: string }>()
  const history = useHistory()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const linkStyle = {
    textDecoration: 'none',
    width: 'fit-content',
    alignSelf: 'center',
  }

  const { settings, appointment } = useAppSelector((state) => ({
    appointment: state.appointment,
    settings: state.settings.values,
  }))

  const { currentCustomerIndex } = appointment
  const hasActivities: boolean =
    appointment.customers.reduce(
      (previousValue, currentValue) =>
        previousValue + currentValue.activities.length,
      0
    ) > 0

  if (!hasActivities && rootPathCheck && !customPath) {
    return null
  }

  if (hasActivities && rootPathCheck) {
    if (
      appointment.customers[currentCustomerIndex] ||
      appointment.customers[currentCustomerIndex].activities.length !== 0
    ) {
      return (
        <BackButtonText
          extraMargin={extraMargin}
          color={settings.highlightColor}
          onPress={() => {
            dispatch(deleteCustomerIndex(currentCustomerIndex))
            history.push(`/${establishmentId}/appointment`)
          }}
        >
          {t('Back')}
        </BackButtonText>
      )
    }

    return (
      <Link to="appointment" style={linkStyle}>
        <TextWrapper>
          <BackButtonText
            extraMargin={extraMargin}
            color={settings.highlightColor}
          >
            {t('Back')}
          </BackButtonText>
        </TextWrapper>
      </Link>
    )
  }

  if (typeof customPath !== 'undefined') {
    return (
      <Link
        to={customPath}
        onClick={() => {
          if (resetAuthState) {
            dispatch(setAuthState('anonymous'))
          }
        }}
        style={linkStyle}
      >
        <TextWrapper>
          <BackButtonText
            extraMargin={extraMargin}
            color={settings.highlightColor}
          >
            {t('Back')}
          </BackButtonText>
        </TextWrapper>
      </Link>
    )
  }

  const hasDescription = (): boolean => {
    const { customers } = appointment
    const currentCustomer = customers[appointment.currentCustomerIndex]
    const { activities } = currentCustomer

    if (activities.length > 0) {
      const lastActivity = activities[activities.length - 1]
      return !!lastActivity.description
    }
    return false
  }

  const numberOfSteps: number = goBack || -1

  const goBackLogic = () => {
    if (isAppointment) {
      dispatch(deleteLastAdded())
      history.go(numberOfSteps)
    } else if (hasDescription()) {
      history.go(-2)
    } else {
      history.goBack()
    }
  }

  return (
    <BackButtonText
      extraMargin={extraMargin}
      color={settings.highlightColor}
      onPress={goBackLogic}
    >
      {t('Back')}
    </BackButtonText>
  )
}
