import styled from 'styled-components/native'

export const SelectPersonContainer = styled.View<{
    highlightcolor: string
}>`
    border-color: ${({ highlightcolor }) =>
    highlightcolor ? highlightcolor + 15 : '#fff'};
    border-width: 1px;
    padding: 3px 6px;
    border-radius: 15px;
    margin-top: 8px;
`

export const SelectPersonText = styled.Text<{
    highlightcolor: string
}>`
    color: ${({ highlightcolor }) =>
    highlightcolor || '#fff'};
`
