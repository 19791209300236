import {
  IActivityCategory,
  IApiActivity,
  IApiActivityCategory,
  IExtraActivity,
} from './IExtraActivity'

export default class Transformer {
  public static transform(apiActivity: IApiActivity): IExtraActivity {
    return {
      uuid: apiActivity.uuid,
      deposit: apiActivity.deposit ? apiActivity.deposit : undefined,
      description: apiActivity.description
        ? apiActivity.description
        : undefined,
      duration: apiActivity.duration,
      establishmentDetails: apiActivity.establishment_details,
      id: apiActivity.id,
      image: apiActivity.image ? apiActivity.image : undefined,
      isOnline: apiActivity.is_online,
      isPersonSelectable: apiActivity.person_choice,
      name: apiActivity.name,
      order: apiActivity.order,
      personsAmount: apiActivity.persons_amount
        ? apiActivity.persons_amount
        : undefined,
      price: apiActivity.price ? apiActivity.price : undefined,
      type: 'activity',
      waitTime: apiActivity.wait_time || 0,
      existingCustomersOnly: apiActivity.only_for_existing_customers,
    }
  }

  public static transformCategory(
    apiActivityCategory: IApiActivityCategory
  ): IActivityCategory {
    return {
      ...apiActivityCategory,
      activities: apiActivityCategory.activities.map(
        Transformer.transform
      ),
    }
  }
}
