import Client from '../Client/Client'

import { IApiLastMinute, ILastMinute } from './ILastMinute'
import LastMinuteTransformer from './Transformer'

export const LASTMINUTES_LOADING = 'LASTMINUTES_LOADING'
export const GET_LASTMINUTES_SUCCESS = 'GET_LASTMINUTES_SUCCESS'

export function lastMinutesLoading() {
  return {
    type: LASTMINUTES_LOADING,
  }
}

function getLastMinutesSuccess(lastMinute: ILastMinute[]) {
  return {
    type: GET_LASTMINUTES_SUCCESS,
    payload: lastMinute,
  }
}

export function getLastMinutes(establishmentId: string) {
  return (dispatch: any) => {
    dispatch(lastMinutesLoading())

    Client.getLastMinutes(establishmentId).then((response) => {
      const transformedLastMinutes = response.data.data.map(
        (apiLastMinute: IApiLastMinute) =>
          LastMinuteTransformer.transform(apiLastMinute)
      )
      dispatch(getLastMinutesSuccess(transformedLastMinutes))
    })
  }
}
