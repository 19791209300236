export const SET_CONTEXT_MENU_URL = 'SET_CONTEXT_MENU_URL'
export const TOGGLE_CONTEXT_MENU = 'TOGGLE_CONTEXT_MENU'

export function addTileUrl(
  isActive: boolean,
  position: { x: number; y: number },
  tileUrl: string
) {
  return {
    type: SET_CONTEXT_MENU_URL,
    payload: {
      isActive,
      position,
      tileUrl,
    },
  }
}

export function toggleContextMenu(isActive: boolean) {
  return {
    type: TOGGLE_CONTEXT_MENU,
    payload: {
      isActive,
    },
  }
}
