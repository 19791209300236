import React, { PropsWithChildren } from 'react'
import { TouchableOpacity } from 'react-native'
import { Link } from 'react-router-dom'
import { AddToCart, LinkNavigation } from './ListCard'

type List =
  | { navType: AddToCart; onPress: () => void }
  | { navType: LinkNavigation; link?: string }

export const ToucheWrapper = (props: PropsWithChildren<List>) => {
  if (props.navType === 'activity') {
    return (
      <TouchableOpacity onPress={() => props.onPress()}>
        {props.children}
      </TouchableOpacity>
    )
  }

  return (
    <Link to={props.link} style={{ textDecoration: 'none' }}>
      {props.children}
    </Link>
  )
}
