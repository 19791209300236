import { initialUserData, ISetUserData, IUserData } from './IUserData'

export const setUserData = (newData: ISetUserData): void => {
  try {
    const userData: IUserData | null = JSON.parse(
      localStorage.getItem('userData')
    )
    if (userData === null) {
      localStorage.setItem(
        'userData',
        JSON.stringify({ ...initialUserData, ...newData })
      )
      return
    }
    localStorage.setItem(
      'userData',
      JSON.stringify({ ...initialUserData, ...userData, ...newData })
    )
    return
  } catch (e) {
    localStorage.setItem('userData', JSON.stringify(initialUserData))
  }
}
