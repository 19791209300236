import React, { forwardRef } from 'react'
import { TextInput } from 'react-native'
import { FieldInput } from '../../style'
import { SharedInputProps } from '../SharedInputProps'

export const CityInput = forwardRef<TextInput, SharedInputProps>(
  (
    {
      onChange,
      onBlur,
      value,
      fontColor,
      onSubmitEditing,
      autoComplete,
      onSubmit,
    },
    ref: React.RefObject<TextInput>
  ) => (
    <FieldInput
      fontcolor={fontColor}
      onBlur={onBlur}
      onChangeText={onChange}
      value={value}
      ref={ref}
      onSubmitEditing={onSubmitEditing}
      // autoComplete={autoComplete ? 'postal-code' : null}
      onKeyPress={(e) => {
        if (e.key === 'Enter') {
          onSubmit({ email: value })
        }
      }}
    />
  )
)
