import React, { FunctionComponent, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { BackButton, Logo, Spinner } from '../../App/Components'
import { StyledScrollView } from '../../App/Components/AppContainer'
import { CustomerForm } from '../../CustomerForm/CustomerForm'
import { CustomerFormData } from '../../CustomerForm/ICustomerFormData'
import { useParams } from '../../Router'
import { IAppState } from '../../store/IAppState'
import { useAppSelector } from '../../store/hooks'
import { TextNoWorkshops, ViewNoWorkshops } from '../WorkshopOverview/style'
import Client from '../Client/Client'
import { SuccessMessage } from '../../App/Components/BookingSuccess/Components/SuccessMessage/SuccessMessage'
import { ErrorMessage } from '../../App/Components/BookingSuccess/Components/ErrorMessage/ErrorMessage'

export const BookWorkshop: FunctionComponent = () => {
  const params = useParams<{ workshopUuid: string; establishmentId: string }>()
  const { t } = useTranslation()
  const [status, setStatus] = useState<
    undefined | 'saving' | 'success' | 'error' | 'already_subscribed'
  >()

  const { workshop, isLoading } = useAppSelector((state: IAppState) => ({
    isLoading: typeof state.workshops === 'undefined',
    workshop: state.workshops.list
      ? state.workshops.list.find(
          (oneWorkshop) => oneWorkshop.uuid === params.workshopUuid
        )
      : undefined,
  }))

  const handleSubmit = async (customerData: CustomerFormData) => {
    setStatus('saving')
    try {
      await Client.subscribeToWorkshop(
        params.establishmentId,
        workshop.uuid,
        customerData.firstName,
        customerData.lastName,
        customerData.phone,
        customerData.email,
        customerData.comment,
        customerData.sendEmail
      )
      setStatus('success')
    } catch (error) {
      if (error.response.status >= 200 && error.response.status < 300) {
        setStatus('success')
      } else if (
        error.response.data.message === 'Customer already subscribed'
      ) {
        setStatus('already_subscribed')
      } else {
        setStatus('error')
      }
    }
  }

  if (isLoading || status === 'saving') {
    return <Spinner />
  }

  if (!workshop) {
    <StyledScrollView>
      <Logo />
      <BackButton extraMargin />
      <ViewNoWorkshops>
        <TextNoWorkshops>{t('Workshop not found')}</TextNoWorkshops>
      </ViewNoWorkshops>
    </StyledScrollView>
  }

  if (status === 'success' || status === 'already_subscribed') {
    return (
      <StyledScrollView>
        <Logo />
        <BackButton extraMargin />
        <SuccessMessage message={t('Workshop has been booked.')} />
      </StyledScrollView>
    )
  }

  if (status === 'error') {
    return (
      <StyledScrollView>
        <Logo />
        <BackButton />
        <ErrorMessage
          message={t('An error ocurred while subscribing, please try again.')}
        />
      </StyledScrollView>
    )
  }

  return (
    <StyledScrollView>
      <CustomerForm
        submitHandler={handleSubmit}
        confirmButtonText={t('Confirm workshop subscription')}
      />
    </StyledScrollView>
  )
}
