import React, { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { faPlusCircle } from '@fortawesome/pro-light-svg-icons'

import { FaIcon, ProximaNova } from '../../../App/Components'
import { Link, useParams } from '../../../Router'
import { IAppState } from '../../../store/IAppState'
import { setCurrentCustomerIndex } from '../../store/Actions'
import { AddActivityContainer } from '../../style'

import { IProps } from './IProps'

export const AddActivityButton: FunctionComponent<IProps> = ({
  showButton,
  customerIndex,
}) => {
  const { establishmentId } = useParams<{ establishmentId: string }>()
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const { settings } = useSelector((state: IAppState) => ({
    selectedPerson: state.appointment.selectedPerson,
    settings: state.settings.values,
  }))

  const dispatchCurrentCustomer = () =>
    dispatch(setCurrentCustomerIndex(customerIndex))

  if (!showButton) {
    return null
  }
  return (
    <AddActivityContainer>
      <Link
        style={{ textDecoration: 'none', display: 'flex' }}
        to={`/${establishmentId}/activities`}
        onClick={dispatchCurrentCustomer}
      >
        <>
          <FaIcon
            size="lg"
            icon={faPlusCircle}
            color={settings.highlightColor}
            cssStyle={{
              marginRight: '10px',
              alignSelf: 'baseline',
            }}
          />
          <ProximaNova>{t('Add extra activity')}</ProximaNova>
        </>
      </Link>
    </AddActivityContainer>
  )
}
