import { TouchableOpacity } from 'react-native'

import styled from 'styled-components/native'

import StyleVariables from '../../../StyleVariables'

export const ListCardContainer = styled.View<{
    highlight: string
    isChild: boolean
}>`
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-grow: 1;
    padding: ${({ isChild }) => isChild ? '5px 35px 5px 15px' : '5px 0 5px 0'};
    margin: 15px 0;
    width: 100%;
`

export const ListImage = styled.Image`
    height: ${({ isChild }) => isChild ? '40px' : '48px'};
    width: ${({ isChild }) => isChild ? '40px' : '48px'};
    border-radius: 28px;
    background-color: ${({ highlight }) => (highlight || '#FFF')};
`

export const ListColor = styled.View<{
    color: string
}>`
    height: ${({ isChild }) => isChild ? '40px' : '48px'};
    width: ${({ isChild }) => isChild ? '40px' : '48px'};
    border-radius: 24px;
    background-color: ${({ color }) => color};
`

export const ListCardTextContainer = styled(TouchableOpacity)`
    flex-grow: 4;
    max-width: 200px;
    margin-left: 24px;
`
export const ListCardTitle = styled.Text<{
    highlight: string
}>`
    font-size: 16px;
    margin-bottom: 8px;
    color: ${({ highlight }) =>
    highlight || StyleVariables.colorGrey};
    font-weight: bold;
    width: fit-content;
`

export const ListCardText = styled.Text<{
    fontcolor: string
}>`
    font-size: 12px;
    color: ${({ fontcolor }) =>
    fontcolor || StyleVariables.colorGrey};
`


export const ListMetaContainer = styled(TouchableOpacity)`
    flex-direction: row;
    flex-grow: 1;
    justify-content: space-between;
    align-items: center;
`

export const ListCardPrice = styled.Text<{
    highlight: string
}>`
    font-size: 16px;
    margin-top: 8px;
    color: ${({ highlight }) =>
    highlight || StyleVariables.colorGrey};
    font-weight: bold;
`

export const ContentLine = styled.View<{
    lineColor: string
}>`
    border-bottom-width: 2px;
    border-bottom-color: ${({ lineColor }) => lineColor};
    margin: 4px 0;
`