import React, { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import { BackButton, Logo, Spinner } from '../App/Components'
import {
  ListContainer,
  OptionsContainer,
  StyledScrollView,
  TileContainer,
} from '../App/Components/AppContainer'
import { LastMinutesItem } from '../LastMinutes/LastMinutesItem/LastMinutesItem'
import { useLocation, useParams } from '../Router'

import { ListStructure, TileStructure } from './Components'
import { StyledTitle } from './style'
import { useAppSelector } from '../store/hooks'
import { WorkshopItem } from '../Workshop/WorkshopItem/WorkshopItem'

export const Menu: React.FC = () => {
  const location = useLocation()

  const { t } = useTranslation()
  const { establishmentId } = useParams<{ [key: string]: string }>()

  const settings = useAppSelector((state) => state.settings.values)
  const appointment = useAppSelector((state) => state.appointment)
  const hasActivities: boolean =
    appointment.customers.reduce(
      (previousValue, currentValue) =>
        previousValue + currentValue.activities.length,
      0
    ) > 0
  const rootPath = `/${establishmentId}/menu`
  const isRootPath: boolean =
    location.pathname === rootPath || location.pathname === `${rootPath}/`

  const { isListView } = settings
  const StructureContainer: FunctionComponent = isListView
    ? ListContainer
    : TileContainer

  let backUrl: string | undefined

  if (typeof settings === 'undefined') {
    return <Spinner />
  }

  const onlineBookableLocations = settings.locations.filter(
    (locationCheck) => locationCheck.onlineBookingEnabled
  )

  if (isRootPath) {
    if (
      onlineBookableLocations.length > 1 &&
      settings.isSelectLocation &&
      !hasActivities
    ) {
      backUrl = `/${establishmentId}/select-location`
    }
  } else {
    backUrl = `/${establishmentId}/menu`
    const breadcrumbs = location.pathname
      .split('/')
      .splice(3)
      .filter(Boolean)
      .map((breadcrumb): number => Number(breadcrumb))
    breadcrumbs.pop()
    if (breadcrumbs.length > 0) {
      backUrl += `/${breadcrumbs.join('/')}`
    }
  }

  return (
    <StyledScrollView>
      <Logo />
      <StyledTitle highlight={settings.fontColor}>
        {t('Please select a service')}
      </StyledTitle>
      <BackButton rootPathCheck={isRootPath} customPath={backUrl} />
      <StructureContainer>
        <LastMinutesItem isListView={isListView} />
        <WorkshopItem display={isListView ? 'list' : 'tile'} />
        {isListView ? <ListStructure /> : <TileStructure />}
      </StructureContainer>
      <OptionsContainer />
    </StyledScrollView>
  )
}
