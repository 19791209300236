import React from 'react'
import { ScrollView, View } from 'react-native'

import {
  Control,
  FieldErrors,
  FieldName,
  FieldValues,
  SetFieldValue,
  SetValueConfig,
} from 'react-hook-form'
import { ChoiceTypeField } from './ChoiceTypeField/ChoiceTypeField'
import { OpenTypeField } from './OpenTypeField/OpenTypeField'
import { Question, QuestionContainer, QuestionNumber } from './style'

type Props = {
  id: string
  question: string
  highlight: string
  bgcolor: string
  fontcolor: string
  type: 'open' | 'choice' | 'condition'
  isMultiline?: boolean
  isMultipleChoice?: boolean
  choices: string[]
  number: number
  control: Control<FieldValues>
  errors: FieldErrors<FieldValues>
  setValue: (
    name: FieldName<FieldValues>,
    value: SetFieldValue<FieldValues>,
    config?: SetValueConfig
  ) => void
}

export const QuestionCard: React.FC<Props> = (props) => (
  <View>
    <QuestionContainer>
      <QuestionNumber highlight={props.highlight} bgcolor={props.bgcolor}>
        {props.number}
      </QuestionNumber>
      {props.type === 'condition' ? (
        <ScrollView>
          <Question fontcolor={props.fontcolor}>{props.question}</Question>
        </ScrollView>
      ) : (
        <Question fontcolor={props.fontcolor}>{props.question}</Question>
      )}
    </QuestionContainer>
    <View>
      {props.type === 'open' || props.type === 'condition' ? (
        <OpenTypeField
          fontcolor={props.fontcolor}
          isMultiline={props.isMultiline}
          control={props.control}
          errors={props.errors}
          questionId={props.id}
          inputType={props.type}
          question={props.question}
        />
      ) : (
        <ChoiceTypeField
          fontcolor={props.fontcolor}
          isMultipleChoice={props.isMultipleChoice}
          choices={props.choices}
          control={props.control}
          questionId={props.id}
          setValue={props.setValue}
        />
      )}
    </View>
  </View>
)
