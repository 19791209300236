import { useTranslation } from 'react-i18next'
import React, { useEffect } from 'react'
import { Text } from 'react-native'
import { useHistory, useParams } from '../../../Router'
import { useAppDispatch } from '../../../store/hooks'
import { SelectPersonContainer } from '../../../Appointment/Components/DisplayActivities/Components/SelectPersonPerActivity/style'
import { getPersons } from '../../../SelectPerson/store/Actions'

type Props = {
  highlightcolor: string
  selectedPerson: string
  activityId: number
  isPreferredPerson: boolean
}

export const EditPersonPerActivity = ({
  highlightcolor,
  selectedPerson,
  activityId,
  isPreferredPerson,
}: Props) => {
  const { t } = useTranslation()
  const { establishmentId } = useParams<{ [key: string]: string }>()
  const history = useHistory()
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(getPersons(establishmentId))
  }, [establishmentId])

  const onSelectPerson = () => {
    history.push(`/${establishmentId}/edit-person/${activityId}`)
  }

  return (
    <SelectPersonContainer>
      <Text style={{ color: highlightcolor }} onPress={onSelectPerson}>
        {selectedPerson && isPreferredPerson
          ? selectedPerson
          : t('Select preferred employee')}
      </Text>
    </SelectPersonContainer>
  )
}
