export const enBE = `**Privacy Policy OPTIOS**
=========================

_Version 2022.01_

This Privacy Statement applies when you visit the website [www.optios.net](http://optios.squarespace.com) or when you register on the Optios platform (hereinafter referred to as "Optios"). It explains why the BV OPTIOS (hereinafter referred to as "Optios BV", "we" or "us") processes your personal data when you use our services.

Contents

[1 Point of contact to submit inquiries](#_Toc101864129)

[2 Website visits.](#_Toc101864130)

[3 Applicants](#_Toc101864131)

[4 Optios](#_Toc101864132)

[4.1 Creating an account](#_Toc101864133)

[4.2 Using Optios](#_Toc101864134)

5 Types of personal & sensitive user data that the app accesses, collects & uses

[6 With whom do we share your personal data?](#_Toc101864135)

[7 How do we secure your personal data?](#_Toc101864136)

[8 What are your rights and how can you exercise them?](#_Toc101864137)

[9 Hyperlinks](#_Toc101864138)

1 Point of contact to submit inquiries
----------------------------------------

The controller of your personal data is:

                  **BV OPTIOS**

                  Antwerpse Steenweg 19

                  9080 Lochristi

                  0629.718.060

If you have any questions about the processing of your personal data by Optios BV or about the content of this Privacy Statement, you can always contact us at help@optios.net.

Optios BV reserves the right to change or revise this Privacy Statement from time to time. If you are registered through Optios, we will notify you before such changes or revisions take effect.

2 Website visits.
-------------------

When you visit our website, in certain circumstances personal data may be collected from you using cookies (see our cookie statement in this regard).

Our website also provides you with various ways to share personal data with us. You can request a consultation or contact us by means of a contact form.

In these cases, we ask for the information necessary to provide you with the desired information such as, among others, your name and e-mail address. These processing operations are always based on our legitimate interest, which is to respond to your various inquiries.  

Our website also offers you the possibility to contact us via the chatbot. In this case, the data you leave there will be processed. This processing is part of our legitimate interest, in particular responding to the questions you ask.

3 Applicants
--------------

When you apply to Optios, we collect the following data: your name, e-mail address, telephone number, possibly a photo, CV, cover letter and any other information you provide in your correspondence with us.

Optios BV uses the above information for internal recruitment purposes only. If your application is not retained, we will retain your personal data for a maximum of 30 days. If we would like to keep your application in our recruitment reserve, we will always ask your permission. Your data will be kept in our recruitment reserve for a maximum of 2 years. 

4 Optios
----------

### 4.1 Creating an account

When you create your account in Optios, your name, company name and email address will be requested.

The processing of this personal data is necessary to carry out the agreement between you and us regarding the use of Optios. If you do not wish to transfer this personal data to Optios BV, you can not use Optios as this processing is necessary to facilitate your access to and use of Optios.

### 4.2 Using Optios

When you use Optios, we additionally collect the following personal data:

A cell phone number, any additional phone number, gender and the language in which we communicate with you. In the case of a sole proprietorship, we also process your address, account number and VAT number.

We process this personal data for the following reasons:

§ To continuously improve our services and thus develop new functionalities. The processing is based on our legitimate interest, in particular to better tailor our services to the needs of our customers;

§ Keeping Optios safe for you and all our users. This processing is based on our legitimate interest, in particular to provide a secure platform for all stakeholders;

§ To keep track of how Optios is being used and whether the Optios Terms of Use are being abused. This processing is compatible with the fact that the contract between you and Optios is correctly executed;

§ Properly billing for services rendered.

5 Types of personal & sensitive user data that the app accesses, collects & uses
----------------------------------------------------------------------------------

When the Optios online booking (app) is used, the user is asked to enter personal data to confirm their appointment. Namely:

 First name

 Last name

 Phone

 E-mail address

This data is stored together with the appoinment details (date, time  
and serivces) in the database of Optios. This data can be used by the  
salon owner for marketing en communication purposed.

The data collected with the Optios app will not be distributed to any other  
parties apart from Optios and the salon itself.

6 With whom do we share your personal data?
---------------------------------------------

Your personal data will be processed solely for the purposes listed above.

Optios BV may also use third parties in the context of the aforementioned purposes, such as, for example, for software integration (e.g. software providers) and hosting purposes (e.g. cloud providers, external data centers...), but also an external recruitment agency.

Under no circumstances will your personal data be passed on to third parties for commercial purposes.

Furthermore, your data will not be transferred to countries outside the European Economic Area. Should this change in the future, we will ensure that we have taken the necessary appropriate safeguards and additional measures as required by data protection legislation.

7 How do we protect your personal data?
-----------------------------------------

Optios BV takes all reasonable technical and organizational measures to ensure the protection of your personal data so that it is protected against misuse, loss or unauthorized access. Thus, access to your personal data is only granted on a need-to-know basis. These measures are always based on the current state of the art. However, the security and safety of your personal data is always a best-effort obligation for which we cannot offer absolute guarantees.

8 What are your rights and how can you exercise them?
-------------------------------------------------------

If you have a complaint, a question or a problem with the way we process your personal data you can contact us using the contact details described in section 1.

If you contact us to exercise your rights we will reply within 1 month. Exceptionally this may take longer (up to a maximum of 3 months), but in that case we will inform you within 1 month. Whether or not you can exercise your rights is always linked to the processing itself and the legal basis.

If you wish to exercise your rights, we would ask you to clearly define which right you wish to exercise and by what means (e.g. e-mail, post) you wish to obtain the information requested. In addition, we have a duty to identify ourselves. We must therefore always verify whether you are actually entitled to obtain the information requested. For that reason, we may ask you for a copy of your identity card. 

**Right of access and correction**

If you would like to know what personal data Optios BV processes from you and for what purposes, please contact us. We can provide you with a free copy of the personal data we process from you. If you believe that your personal data is not correct or up to date, you can request us to correct it.

**Right of removal and restriction**

You have the right to ask us to delete your personal data. However, if we are legally obliged to keep certain personal data for a longer period, we cannot comply with this request. In that case we will notify you.

If you have legitimate reasons to do so, you can request us to restrict the processing of your personal data. For example, because you dispute the accuracy of your data.

**Right of retention**

If the processing of your personal data is based on our legitimate interest or is part of the performance of a task carried out in the public interest or under public authority, you can object to the processing of your personal data in a substantiated manner.

**Right to data portability**

If we process your personal data on the basis of your (explicit) consent or in the context of the performance of the contract between you and us, you may request us to provide you with the personal data you have provided to us in a structured, commonly used and machine-readable form or to transfer it to another data controller, provided that the processing is carried out by automated means.

**Right to withdraw your consent**

Where the processing of your personal data by Optios BV is based on your (explicit) consent, you have the right to withdraw such consent at any time. However, the withdrawal of your consent shall not affect the lawfulness of the processing prior to the withdrawal of your consent.

**Right to file a complaint**

If you are not satisfied with the way in which Optios BV processes your personal data or if you feel that you have not been sufficiently heard by Optios BV with respect to your privacy complaints, you always have the right to lodge a complaint with the Belgian supervisory authority via:

                  **Data Protection Authority**

                  Printing Press Street 35                

                  1000 Brussels

                  Tel. 02/274.48.00

                  Fax. 02/274.48.35

            [contact@apd-gba.be](mailto:contact@apd-gba.be)

9 Hyperlinks
--------------

This website may contain hyperlinks to websites of other parties. When you click on these hyperlinks you will leave the website of Optios BV. Optios BV is in no way responsible for the privacy practices of these websites. It is always recommended to read the privacy statements of these external websites carefully.`