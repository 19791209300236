import styled from 'styled-components/native'

export const StyledText = styled.Text<{
    highlight: string
}>`
    color: ${({ highlight }) => highlight};
    font-family: 'ProximaNova-Extrabld, proxima-nova, "Helvetica Neue", Helvetica, Arial, sans-serif';
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
    margin: 0;
    padding: 0;
    text-align: center;
    align-self: center;
`
