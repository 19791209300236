import React from 'react'
import { CheckBox } from 'react-native'
import { stringToHsl } from '../../Util'
import { ListImage, ListColor } from './style'

type Props = {
  title: string
  image?: string
  isChild: boolean
  isShowCheckBox: boolean
  isCheckBoxActive: boolean
}

export const ImageSwitch = ({
  title,
  image,
  isChild,
  isShowCheckBox,
  isCheckBoxActive,
}: Props) => {
  if (isShowCheckBox) return <CheckBox value={isCheckBoxActive} />

  if (image) {
    return <ListImage source={{ uri: image }} isChild={isChild} />
  }
  return <ListColor color={stringToHsl(title, 50, 50)} isChild={isChild} />
}
