import { StyleSheet } from 'react-native'

export const Style = (fontColor: string) => StyleSheet.create({
  inputContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 14,
    marginLeft: 36,
  },
  input: {
    marginRight: 5,
  },
  inputError: {
    color: 'white',
    position: 'absolute',
    backgroundColor: '#D24444',
    borderRadius: 3,
    paddingTop: 3,
    paddingRight: 5,
    paddingBottom: 3,
    paddingLeft: 5,
    right: 5,
    bottom: 35,
  },
  optionText: {
    color: fontColor
  }
})
