import { Dimensions, Text } from 'react-native'

import Modal from 'modal-enhanced-react-native-web'
import styled from 'styled-components/native'

import { ProximaNova } from '../TextComponent'

// TODO dimsensions are also use in snackbar, should be on more global level (getting on evry comp lifecycle is buggy)
export const modalWindowDimensions = Dimensions.get('window')
export const changeModalMargin = modalWindowDimensions.height < 680

export const StyledMobileModal = styled(Modal)<{
  backgroundcolor: string
}>`
  position: absolute;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  background-color: ${({ backgroundcolor }) => backgroundcolor};
  width: 100%;
  margin: 0;
`

export const StyledModal = styled(StyledMobileModal)<{
  backgroundcolor: string
}>`
  width: ${modalWindowDimensions.width > 640
    ? 440
    : modalWindowDimensions.width - 20};
  min-width: 330px;
  margin: ${changeModalMargin ? '0 0 0 50%' : '-340px 0 0 50%'};
  top: ${changeModalMargin ? 0 : '50%'};
  left: ${modalWindowDimensions.width > 640
    ? -440 / 2
    : -(modalWindowDimensions.width - 20) / 2};
  max-height: 680px;
`

export const ModalMessageTitle = styled(ProximaNova)`
  font-size: 19px;
  margin: 1em 0 0.5em 0;
`

export const ModalMessage = styled(ProximaNova)<{
  highlightcolor: string
}>`
  padding: 1em 1.5em 1.5em;
  line-height: 1.5em;
  pointer-events: none;
`

export const ModalCloseWrapper = styled(Text)`
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 10;
  border-radius: 14px;
`

export const ModalExclamationWrapper = styled(Text)`
  margin: 20px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
`
