import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { BackButton, Spinner, Tile } from '../App/Components'
import { StyledScrollView, TileContainer } from '../App/Components/AppContainer'
import { IMenuActivity } from '../Menu/Store/IMenuActivity'
import { useHistory, useParams } from '../Router'

import { EligiblePerson } from './Components/EligiblePerson/EligiblePerson'
import { getPersons } from './store/Actions'
import { filterCustomerActivities } from './utils/filterCustomerActivities'
import { noPreferenceTilePress } from './utils/noPreferenceTilePress'
import { useAppDispatch, useAppSelector } from '../store/hooks'

type Params = {
  establishmentId: string
  customerIndex: string
  activityId: string
}

export const SelectPerson = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const history = useHistory()
  const params = useParams<Params>()

  const { establishmentId } = params
  const customerIndex = params.customerIndex
    ? Number(params.customerIndex)
    : undefined
  const activityId = params.activityId ? Number(params.activityId) : undefined

  const { settings, appointment, persons } = useAppSelector((state) => ({
    appointment: state.appointment,
    persons: state.persons.persons,
    settings: state.settings.values,
  }))

  useEffect(() => {
    if (typeof persons === 'undefined') {
      dispatch(getPersons(establishmentId))
    }
  }, [])

  const customerActivities: IMenuActivity[] | [] = useMemo(
    () =>
      filterCustomerActivities(
        appointment,
        establishmentId,
        history,
        customerIndex
      ),
    [customerIndex, appointment]
  )

  if (typeof settings === 'undefined') {
    return (
      <StyledScrollView>
        <Spinner />
      </StyledScrollView>
    )
  }
  const { isPreferredPersonPerActivity, allowNoPreferenceSelection } = settings

  const onTilePress = () => {
    noPreferenceTilePress(
      dispatch,
      isPreferredPersonPerActivity,
      activityId,
      customerIndex
    )
    history.push(`/${establishmentId}/appointment`)
  }

  return (
    <StyledScrollView>
      <BackButton extraMargin goBack={-1} isAppointment={false} />
      <TileContainer>
        <Tile
          title={t('No preference')}
          image="https://client.optios.net/img/avatar-female.png"
          onPress={onTilePress}
          noPreferenceSelection={allowNoPreferenceSelection}
        />
        <EligiblePerson
          customerActivities={customerActivities}
          persons={persons}
          isPreferredPersonPerActivity={isPreferredPersonPerActivity}
          establishmentId={establishmentId}
          customerIndex={customerIndex}
          activityId={activityId}
        />
      </TileContainer>
    </StyledScrollView>
  )
}
