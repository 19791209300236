import styled from 'styled-components/native'

import { ProximaNova } from '../../../../../App/Components'

export const InfoText = styled(ProximaNova)`
    text-align: center;
    align-self: center;
    margin-top: 16px;
    max-width: 332px;
`
