import moment from 'moment'
import {IApiWorkshop, IApiWorkshopSubscription, IWorkshop, IWorkshopSubscription} from './IWorkshop'

export default class WorkshopTransformer {
  public static transform(apiWorkshop: IApiWorkshop): IWorkshop {
    return {
      ...apiWorkshop,
      startsAt: moment(apiWorkshop.starts_at),
      endsAt: moment(apiWorkshop.ends_at)
    }
  }

  public static transformSubscription(apiWorkshopSubscription: IApiWorkshopSubscription): IWorkshopSubscription {
    return {
      ...apiWorkshopSubscription,
      uuid: apiWorkshopSubscription.workshop_uuid,
      startsAt: moment(apiWorkshopSubscription.starts_at),
      endsAt: moment(apiWorkshopSubscription.ends_at),
      subscriptionUuid: apiWorkshopSubscription.subscription_uuid
    }
  }
}
