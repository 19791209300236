import { ApiAppointmentBlock, ApiAppointmentResponseData } from '../../../../Appointment/store/interface/IApiAppointmentResponse'
import { SuccessMessageInfo } from './SuccessMessage/SuccessMessage'

const activityFilter = (blocks: ApiAppointmentBlock[], isShowNoDuration: boolean): string[] =>blocks
  .filter((block) => isShowNoDuration || block.ends_at !== block.starts_at)
  .map((block) => block.activity.name)

export const transformAppointmentInfo = (
  response: ApiAppointmentResponseData,
  isShowActivitiesWithNoDuration: boolean
): SuccessMessageInfo => ({ 
  startsAt: response.starts_at,
  customerName: response.customer.full_name,
  activities: activityFilter(response.blocks, isShowActivitiesWithNoDuration),
  isShowActivitiesWithNoDuration
})