import React from 'react'

import { useTranslation } from 'react-i18next'
import { Tile } from '../App/Components'
import { StyledScrollView, TileContainer } from '../App/Components/AppContainer'
import { useHistory, useParams } from '../Router'
import { useAppSelector } from '../store/hooks'
import { StyledHeader } from './style'

export const SelectLocation = () => {
  const { establishmentId } = useParams()
  const history = useHistory()
  const { t } = useTranslation()

  const { settings } = useAppSelector((state) => ({
    settings: state.settings.values,
  }))

  const onlineBookableLocations = settings.locations.filter(
    (location) => location.onlineBookingEnabled
  )
  if (onlineBookableLocations.length === 0) {
    if (settings.isMenuVisible) history.push(`/${establishmentId}/menu`)
    else history.push(`/${establishmentId}/activities`)
    return null
  }

  if (onlineBookableLocations.length === 1) {
    if (settings.isMenuVisible)
      history.push(`/${onlineBookableLocations[0].id}/menu`)
    else history.push(`/${onlineBookableLocations[0].id}/activities`)
    return null
  }

  return (
    <StyledScrollView>
      <StyledHeader>{t('Make your choice')}</StyledHeader>
      <TileContainer>
        {settings.locations
          .filter((location) => location.onlineBookingEnabled)
          .map((location) => {
            const handleOnPress = () => {
              if (settings.isMenuVisible) history.push(`/${location.id}/menu`)
              else history.push(`/${location.id}/activities`)
            }
            return (
              <Tile
                image={location.image}
                title={location.name}
                onPress={handleOnPress}
                key={location.id}
              />
            )
          })}
      </TileContainer>
    </StyledScrollView>
  )
}
