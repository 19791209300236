import React, { useMemo, useState } from 'react'
import { faLink } from '@fortawesome/pro-regular-svg-icons'
import { useTranslation } from 'react-i18next'
import Clipboard from '@react-native-clipboard/clipboard'
import { ContextModal, MenuOption, MenuOptionText } from './style'
import { FaIcon } from '../FaIcon/FaIcon'
import { ISettingValues } from '../../../Settings/ISettings'
import { useAppSelector } from '../../../store/hooks'

interface IProps {
  settingValues: ISettingValues
}

export const ContextMenu = ({ settingValues }: IProps) => {
  const [isContextMenuVisible, setContextMenuVisible] = useState(false)

  const { t } = useTranslation()

  const menuData = useAppSelector((state) => state.contextMenu)

  const hideContextMenu = () => {
    const clipboardUrl = `${window.location.origin}${menuData.menuItems.tileUrl}`
    setContextMenuVisible(false)
    Clipboard.setString(clipboardUrl)
  }

  useMemo(() => {
    if (menuData.isActive) {
      setContextMenuVisible(true)
    } else setContextMenuVisible(false)
  }, [menuData])

  if (typeof settingValues === 'undefined') {
    return null
  }

  return (
    <ContextModal
      display={isContextMenuVisible}
      onRequestClose={hideContextMenu}
      highlight={settingValues.highlightColor}
      position={menuData.position}
    >
      <MenuOption onPress={hideContextMenu} onClick={hideContextMenu}>
        <FaIcon size="sm" icon={faLink} color={settingValues.highlightColor} />
        <MenuOptionText highlight={settingValues.highlightColor}>
          {t('Copy Link')}
        </MenuOptionText>
      </MenuOption>
    </ContextModal>
  )
}
