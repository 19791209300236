import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Spinner, Tile } from '../../../App/Components'
import { addActivity } from '../../../Appointment/store/Actions'
import { useHistory, useLocation, useParams } from '../../../Router'
import { IActivityCategory, IExtraActivity } from '../../Client/IExtraActivity'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { personSelectPathCheck } from '../../../App/Util/personSelectPathCheck'
import { activityFilter } from '../../../App/Util/activityFilter'

export const TileStructure = () => {
  const { establishmentId, categoryId, activityId } = useParams<{
    establishmentId: string
    categoryId: undefined | string
    activityId: undefined | string
  }>()
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useAppDispatch()
  const location = useLocation()

  const {
    extraActivities,
    settings,
    lastMinutes,
    currentCustomerIndex,
    isActivitiesLoading,
    isPersonsLoading,
    isExistingCustomer,
  } = useAppSelector((state) => ({
    isActivitiesLoading: state.activities.loading,
    extraActivities: state.activities.list,
    lastMinutes: state.lastMinute.list,
    settings: state.settings.values,
    currentCustomerIndex: state.appointment.currentCustomerIndex,
    isPersonsLoading: state.persons.loading,
    isExistingCustomer: state.settings.isCustomerExisting,
  }))

  useEffect(() => {
    if (activityId && extraActivities) {
      const category = extraActivities.find(
        (oneCategory) => oneCategory.id === Number(categoryId)
      )
      if (!category) {
        return
      }

      const activity = category.activities.find(
        (oneActivity) => oneActivity.id === Number(activityId)
      )

      if (!activity) {
        return
      }

      dispatch(addActivity(activity))

      const pathUrl = personSelectPathCheck(
        settings.isPersonPreferenceMandatory,
        activity.isPersonSelectable,
        establishmentId,
        currentCustomerIndex,
        activity.id.toString()
      )
      history.replace(pathUrl)
    }
  }, [categoryId, activityId, extraActivities])

  if (typeof settings === 'undefined' || isActivitiesLoading) {
    return <Spinner />
  }

  if (
    typeof extraActivities === 'undefined' ||
    extraActivities.length === 0 ||
    typeof lastMinutes === 'undefined' ||
    isPersonsLoading
  ) {
    return <Spinner />
  }

  let list = []
  if (extraActivities && categoryId) {
    const category = extraActivities.find(
      (oneCategory) => oneCategory.id === parseInt(categoryId, 10)
    )
    if (category) {
      list = activityFilter(
        category.activities,
        settings.isCertainServiceBlock,
        isExistingCustomer
      ).map((extraActivity: IExtraActivity) => {
        const navigationLink: string =
          `${location.pathname}/${extraActivity.id}`.replace('//', '/')
        return (
          <Tile
            key={extraActivity.id}
            title={t(`activities;;${extraActivity.name}`)}
            description={extraActivity.description}
            link={navigationLink}
            onPress={(e) => {
              e.preventDefault()
              e.stopPropagation()

              dispatch(addActivity(extraActivity))
              const pathUrl = personSelectPathCheck(
                settings.isPersonPreferenceMandatory,
                extraActivity.isPersonSelectable,
                establishmentId,
                currentCustomerIndex,
                extraActivity.id.toString()
              )
              history.push(pathUrl)

              return false
            }}
            image={extraActivity.image}
          />
        )
      })
    }
  } else if (extraActivities) {
    list = extraActivities
      .filter(
        (category: IActivityCategory) =>
          activityFilter(
            category.activities,
            settings.isCertainServiceBlock,
            isExistingCustomer
          ).length > 0
      )
      .map((category: IActivityCategory) => (
        <Tile
          key={`category${category.id}`}
          title={t(`activities;;${category.name}`)}
          link={`/${establishmentId}/activities/${category.id}`}
          onPress={(event) => {
            event.preventDefault()
            event.stopPropagation()
            history.push(`/${establishmentId}/activities/${category.id}`)
          }}
          image={category.image}
        />
      ))
  }
  return <>{list}</>
}
