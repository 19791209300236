import React, { useEffect } from 'react'
import { View } from 'react-native'

import { Spinner } from '../../../App/Components'
import { addActivity } from '../../../Appointment/store/Actions'
import { useHistory, useParams } from '../../../Router'
import { ExtraActivityMap } from './Components/ExtraActivityMap/ExtraActivityMap'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { personSelectPathCheck } from '../../../App/Util/personSelectPathCheck'

export const ListStructure = () => {
  const { establishmentId, categoryId, activityId } =
    useParams<{ [key: string]: string }>()
  const history = useHistory()
  const dispatch = useAppDispatch()

  const {
    extraActivities,
    settings,
    lastMinutes,
    currentCustomerIndex,
    isExistingCustomer,
  } = useAppSelector((state) => ({
    extraActivities: state.activities.list,
    settings: state.settings.values,
    lastMinutes: state.lastMinute.list,
    currentCustomerIndex: state.appointment.currentCustomerIndex,
    isExistingCustomer: state.settings.isCustomerExisting,
  }))

  useEffect(() => {
    if (activityId && extraActivities) {
      const category = extraActivities.find(
        (oneCategory) => oneCategory.id === Number(categoryId)
      )
      if (!category) {
        return
      }

      const activity = category.activities.find(
        (oneActivity) => oneActivity.id === Number(activityId)
      )

      if (!activity) {
        return
      }

      dispatch(addActivity(activity))

      const pathUrl = personSelectPathCheck(
        settings.isPersonPreferenceMandatory,
        activity.isPersonSelectable,
        establishmentId,
        currentCustomerIndex,
        activity.id.toString()
      )
      history.replace(pathUrl)
    }
  }, [categoryId, activityId, extraActivities])

  if (typeof settings === 'undefined') {
    return <Spinner />
  }

  if (
    typeof extraActivities === 'undefined' ||
    extraActivities.length === 0 ||
    typeof lastMinutes === 'undefined'
  ) {
    return <Spinner />
  }
  return (
    <View>
      <ExtraActivityMap
        extraActivities={extraActivities}
        settings={settings}
        isExistingCustomer={isExistingCustomer}
      />
    </View>
  )
}
