import styled from 'styled-components/native'

export const TileImageContainer = styled.Image`
  border-radius: 5px;
  width: 160px;
  height: 160px;
`

export const TileEmptyImage = styled.View<{ backgroundColor: string }>`
  border-radius: 5px;
  width: 160px;
  height: 160px;
  background-color: ${({ backgroundColor }) => backgroundColor};
`
