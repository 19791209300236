import { URL_API } from 'react-native-dotenv'

import axios, { AxiosPromise } from 'axios'
import { getUserToken } from '../../App/Util/localStorageHelper/userToken/setUserToken'

export default class Client {
  public static getLastMinutes(establishmentId: string): Promise<any> {
    const requestOptions: object = {
      method: 'GET',
      url: `${URL_API}/anonymous/last-minutes?establishment_id=${establishmentId}`,
    }

    return axios(requestOptions)
  }

  public static bookLastMinute(
    establishmentId: string,
    lastMinutesId: string,
    firstName: string,
    lastName: string,
    phone: number,
    email: string,
    comment: string,
    street?: string,
    city?: string,
    zip?: string
  ): AxiosPromise {
    const customerToken = getUserToken(establishmentId, email)
    const CustomerData = {
      first_name: firstName,
      last_name: lastName,
      phone,
      email,
      street,
      city,
      zip,
      token: customerToken,
    }

    const requestOptions: object = {
      method: 'POST',
      url: `${URL_API}/anonymous/last-minutes`,
      data: {
        establishment_id: establishmentId,
        remark: comment,
        customer_data: CustomerData,
        last_minute_id: lastMinutesId,
      },
    }

    return axios(requestOptions)
  }
}
