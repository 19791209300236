import React from 'react'
import {
  TouchableHighlight,
  View,
  Platform,
  GestureResponderEvent,
} from 'react-native'

import { DescriptionModal } from '../modal/DescriptionModal/DescriptionModal'

import { Tile as TileContainer } from './style'
import { TileText } from './components/TileText/TileText'
import { TileImage } from './components/TileImage/TileImage'

type Props = {
  title: string
  link?: string
  image: string
  onPress?: (e: GestureResponderEvent) => void
  description?: string
  noPreferenceSelection?: boolean
}

export const Tile = ({
  image,
  link,
  title,
  onPress,
  description,
  noPreferenceSelection,
}: Props) => {
  const isWeb = Platform.OS === 'web'

  let imageUrl = null
  if (image) {
    imageUrl = image
    if (typeof imageUrl === 'string' && imageUrl.includes('files.optios.')) {
      imageUrl += '&w=160&h=160&fit=crop'
    }

    if (typeof imageUrl === 'string' && imageUrl.startsWith('//')) {
      imageUrl = `https:${imageUrl}`
    }
  }

  if (noPreferenceSelection === false) {
    return null
  }

  return (
    <TileContainer data-url-info={link}>
      <DescriptionModal description={description} link={link} title={title} />
      <TouchableHighlight data-url-info={link} onPress={onPress}>
        <View data-url-info={link}>
          <TileImage link={link} imageUrl={imageUrl} title={title} />
          <TileText link={link} isWeb={isWeb} title={title} />
        </View>
      </TouchableHighlight>
    </TileContainer>
  )
}
