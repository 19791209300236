import React from 'react'
import { useTranslation } from 'react-i18next'

import { useHistory } from 'react-router'
import { useLocation, useParams } from '../../Router'
import { Tile } from '../../App/Components'
import { useAppSelector } from '../../store/hooks'
import { WorkshopListView } from '../WorkshopListView/WorkshopListView'

type Props = {
  display: 'tile' | 'list'
}

export const WorkshopItem = ({ display }: Props) => {
  const history = useHistory()
  const location = useLocation()
  const { t } = useTranslation()
  const { establishmentId } = useParams<{ [key: string]: string }>()

  const workshops = useAppSelector((state) => state.workshops.list)

  const currentPath = location.pathname.replace(/\/+$/, '')
  const isRootPath: boolean =
    currentPath === `/${establishmentId}/menu` ||
    currentPath === `/${establishmentId}/activities`

  const showItem: boolean =
    typeof workshops !== 'undefined' && workshops.length > 0 && isRootPath

  if (!showItem) {
    return null
  }

  if (display === 'list') {
    return (
      <WorkshopListView
        title={t('Workshops')}
        image="//files.optios.net/?id=1392347"
      />
    )
  }

  return (
    <Tile
      title={t('Workshops')}
      onPress={() =>
        history.push(`/${location.pathname.split('/')[1]}/workshops`)
      }
      image="//files.optios.net/?id=1392347"
    />
  )
}
