import React, { useEffect, useState } from 'react'
import { View } from 'react-native'

import { AbsoluteSpinner, Spinner } from '../../../App/Components'
import { addMenu } from '../../../Appointment/store/Actions'
import { useHistory, useLocation, useParams } from '../../../Router'
import { ListStructureMap } from '../../Menu/Components/ListStructure/Components/ListStructureMap/ListStructureMap'
import { IMenuCategory } from '../../Store/IMenuCategory'
import { isPersonSelectableCheck } from '../../utils/isPersonSelectableCheck/isPersonSelectableCheck'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { personSelectPathCheck } from '../../../App/Util/personSelectPathCheck'
import { menuCategoryFilter } from '../../../App/Util/menuCategoryFilter'

export const ListStructure = () => {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const history = useHistory()
  const { establishmentId } = useParams<{ [key: string]: string }>()

  const [currentCategories, setCurrentCategories] = useState(null)

  const settings = useAppSelector((state) => state.settings.values)
  const categories = useAppSelector((state) => state.menu.categories)
  const currentCustomerIndex = useAppSelector(
    (state) => state.appointment.currentCustomerIndex
  )
  const lastMinutes = useAppSelector((state) => state.lastMinute.list)
  const isExistingCustomer = useAppSelector(
    (state) => state.settings.isCustomerExisting
  )

  const getCurrentCategories = (
    breadcrumbs: number[],
    menuCategories: IMenuCategory[]
  ): undefined | IMenuCategory[] => {
    if (breadcrumbs.length === 0) {
      return menuCategoryFilter(
        menuCategories,
        settings.isCertainServiceBlock,
        isExistingCustomer
      )
    }

    const menuCategory = menuCategories.find(
      (category: IMenuCategory) => category.id === breadcrumbs[0]
    )
    if (!menuCategory || !menuCategory.categories) {
      if (menuCategory.activities) {
        const isPersonSelectable = isPersonSelectableCheck(menuCategory)
        const pathUrl = personSelectPathCheck(
          settings.isPersonPreferenceMandatory,
          isPersonSelectable,
          establishmentId,
          currentCustomerIndex
        )
        dispatch(addMenu(menuCategory.activities, menuCategory.id))
        history.push(pathUrl)
      }

      return menuCategoryFilter(
        menuCategories,
        settings.isCertainServiceBlock,
        isExistingCustomer
      )
    }

    return getCurrentCategories(breadcrumbs.splice(1), menuCategory.categories)
  }

  useEffect(() => {
    if (!categories) {
      return
    }
    const breadcrumbs: number[] = location.pathname
      .split('/')
      .splice(3)
      .filter(Boolean)
      .map((breadcrumb): number => Number(breadcrumb))
    setCurrentCategories(getCurrentCategories(breadcrumbs, categories))
  }, [categories, location.pathname])

  if (settings && !settings.isMenuVisible) {
    const activityUrl = `/${establishmentId}/activities`
    history.push(activityUrl)
    return <Spinner />
  }

  if (
    currentCategories === null ||
    typeof currentCategories === 'undefined' ||
    typeof lastMinutes === 'undefined'
  ) {
    return <AbsoluteSpinner />
  }

  return (
    <View>
      <ListStructureMap currentCategories={currentCategories} />
    </View>
  )
}
