import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'

import { AbsoluteSimpleSpinner, BackButton } from '../../App/Components'
import { StyledScrollView } from '../../App/Components/AppContainer'
import { useParams } from '../../Router'
import { LastMinuteCard } from '../LastMinuteCard/LastMinuteCard'
import { getLastMinutes } from '../store/Actions'
import { ILastMinute } from '../store/ILastMinute'

import { TextNoLastMinutes, ViewNoLastMinutes } from './style'
import { useAppDispatch, useAppSelector } from '../../store/hooks'

export const LastMinutesOverview = () => {
  const dispatch = useAppDispatch()
  const { establishmentId } = useParams<{ [key: string]: string }>()
  const { t } = useTranslation()

  const settings = useAppSelector((state) => state.settings.values)
  const lastMinutes = useAppSelector((state) => state.lastMinute.list)

  useEffect(() => {
    dispatch(getLastMinutes(establishmentId))
  }, [])

  let content = (
    <View>
      <AbsoluteSimpleSpinner />
    </View>
  )

  if (lastMinutes) {
    if (lastMinutes.length === 0) {
      content = (
        <ViewNoLastMinutes>
          <TextNoLastMinutes>{t('No last-minute found')}</TextNoLastMinutes>
        </ViewNoLastMinutes>
      )
    } else {
      content = (
        <View>
          {lastMinutes.map((lastMinute: ILastMinute) => (
            <LastMinuteCard
              key={lastMinute.id}
              settings={settings}
              lastMinute={lastMinute}
            />
          ))}
        </View>
      )
    }
  }

  return (
    <StyledScrollView>
      <BackButton extraMargin />
      {content}
    </StyledScrollView>
  )
}
