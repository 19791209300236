import React from 'react'
import { ISettingValues } from '../Settings/ISettings'

interface IProps {
  settings: ISettingValues
}
/* eslint-disable */
export const Helmet = ({ settings }: IProps) => {
  if (!settings) {
    return null
  }
  return (
    <>
      <title> Optios online booking </title>
      <meta name="description" content={settings.message} />
      <meta property="og:image" content={`https:${settings.logo}`} />
      <meta property="og:title" content="Optios online booking" />
      <meta property="og:image:width" content="280" />
      <meta property="og:image:height" content="280" />
      <meta property="twitter:card" content="summary_large_image" />
      <meta
        property="twitter:image"
        content={`https:${settings.logo}&w=400&h=200&fit=crop`}
      />
      <meta property="twitter:image:width" content="280" />
      <meta property="twitter:image:height" content="280" />
      <meta property="twitter:title" content="Optios online booking" />
      <meta property="twitter:description" content={settings.message} />
      <link rel="canonical" href="/Users/maxim/Public" />
    </>
  )
}
