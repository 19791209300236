import React, { FunctionComponent } from 'react'

import { faTrash } from '@fortawesome/pro-regular-svg-icons'

import { FaIcon } from '../../../../../App/Components'

import { IProps } from './IProps'
import { RemoveActivityContainer } from './style'

export const RemoveActivityIcon: FunctionComponent<IProps> = ({
  isShowRemoveIcon,
  highlightcolor,
  removeActivity,
}) => {
  if (!isShowRemoveIcon) {
    return null
  }

  return (
    <RemoveActivityContainer
      highlightcolor={highlightcolor}
      onPress={removeActivity}
    >
      <FaIcon size="sm" color="#d24444" icon={faTrash} />
    </RemoveActivityContainer>
  )
}
