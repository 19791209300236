export const isDesktopCheck = () => {
  let isDesktop = true
  const {userAgent} = window.navigator
  if(!userAgent) return isDesktop
  if (
    userAgent.match(/Android/i) ||
  userAgent.match(/webOS/i) ||
  userAgent.match(/iPhone/i) ||
  userAgent.match(/iPad/i) ||
  userAgent.match(/iPod/i) ||
  userAgent.match(/BlackBerry/i) ||
  userAgent.match(/Windows Phone/i)
  ) {
    isDesktop = false
  }
  return isDesktop
}