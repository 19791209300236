import React, { FunctionComponent, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { AbsoluteSpinner, ButtonWithText } from '../../../App/Components'
import { useHistory } from '../../../Router'

import { IProps } from './IProps'
import { ConfirmationModal } from '../../../App/Components/modal/ConfirmationModal/ConfirmationModal'
import { IButtonSettings, TConfirmationState } from './TConfirmationState'
import { useAppDispatch } from '../../../store/hooks'
import { deleteAccountInfo } from '../../client/client'
import { deleteAccountInfoStore } from '../../../Account/store/Action'
import { changeSnackBarState } from '../../../SnackBar/store/Action'
import {setAuthState} from "../../../Settings/Store/Action";

export const DeleteInfoButton: FunctionComponent<IProps> = ({
  backgroundcolor,
  highlight,
  token,
  establishmentId,
}) => {
  const [showDeleteSpinner, setShowDeleteSpinner] = useState<boolean>(false)
  const [confirmationModal, setConfirmationModal] =
    useState<TConfirmationState>('idle')
  const history = useHistory()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const buttonSettings: IButtonSettings = {
    cancel: { text: t('No, don’t cancel'), visible: true },
    confirm: { text: t('Yes'), visible: true },
  }

  useEffect(() => {
    if (confirmationModal !== 'confirm') {
      return
    }
    setShowDeleteSpinner(true)
    deleteAccountInfo(establishmentId, token).then((response) => {
      if (response === 'success') {
        dispatch(deleteAccountInfoStore())
        dispatch(setAuthState('anonymous'))
        return history.push(`/${establishmentId}`)
      }
      return dispatch(
        changeSnackBarState(
          'error',
          t('Could not delete info, please try again or contact the salon.'),
          t('Error'),
          5000
        )
      )
    })
  }, [confirmationModal])

  if (showDeleteSpinner) {
    return <AbsoluteSpinner />
  }

  return (
    <>
      <ConfirmationModal
        description={
          confirmationModal === 'open'
            ? t('Are you sure you want to delete this profile?')
            : null
        }
        title={t('Delete profile data')}
        confirmationState={confirmationModal}
        setConfirmation={setConfirmationModal}
        buttonSettings={buttonSettings}
      />
      <ButtonWithText
        backgroundcolor="transparent"
        activefontcolor={backgroundcolor}
        fontcolor={highlight}
        hoversensitive
        onPress={() => setConfirmationModal('open')}
        isMaxWidth
      >
        {t('Delete my profile')}
      </ButtonWithText>
    </>
  )
}
