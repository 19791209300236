import styled from 'styled-components/native'

import { ProximaNova } from '../../App/Components'

export const ViewNoLastMinutes = styled.View`
    width: 100%;
    background-color: red;
    padding: 30px 0;
    margin-bottom: 25px;
`

export const TextNoLastMinutes = styled(ProximaNova)`
    color: white;
    text-align: center;
`
