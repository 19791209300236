import { URL_API } from 'react-native-dotenv'

import axios, { AxiosResponse } from 'axios'

import {IOptIns} from './IOptIns' 
import Transformer from './Transformer'

export default class Client {
  public static getOptInsFromClient(
    customerUuid: string,
    customerToken: string
  ): Promise<any> {
    const requestOptions: object = {
      method: 'GET',
      url: `${URL_API}/anonymous/customers/opt-in/${customerUuid}/${customerToken}`,
    }

    return axios(requestOptions).then(
      (response: AxiosResponse): IOptIns =>
        Transformer.transform(response.data)
    )
  }

  public static submitOptInToClient(
    customerUuid: string,
    customerToken: string,
    isTransactional: boolean,
    isMarketing: boolean
  ): Promise<any> {
    const submitOptIn: object = {
      method: 'POST',
      url: `${URL_API}/anonymous/customers/opt-in/${customerUuid}/submit`,
      data: {
        token: customerToken,
        is_transactional_optin: isTransactional,
        is_marketing_optin: isMarketing,
      },
    }
    return axios(submitOptIn)
  }
}
