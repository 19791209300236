import { ISettingsState } from '../../../Settings/Store/ISettingsState'

export const isDepositCheck = (
  settings: ISettingsState,
  deposit: number
): boolean => {
  const settingValues = settings.values

  const { isCustomerExisting } = settings
  const { isOnlinePaymentEnabled } = settingValues
  const isDepositForSpecificCustomer: boolean =
    settingValues.depositForSpecificCustomers
  const { isCustomerSpecificDeposit } = settings.user
  const isDepositOnlyForNewCustomers: boolean =
    settingValues.onlineAppointmentsDepositOnlyForNewCustomers

  if (!isOnlinePaymentEnabled || !deposit) {
    return false
  }

  if (isDepositOnlyForNewCustomers && !isCustomerExisting) {
    return true
  }

  if (isDepositForSpecificCustomer && isCustomerSpecificDeposit) {
    return true
  }
  return !isDepositOnlyForNewCustomers && !isDepositForSpecificCustomer
}
