import React from 'react'

import { handleLink } from '../../Util'

import { PoweredBy as CompanyName } from './style'
import { useAppSelector } from '../../../store/hooks'

export const PoweredBy = () => {
  const settings = useAppSelector((state) => state.settings.values)

  if (!settings) {
    return null
  }

  return (
    <CompanyName
      highlight={settings.highlightColor}
      onPress={() => handleLink('https://www.optios.net')}
    >
      Powered by OPTIOS
    </CompanyName>
  )
}
