import { initialUserData, IUserData } from './IUserData'

export const getUserData = (): IUserData => {
    try {
        const userData: IUserData | null = JSON.parse(
            localStorage.getItem('userData')
        )
        if (userData === null) {
            localStorage.setItem('userData', JSON.stringify(initialUserData))
            return initialUserData
        }
        return userData
    } catch (e) {
        localStorage.setItem('userData', JSON.stringify(initialUserData))
        return initialUserData
    }
}
