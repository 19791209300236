import { Reducer } from 'redux'

import { GET_LASTMINUTES_SUCCESS, LASTMINUTES_LOADING } from './Actions'
import { ILastMinuteState } from './ILastMinuteState'

const initialState: ILastMinuteState = {
  loading: false,
}

export const reducer: Reducer<ILastMinuteState> = (
  state: ILastMinuteState = initialState,
  action
): ILastMinuteState => {
  switch (action.type) {
    case LASTMINUTES_LOADING:
      return {
        ...state,
        loading: true,
      }
    case GET_LASTMINUTES_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload,
      }
    default:
      return state
  }
}
