import { IAccountInfo, IAPIAccountInfo } from '../IAccountInfo'

export const accountInfoTransformer = (
  apiObject: IAPIAccountInfo
): IAccountInfo => ({
  firstName: apiObject.first_name,
  lastName: apiObject.last_name,
  cellphone: apiObject.cellphone,
  email: apiObject.email,
  appointmentConfirmation: apiObject.appointment_confirmation,
})
