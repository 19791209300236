import { IApiSettingValues, ISettingValues } from '../ISettings'

export default class Transformer {
  public static transform(apiSettings: IApiSettingValues): ISettingValues {
    return {
      uuid: apiSettings.uuid,
      message: apiSettings.message,
      logo: apiSettings.logo,
      isActive: apiSettings.is_active,
      isPreferredPersonPerActivity: apiSettings.is_preferred_person_per_service,
      isPersonPreferenceMandatory: apiSettings.is_person_preference_mandatory,
      numberOfMomentsIndicatingBusy:
        apiSettings.number_of_moments_indicating_busy,
      onlineAppointmentsExistingCustomersOnly:
        apiSettings.online_appointments_existing_customers_only,
      isOnlinePaymentEnabled: apiSettings.is_online_payment_enabled,
      facebookPixel: apiSettings.facebook_pixel,
      backgroundColor: apiSettings.background_color,
      fontColor: apiSettings.font_color,
      highlightColor: apiSettings.highlight_color,
      opacity: apiSettings.opacity,
      locations:
        typeof apiSettings.locations === 'undefined'
          ? []
          : apiSettings.locations.map((location) => ({
            id: location.id,
            image: location.image,
            name: location.name,
            onlineBookingEnabled: location.online_booking_enabled,
          })),
      locale: apiSettings.locale,
      onlineAppointmentsExistingCustomersOnlyMessage:
        apiSettings.online_appointments_existing_customers_only_message,
      onlineAppointmentsShowWeekdayNames:
        apiSettings.online_appointments_show_weekday_names,
      isUsingNewFreeMomentFinder:
        apiSettings.online_appointments_new_free_moment_finder,
      isSeamlessIntegrated: apiSettings.is_seamless_integrated,
      backgroundImage: apiSettings.background_image,
      isMenuVisible: apiSettings.is_menu_visible,
      isSelectLocation: apiSettings.select_location,
      maxFreeMoments: apiSettings.max_free_moments,
      isShowAllButtonVisible: apiSettings.is_show_all_button_visible,
      isAddExtraActivityVisible: apiSettings.is_extra_services_visible,
      closingDays: apiSettings.days_not_active,
      maxMonthsInAdvance: apiSettings.max_months_in_advance,
      hoursBeforeNotAllowedToMove: apiSettings.hours_before_not_allowed_to_move,
      hoursToCancel: apiSettings.hours_to_cancel,
      isListView: apiSettings.online_appointments_list_view,
      allowNoPreferenceSelection:
        apiSettings.online_appointments_allow_no_preference_selection,
      isMultipleCustomersEnabled: apiSettings.is_multiple_customers_enabled,
      onlineAppointmentsRemarksEnabled:
        apiSettings.online_appointments_remarks_enabled,
      onlineAppointmentsDepositOnlyForNewCustomers:
        apiSettings.online_appointments_deposit_only_for_new_customers,
      showPrice: apiSettings.online_appointments_show_price,
      showDuration: apiSettings.online_appointments_show_duration,
      depositForSpecificCustomers:
        apiSettings.online_appointments_deposit_for_specific_customers,
      onlineAppointmentsNewFreeMomentFinder:
        apiSettings.online_appointments_new_free_moment_finder,
      annulationCondition: apiSettings.annulation_condition,
      giftcardTrackingEnabled: apiSettings.giftcard_tracking_enabled,
      disableCircles: apiSettings.disable_circles,
      disableColor: apiSettings.disable_color,
      canCustomerPayWholeAmount: apiSettings.can_customer_pay_whole_amount,
      onlineWaitingList: apiSettings.online_waiting_list,
      fmfCustomCustomerDurations:
        apiSettings.online_appointments_fmf_apply_custom_customer_activity_durations,
      containerIdGTM: apiSettings.google_tag_manager_container_id,
      onlineAppointmentsFmfFillingConstraint:
        apiSettings.online_appointments_fmf_filling_constraint,
      onlineAppointmentsShowActivitiesWithNoDuration:
        apiSettings.online_appointments_show_activities_with_no_duration,
      onlineAppointmentsFmfResultsChronological: apiSettings.online_appointments_fmf_results_chronological,
      isCertainServiceBlock: apiSettings.online_appointments_certain_services_for_existing_customers_only,
      onlineAppointmentsRequestAddressInfo: apiSettings.online_appointments_request_address_info,
    }
  }
}
