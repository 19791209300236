import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { DeleteInfoButton } from './components/DeleteInfoButton/DeleteInfoButton'
import { useAppDispatch, useAppSelector } from '../store/hooks'
import { BackButton, Logo, ProximaNova, Spinner } from '../App/Components'
import { StyledScrollView } from '../App/Components/AppContainer'
import { useParams } from '../Router'
import { getAccountInfo } from './client/client'
import { setAccountInfo } from '../Account/store/Action'
import { getUserData } from '../App/Util'
import { getUserToken } from '../App/Util/localStorageHelper/userToken/setUserToken'

export const AccountInfo = () => {
  const { t } = useTranslation()
  const { establishmentId } = useParams<{ [key: string]: string }>()
  const dispatch = useAppDispatch()
  const userData = getUserData()
  const token = getUserToken(establishmentId, userData.email)

  const { settings, accountInfo } = useAppSelector((state) => ({
    settings: state.settings.values,
    accountInfo: state.account.accountInfo,
  }))
  const { backgroundColor, highlightColor } = settings

  useEffect(() => {
    if (!accountInfo) {
      getAccountInfo(establishmentId, token).then((data) => {
        dispatch(setAccountInfo(data))
      })
    }
  }, [])

  if (!accountInfo) return <Spinner />

  return (
    <StyledScrollView>
      <Logo />
      <BackButton extraMargin customPath={`/${establishmentId}/account`} />
      <ProximaNova>{accountInfo.firstName}</ProximaNova>
      <ProximaNova>{accountInfo.lastName}</ProximaNova>
      <ProximaNova>{accountInfo.cellphone}</ProximaNova>
      <ProximaNova>{accountInfo.email}</ProximaNova>
      <ProximaNova>{accountInfo.appointmentConfirmation}</ProximaNova>
      <DeleteInfoButton
        backgroundcolor={backgroundColor}
        highlight={highlightColor}
        token={token}
        establishmentId={establishmentId}
      >
        {t('Delete my info')}
      </DeleteInfoButton>
    </StyledScrollView>
  )
}
