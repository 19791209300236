import styled from 'styled-components/native'

export const AppMainView = styled.View<{
    seamless: boolean
}>`
    background-color: ${({ seamless }) =>
    seamless ? '#FFFFFF' : 'rgba(0,0,0,0.4)'};
    height: ${({ seamless }) => (seamless ? '105%' : '100%')};
    justify-content: center;
    box-shadow: rgb(0 0 0 / 40%) 0 0 10px;
`