import { TFunction } from 'i18next'

import { AxiosError, AxiosResponse } from 'axios'
import { RouteComponentProps } from 'react-router'
import {
  formLoadingDone,
  setAppointmentStatus,
} from '../../Appointment/store/Actions'
import { changeSnackBarState } from '../../SnackBar/store/Action'
import { setUserData } from './index'
import { AppDispatch } from '../../store/store'

const bookingErrorLog = (errorResponse: AxiosResponse) => {
  console.error(errorResponse.data)
  console.error(errorResponse.status)
  console.error(errorResponse.headers)
}

const handleBooking400 = (
  dispatch: AppDispatch,
  history: RouteComponentProps['history'],
  t: TFunction,
  establishmentId: string
): void => {
  dispatch(setAppointmentStatus('invalid_email'))
  dispatch(formLoadingDone())
  dispatch(
    changeSnackBarState(
      'error',
      t('Please check if you made a typo or submit a different email.'),
      `${t('Invalid email.')} 😵️`,
      5000
    )
  )
  history.push(`/${establishmentId}/appointment/book`)
}

const handleBooking403 = (
  dispatch: AppDispatch,
  history: RouteComponentProps['history'],
  establishmentId: string
): void => {
  dispatch(setAppointmentStatus('blocked'))
  dispatch(formLoadingDone())
  history.push(`/${establishmentId}/appointment/book/status`)
}

const handleBooking409 = (
  dispatch: AppDispatch,
  history: RouteComponentProps['history'],
  t: TFunction,
  establishmentId: string
): void => {
  dispatch(setAppointmentStatus('fully_booked'))
  dispatch(formLoadingDone())
  dispatch(
    changeSnackBarState(
      'warning',
      t('Unable to book appointment on given date. Please try again.'),
      `${t('Oops')} 🥲`,
      5000
    )
  )
  history.push(`/${establishmentId}/appointment`)
}

const handleBookingBookedError = (dispatch: AppDispatch): void => {
  dispatch(setAppointmentStatus('booked_with_processing_error'))
  dispatch(formLoadingDone())
}

const handleBookingDefaultError = (
  dispatch: AppDispatch,
  history: RouteComponentProps['history'],
  establishmentId: string
): void => {
  dispatch(setAppointmentStatus('contact_support'))
  dispatch(formLoadingDone())
  history.push(`/${establishmentId}/appointment/book/status`)
}

export const bookingFormErrorHandling = (
  establishmentId: string,
  customerEmail: string,
  error: AxiosError,
  dispatch: AppDispatch,
  history: RouteComponentProps['history'],
  t: TFunction
) => {
  if (error.response) {
    // The request was made and the server responded with a status code
    const errorResponse = error.response
    const responseStatusCode: number = errorResponse.status
    bookingErrorLog(errorResponse)
    if (
      responseStatusCode === 400 &&
      errorResponse.data.type === 'invalid_email'
    ) {
      setUserData({ email: customerEmail })
      handleBooking400(dispatch, history, t, establishmentId)
      return null
    }
    if (responseStatusCode === 403) {
      handleBooking403(dispatch, history, establishmentId)
      return null
    }
    if (responseStatusCode === 409) {
      handleBooking409(dispatch, history, t, establishmentId)
      return null
    }
  }
  if (error.message === 'processing_error') {
    handleBookingBookedError(dispatch)
    return null
  }
  handleBookingDefaultError(dispatch, history, establishmentId)
  return null
}
