import styled from 'styled-components/native'

import { ProximaNova } from '../../../../../App/Components'

export const StyledActivityText = styled(ProximaNova)`
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: flex-start;
    width: 100%;
    padding-left: 1px;
`
