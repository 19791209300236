import styled from 'styled-components/native'

import { ProximaNova } from '../../../../App/Components'

export const ActivityMetaText = styled(ProximaNova)<{
  highlightcolor: string
  isShowPrice: boolean
  isShowDuration: boolean
}>`
  font-size: 1em;
  width: 48px;
  margin-left: ${({ isShowDuration, isDurationPresent }) =>
    isShowDuration ? '2em' : isDurationPresent ? '4em' : '8px'};
`

export const ActivityMetaPrice = styled(ProximaNova)<{
  highlightcolor: string
  isShowPrice: boolean
  isShowDuration: boolean
}>`
  font-size: 1em;
  width: 50%;
`

export const ActivityMetaDuration = styled(ProximaNova)<{
  highlightcolor: string
  isShowPrice: boolean
  isShowDuration: boolean
}>`
  font-size: 1em;
  width: 50%;
`
