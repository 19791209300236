import styled from 'styled-components/native'
import { View } from "react-native";
import { ProximaNova } from "../../../App/Components";


export const PriceContainer = styled(View)<{
  fontColor: string
}>`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  
  border: ${({fontColor}) => fontColor ? `1px solid ${fontColor}` : `1px solid #000000`};
  border-radius: 20px;
  padding: 12px;
  max-width: 331px;
  width: 100%;
  margin: 16px 0;
`

export const AmountContainer = styled(View)`
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
`

export const AmountText = styled(ProximaNova)<{
  fontColor: string
}>`
  color: ${({fontColor}) => fontColor || '#000000'};
  font-size: 16px;
  margin-left: 15px;
`

export const Price = styled(ProximaNova)<{
  fontColor: string
}>`
  color: ${({fontColor}) => fontColor || '#000000'};
  font-size: 16px;
  margin-left: 8px;
  font-weight: 600;
`

export const DepositContainer = styled(View)`
  display: flex;
  flex-direction: row;
`