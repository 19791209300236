import React from 'react'
import { useTranslation } from 'react-i18next'

import { ButtonWithText } from '../../App/Components'

import { IProps } from './IProps'
import {
  CardData,
  InnerCardContainer,
  OuterCardContainer,
  StyledText, StyledTitle,
} from './style'
import {useHistory} from "react-router";

export const WorkshopCard: React.FunctionComponent<IProps> = (
  {workshop, fontColor, highlightColor, backgroundColor}
) => {
  const { t } = useTranslation();
  const history = useHistory();

  const bookWorkshop = () => {
    history.push(`workshops/${workshop.uuid}/book`)
  }

  const duration = workshop.endsAt.diff(workshop.startsAt, 'minutes');

  return (
    <OuterCardContainer backgroundcolor={highlightColor}>
      <InnerCardContainer>
        <CardData>
          <StyledTitle>{workshop.title}</StyledTitle>
          {!workshop.description || <StyledText>{workshop.description}</StyledText>}
          <StyledText fontcolor={fontColor}>
            {workshop.startsAt.format('dddd, D MMM YYYY')} {t('at')} {workshop.startsAt.format('LT')}
          </StyledText>
          <StyledText>{t(
            duration >= 120
              ? '{{hours}} hours and {{mins}} minutes'
              : duration >= 60 ? '{{hours}} hours and {{mins}} minutes_one' : '{{amount}} minutes',
            {
              'count': Math.floor(duration / 60),
              'amount': duration,
              'hours': Math.floor(duration / 60),
              'mins': Math.floor(duration % 60)
            }
          )}</StyledText>
        </CardData>
        <ButtonWithText
          backgroundcolor="transparent"
          activefontcolor={backgroundColor}
          fontcolor={highlightColor}
          hoversensitive
          onPress={bookWorkshop}
        >
          {t('Book')}
        </ButtonWithText>
      </InnerCardContainer>
    </OuterCardContainer>
  )
}
