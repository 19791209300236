import React from 'react'

import { useTranslation } from 'react-i18next'
import { ListCard } from '../../../../../App/Components'
import {
  IActivityCategory,
  IExtraActivity,
} from '../../../../Client/IExtraActivity'
import { CategorySection } from '../CategorySection/CategorySection'

import { useAppDispatch, useAppSelector } from '../../../../../store/hooks'
import {
  addActivity,
  deleteActivity,
} from '../../../../../Appointment/store/Actions'
import { ISettingValues } from '../../../../../Settings/ISettings'
import { shadeColor } from '../../../../../App/Util/shadeColor'
import { activityFilter } from '../../../../../App/Util/activityFilter'

type Props = {
  extraActivities: IActivityCategory[]
  settings: ISettingValues
  isExistingCustomer: boolean
}

export const ExtraActivityMap = ({
  extraActivities,
  settings,
  isExistingCustomer,
}: Props) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const appointmentState = useAppSelector((state) => state.appointment)
  const currentCustomerActivities = useAppSelector(
    (state) =>
      state.appointment.customers[appointmentState.currentCustomerIndex]
        .activities
  )

  const toggleActivity = (extraActivity: IExtraActivity) => {
    const isIdInArray = currentCustomerActivities.some(
      (activity) => activity.uuid === extraActivity.uuid
    )
    if (isIdInArray) {
      dispatch(
        deleteActivity(extraActivity, appointmentState.currentCustomerIndex)
      )
    } else {
      dispatch(addActivity(extraActivity))
    }
  }

  if (!extraActivities) {
    return null
  }

  return (
    <>
      {extraActivities
        .filter(
          (category: IActivityCategory) =>
            activityFilter(
              category.activities,
              settings.isCertainServiceBlock,
              isExistingCustomer
            ).length > 0
        )
        .map((category: IActivityCategory) => {
          const categoryActivities = extraActivities.find(
            (oneCategory) => oneCategory.id === category.id
          )
          let activityList: JSX.Element[] = []
          if (categoryActivities) {
            activityList = activityFilter(
              categoryActivities.activities,
              settings.isCertainServiceBlock,
              isExistingCustomer
            ).map((extraActivity: IExtraActivity) => {
              const isActivityActive = currentCustomerActivities.find(
                (activity) => activity.uuid === extraActivity.uuid
              )
              return (
                <ListCard
                  key={`${extraActivity.name}${extraActivity.id}`}
                  type="activity"
                  onPress={() => toggleActivity(extraActivity)}
                  link={undefined}
                  isActivityActive={!!isActivityActive}
                  title={t(`activities;;${extraActivity.name}`)}
                  image={extraActivity.image}
                  price={settings.showPrice ? extraActivity.price : null}
                  description={
                    extraActivity.description
                      ? t(`activities;;${extraActivity.description}`)
                      : undefined
                  }
                  isChild
                />
              )
            })
          }

          return (
            <CategorySection
              key={category.id}
              title={category.name}
              list={activityList}
              fontcolor={settings.fontColor}
              image={category.image}
              bgColor={shadeColor(settings.backgroundColor, -3)}
            />
          )
        })}
    </>
  )
}
