import { RouteComponentProps } from 'react-router-native'
import { IAppointmentState } from '../../Appointment/store/interface/IAppointmentState'
import { IMenuActivity } from '../../Menu/Store/IMenuActivity'

export const filterCustomerActivities = (
  appointment: IAppointmentState,
  establishmentId: string,
  history: RouteComponentProps['history'],
  customerIndex?: number
): IMenuActivity[] | [] => {
  if (typeof appointment.customers === 'undefined') {
    history.replace(`/${establishmentId}`)
    return []
  }

  if (
    typeof customerIndex !== 'undefined' &&
    typeof appointment.customers[customerIndex] === 'undefined'
  ) {
    history.replace(`/${establishmentId}/appointment`)
    return []
  }

  return customerIndex
    ? appointment.customers[customerIndex].activities
    : appointment.customers.map((customer) => customer.activities).flat(1)
}
