import React, {useState} from 'react'
import { useTranslation } from 'react-i18next'

import {ButtonWithText, Spinner} from '../../App/Components'

import { IProps } from './IProps'
import {
  CardData,
  InnerCardContainer,
  OuterCardContainer,
  StyledText, StyledTitle,
} from './style'
import {useAppDispatch} from "../../store/hooks";
import {cancelWorkshop} from "../../Appointment/store/Actions";

export const WorkshopSubscriptionCard: React.FunctionComponent<IProps> = (
  {workshop, token, fontColor, highlightColor, backgroundColor }
) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch()
  const [isCancelling, setIsCancelling] = useState<boolean>(false)

  const onCancelClick = async () => {
    setIsCancelling(true)
    dispatch(cancelWorkshop(
      workshop.uuid,
      workshop.subscriptionUuid,
      token
    ))
  }

  return (
    <OuterCardContainer backgroundcolor={highlightColor}>
      <InnerCardContainer>
        {
          isCancelling
            ? <Spinner />
            : <>
              <CardData>
                <StyledTitle>{workshop.title}</StyledTitle>
                {!workshop.description || <StyledText>{workshop.description}</StyledText>}
                <StyledText fontcolor={fontColor}>
                  {workshop.startsAt.format('dddd, D MMM YYYY')} {t('at')} {workshop.startsAt.format('LT')}
                </StyledText>
              </CardData>
              <ButtonWithText
                backgroundcolor="transparent"
                activefontcolor={backgroundColor}
                fontcolor={highlightColor}
                hoversensitive
                onPress={onCancelClick}
              >
                {t('Cancel')}
              </ButtonWithText>
            </>
        }
      </InnerCardContainer>
    </OuterCardContainer>
  )
}
