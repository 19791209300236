import React from 'react'

import { DisplayActivity } from './Components/DisplayActivity/DisplayActivity'

import { useAppSelector } from '../../../store/hooks'

type Props = {
  customerIndex: number
}

export const DisplayActivities = ({ customerIndex }: Props) => {
  const activities = useAppSelector(
    (state) => state.appointment.customers[customerIndex].activities
  )

  return (
    <>
      {activities.map((activity) => (
        <DisplayActivity
          key={`customerActivity${activity.id}_${customerIndex}`}
          activity={activity}
          customerIndex={customerIndex}
        />
      ))}
    </>
  )
}
