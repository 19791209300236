import styled from 'styled-components/native'

export const LocationsWrapper = styled.View`
  display: flex;
  text-align: center;
  justify-content: center;
  padding: 16px;
  background: #ffffff;
  height: 100%;
  border-radius: 12px;
`
