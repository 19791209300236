import React from 'react'

import { useTranslation } from 'react-i18next'
import { ListCard } from '../../../../../../App/Components'
import { IMenuCategory } from '../../../../../Store/IMenuCategory'
import { useLocation } from '../../../../../../Router'

type Props = {
  currentCategories: IMenuCategory[]
}

export const ListStructureMap = ({ currentCategories }: Props) => {
  const { t } = useTranslation()
  const location = useLocation()
  if (!currentCategories) {
    return null
  }
  return (
    <>
      {currentCategories.map((category: IMenuCategory) => (
        <ListCard
          type="menu"
          key={`${category.name}${category.id}`}
          title={t(`activities;;${category.name}`)}
          link={`${location.pathname}/${category.id}`.replace('//', '/')}
          onPress={undefined}
          image={category.image}
          description={
            category.description
              ? t(`activities;;${category.description}`)
              : undefined
          }
        />
      ))}
    </>
  )
}
