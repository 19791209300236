import React from 'react'
import { useTranslation } from 'react-i18next'

import { DepositText, DepositView } from './style'

interface IProps {
  isShowDepositNotice: boolean
  deposit: number
}

export const DepositNotice = ({ isShowDepositNotice, deposit }: IProps) => {
  const { t } = useTranslation()

  if (!isShowDepositNotice) {
    return null
  }

  return (
    <DepositView>
      <DepositText>
        {t(`warning: this appointment requires a deposit of {{amount}} euro`, {
          amount: deposit,
        })}
      </DepositText>
    </DepositView>
  )
}
