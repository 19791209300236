export const frLU = `**Déclaration de confidentialité OPTIOS**
=========================================

_Version 2022.01_

La présente déclaration de confidentialité s'applique lorsque vous visitez le site web [www.optios.net](http://optios.squarespace.com) ou lorsque vous vous inscrivez sur la plate-forme Optios (ci-après dénommée "Optios"). Elle explique pourquoi la BV OPTIOS (ci-après dénommée "Optios BV", "nous" ou "notre") traite vos données personnelles lorsque vous utilisez nos services.

Contenu

[1 Qui est le contrôleur ?](#_Toc101864129)

[2 Visiter le site web](#_Toc101864130)

[3 Candidats](#_Toc101864131)

[4 Optios](#_Toc101864132)

[4.1 Création d'un compte](#_Toc101864133)

[4.2 Utilisation de l'Optios](#_Toc101864134)

5 Types de données personnelles et sensibles auxquelles l'application accède, qu'elle recueille et qu'elle utilise

[6 Avec qui partageons-nous vos données personnelles ?](#_Toc101864135)

[7 Comment protégeons-nous vos données personnelles ?](#_Toc101864136)

[8 Quels sont vos droits et comment pouvez-vous les exercer ?](#_Toc101864137)

[9 Hyperliens](#_Toc101864138)

1 Qui est le contrôleur ?
---------------------------

Le responsable du traitement de vos données personnelles est :

                  **BV OPTIOS**

                  Antwerpse Steenweg 19

                  9080 Lochristi

                  0629.718.060

Si vous avez des questions sur le traitement de vos données personnelles par Optios BV ou sur le contenu de cette déclaration de confidentialité, veuillez nous contacter à help@optios.net.

Optios BV se réserve le droit de modifier ou de réviser cette déclaration de confidentialité de temps à autre. Si vous êtes enregistré auprès d'Optios, nous vous informerons avant que ces changements ou révisions ne prennent effet.

2 Visiter le site web
-----------------------

Lorsque vous visitez notre site web, des données personnelles peuvent, dans certaines circonstances, être collectées auprès de vous au moyen de cookies (voir notre déclaration sur les cookies).

Notre site web vous propose également différents moyens de partager vos données personnelles avec nous. Par exemple, vous pouvez demander une consultation ou nous contacter au moyen d'un formulaire de contact.

Dans ces cas, nous vous demandons les informations nécessaires pour vous fournir les informations demandées, telles que votre nom et votre adresse électronique, entre autres. Ces traitements sont toujours fondés sur notre intérêt légitime, à savoir répondre à vos différentes demandes.  

Notre site web vous offre également la possibilité de nous contacter via le chatbot. Dans ce cas, les données que vous laissez seront traitées. Ce traitement s'inscrit dans le cadre de notre intérêt légitime à répondre à vos questions.

3 Candidats
-------------

Lorsque vous postulez à un emploi chez Optios, nous recueillons les données suivantes : votre nom, votre adresse électronique, votre numéro de téléphone, éventuellement une photo, votre CV, votre lettre de motivation et toute autre information que vous fournissez dans votre correspondance avec nous.

Optios BV utilisera les informations ci-dessus uniquement à des fins de recrutement interne. Si votre candidature n'est pas retenue, nous conserverons vos données personnelles pendant un maximum de 30 jours. Si nous souhaitons conserver votre candidature dans notre réserve de recrutement, nous vous demanderons toujours votre autorisation. Vos données seront conservées dans notre réserve de recrutement pour une durée maximale de 2 ans. 

4 Optios
----------

### 4.1 Création d'un compte

Lorsque vous créez votre compte dans Optios, votre nom, le nom de votre société et votre adresse électronique sont demandés.

Le traitement de ces données personnelles est nécessaire à l'exécution du contrat entre vous et nous concernant l'utilisation d'Optios. Si vous ne souhaitez pas fournir ces données personnelles à Optios BV, vous ne pourrez pas utiliser Optios car ce traitement est nécessaire pour faciliter votre accès à Optios et son utilisation.

### 4.2 Utilisation de l'Optios

Lorsque vous utilisez Optios, nous recueillons les données personnelles supplémentaires suivantes :

Un numéro de téléphone mobile, tout numéro de téléphone supplémentaire, le sexe et la langue dans laquelle nous communiquons avec vous. Dans le cas d'une entreprise unipersonnelle, nous traitons également votre adresse, votre numéro de compte et votre numéro de TVA.

Nous traitons ces données personnelles pour les raisons suivantes :

§ Pour améliorer continuellement nos services et développer de nouvelles fonctionnalités. Le traitement est fondé sur notre intérêt légitime, notamment pour mieux adapter nos services aux besoins de nos clients ;

§ Assurer la sécurité d'Optios pour vous et tous nos utilisateurs. Ce traitement est fondé sur notre intérêt légitime à fournir une plateforme sécurisée pour toutes les parties concernées ;

§ Suivre la manière dont Optios est utilisé et vérifier si les conditions d'utilisation d'Optios sont violées. Ce traitement est compatible avec la bonne exécution du contrat entre vous et Optios ;

§ La facturation correcte des services fournis.

5 Types de données personnelles et sensibles auxquelles l'application accède, qu'elle recueille et qu'elle utilise
--------------------------------------------------------------------------------------------------------------------

Lors de l'utilisation de la réservation en ligne Optios (app), l'utilisateur est invité à saisir des données personnelles pour confirmer son rendez-vous. A savoir :

 Prénom

 Nom de famille

 Téléphone

 Adresse électronique

Ces données, ainsi que les détails du rendez-vous (date, heure et services) seront stockés dans la base de données d'Optios. Ces données peuvent être utilisées par le propriétaire du salon à des fins de marketing et de communication.

Les données collectées à l'aide de l'application Optios ne seront pas distribuées à des parties autres qu'Optios et le salon lui-même.

6 Avec qui partageons-nous vos données personnelles ?
-------------------------------------------------------

Vos données personnelles seront traitées uniquement aux fins susmentionnées.

Optios BV peut également faire appel à des tiers aux fins susmentionnées, comme pour l'intégration de logiciels (par exemple, des fournisseurs de logiciels) et à des fins d'hébergement (par exemple, des fournisseurs de cloud, des centres de données externes...), mais aussi une agence de recrutement externe.

En aucun cas, vos données personnelles ne seront transmises à des tiers à des fins commerciales.

En outre, vos données ne seront pas transférées vers des pays situés en dehors de l'Espace économique européen. Si cela devait changer à l'avenir, nous nous assurerons que nous avons pris les garanties appropriées et les mesures supplémentaires requises par la législation sur la protection des données.

7 Comment protégeons-nous vos données personnelles ?
------------------------------------------------------

Optios BV prend toutes les mesures techniques et organisationnelles raisonnables pour assurer la protection de vos données personnelles contre les abus, les pertes ou les accès non autorisés. Ainsi, l'accès à vos données personnelles n'est accordé que sur la base du besoin de savoir. Ces mesures sont toujours fondées sur l'état actuel de la technique. Cependant, la sécurité et la sûreté de vos données personnelles sont toujours une obligation de moyens pour laquelle nous ne pouvons offrir de garanties absolues.

8 Quels sont vos droits et comment pouvez-vous les exercer ?
--------------------------------------------------------------

Si vous avez une plainte, une question ou un problème concernant la manière dont nous traitons vos données personnelles, vous pouvez nous contacter via les coordonnées décrites au point 1.

Si vous nous contactez pour exercer vos droits, nous vous répondrons dans un délai de 1 mois. Exceptionnellement, ce délai peut être plus long (jusqu'à un maximum de 3 mois), mais dans ce cas, nous vous en informerons dans un délai d'un mois. La possibilité d'exercer ou non vos droits est toujours liée au traitement lui-même et à sa base juridique.

Si vous souhaitez exercer vos droits, nous vous demandons de toujours décrire clairement quel droit vous souhaitez exercer et comment (par exemple par e-mail, par courrier...) vous souhaitez obtenir les informations demandées. En outre, nous avons le devoir de nous identifier. Nous devons donc toujours vérifier si vous êtes effectivement habilité à recevoir les informations demandées, c'est pourquoi nous pouvons vous demander une copie de votre carte d'identité. 

**Droit d'accès et de correction**

Si vous voulez savoir quelles données personnelles Optios BV traite et à quelles fins, veuillez nous contacter. Nous pouvons vous fournir une copie gratuite des données personnelles que nous traitons. Si vous pensez que vos données personnelles ne sont pas correctes ou actuelles, vous pouvez nous demander de les corriger.

**Droit d'effacement et de restriction**

Vous avez le droit de nous demander de supprimer vos données personnelles. Toutefois, si nous sommes légalement tenus de conserver certaines données personnelles pendant une période plus longue, nous ne pouvons pas accéder à cette demande. Dans ce cas, nous vous en informerons.

Si vous avez de bonnes raisons de le faire, vous pouvez nous demander de restreindre le traitement de vos données personnelles. Par exemple, parce que vous contestez l'exactitude de vos données.

**Droit de rétention**

Si le traitement de vos données personnelles est fondé sur notre intérêt légitime ou s'inscrit dans le cadre de l'accomplissement d'une mission d'intérêt général ou d'autorité publique, vous pouvez vous opposer au traitement de vos données personnelles de manière justifiée.

**Droit à la portabilité des données**

Si nous traitons vos données personnelles sur la base de votre consentement (explicite) ou dans le cadre de l'exécution du contrat entre vous et nous, vous pouvez demander que nous fournissions les données personnelles que vous nous avez fournies sous une forme structurée, couramment utilisée et lisible par machine ou que nous les transférions à un autre responsable du traitement, à condition que le traitement soit effectué par des moyens automatisés.

**Droit de retirer votre consentement**

Lorsque le traitement de vos données personnelles par Optios BV est basé sur votre consentement (explicite), vous avez le droit de retirer ce consentement à tout moment. Toutefois, le retrait de votre consentement n'affecte pas la licéité du traitement antérieur au retrait de votre consentement.

**Droit de porter plainte**

Si vous n'êtes pas satisfait de la manière dont Optios BV traite vos données personnelles ou si vous estimez que vous n'avez pas été suffisamment entendu par Optios BV en ce qui concerne vos plaintes relatives à la protection de la vie privée, vous avez toujours le droit d'introduire une plainte auprès de l'autorité de contrôle belge via :

                  **Autorité de protection des données**

                  Rue de la presse 35                

                  1000 Bruxelles

                  Tél. 02/274.48.00

                  Fax. 02/274.48.35

            [contact@apd-gba.be](mailto:contact@apd-gba.be)

9 Hyperliens
--------------

Ce site web peut contenir des hyperliens vers des sites web d'autres parties. Lorsque vous cliquez sur ces hyperliens, vous quittez le site web d'Optios BV. Optios BV n'est en aucun cas responsable des pratiques de ces sites en matière de confidentialité. Il est toujours recommandé de lire attentivement les déclarations de confidentialité de ces sites web externes.`