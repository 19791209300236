import React, { FunctionComponent } from 'react'

import {BookingError, BookingErrorText} from './style'
import { IProps } from './IProps'

export const ErrorMessage: FunctionComponent<IProps> = ({ message }) => (
  <BookingError>
    <BookingErrorText>{message}</BookingErrorText>
  </BookingError>
)
