import React, { useState } from 'react'

import { GestureResponderEvent, View } from 'react-native'
import { isTouchDevice } from '../../Util'

import { ButtonContainer, ButtonText } from './style'

type Props = {
  backgroundcolor: string
  fontcolor: string
  activefontcolor?: string
  hoversensitive?: boolean
  onPress: (event: GestureResponderEvent) => void
  isInline?: boolean
  customPadding?: string
  disableHoverOnTouchDevice?: boolean
  isMaxWidth?: boolean
  tagManagerId?: string
  isRender?: boolean
}

export const ButtonWithText: React.FC<Props> = (props) => {
  const {
    backgroundcolor,
    fontcolor,
    activefontcolor,
    hoversensitive,
    children,
    isInline = false,
    customPadding,
    disableHoverOnTouchDevice = false,
    isMaxWidth,
    tagManagerId,
    isRender = true,
  } = props
  const [buttonActiveState, setButtonActiveState] = useState<boolean>(false)

  const mouseEnterEvent = () => setButtonActiveState(hoversensitive)
  const mouseLeaveEvent = () => setButtonActiveState(false)

  const disableHover: boolean = disableHoverOnTouchDevice
    ? isTouchDevice()
    : false

  if (!isRender) {
    return null
  }

  return (
    <ButtonContainer
      backgroundcolor={backgroundcolor}
      fontcolor={fontcolor}
      buttonstate={buttonActiveState}
      onMouseEnter={disableHover ? null : mouseEnterEvent}
      onMouseLeave={disableHover ? null : mouseLeaveEvent}
      custompadding={customPadding}
      {...props}
    >
      <View
        id={tagManagerId || null}
        style={{
          display: 'inline',
          width: '100%',
          height: '100%',
          textAlign: 'center',
        }}
      >
        <ButtonText
          fontcolor={fontcolor}
          activefontcolor={activefontcolor || backgroundcolor}
          buttonstate={buttonActiveState}
        >
          {children}
        </ButtonText>
      </View>
    </ButtonContainer>
  )
}
