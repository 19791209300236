import React, {FunctionComponent} from 'react'
import {useTranslation} from 'react-i18next'

import {useDispatch, useSelector} from 'react-redux'
import {ButtonWithText} from '../../../App/Components'
import {useHistory, useParams} from '../../../Router'

import {IProps} from './IProps'
import {IButtonSettings, TConfirmationState,} from '../CancelAppointmentButton/TConfirmationState'
import {IAppState} from '../../../store/IAppState'
import {removeUpdatePreviousAppointment, setUpdatePreviousAppointment,} from '../../../Appointment/store/Actions'

export const MoveAppointmentButton: FunctionComponent<IProps> = ({
  previousAppointment,
  backgroundcolor,
  highlight,
  cantMove,
}) => {
  const {establishmentId} = useParams()
  const history = useHistory()
  const dispatch = useDispatch()
  const {t} = useTranslation()
  const buttonSettings: IButtonSettings = {
    cancel: {text: '', visible: false},
    confirm: {text: 'Confirm', visible: true},
  }

  const {appointment} = useSelector((state: IAppState) => ({
    appointment: state.appointment,
  }))

  const navigateToAppointment = async () => {

    dispatch(removeUpdatePreviousAppointment())
    dispatch(
      setUpdatePreviousAppointment(
        appointment.previousAppointments.find(
          (prevAppointment) => previousAppointment.id === prevAppointment.id
        )
      )
    )

    return history.push(
      `/${establishmentId}/appointment/move/${previousAppointment.id}`
    )
  }

  if (cantMove) {
    return null
  }

  return (
    <ButtonWithText
      backgroundcolor="transparent"
      activefontcolor={backgroundcolor}
      fontcolor={highlight}
      hoversensitive
      onPress={navigateToAppointment}
    >
      {t('Move')}
    </ButtonWithText>
  )
}
