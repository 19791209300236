import styled from 'styled-components/native'
import { ProximaNova } from '../TextComponent'

export const CartButton = styled.TouchableOpacity<{bgColor: string, borderColor: string, isDesktop: boolean}>`
    position: absolute;
    top: ${(props) => props.isDesktop ? 'calc(680px - 68px)' :  'calc(100dvh - 68px)'};
    right: 16px;
    display: flex;
    flex-direction: row;
    background-color:${(props) => props.bgColor};
    padding: 10px 20px;
    border-radius: 5px;
    border-color: ${(props) => props.borderColor};
    border-width: 1px;
`

export const CartButtonText = styled(ProximaNova)<{textColor: string}>`
    color: ${(props) => props.textColor};
    font-size: 18px;
    margin-right: 8px;
`

export const CartButtonAmount = styled(ProximaNova)<{bgColor: string, textColor: string}>`
    color: ${(props) => props.textColor};
    font-size: 13px;
    background-color: ${(props) => props.bgColor};
    padding: 7px;
    border-radius: 50;
    width: 30px;
    height: 30px;
    text-align: center;
`