import React, { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import { ProximaNova } from '../App/Components'
import { StyledScrollView } from '../App/Components/AppContainer'

const Waiting: FunctionComponent = () => {
    const { t } = useTranslation()

    return (
        <StyledScrollView>
            <ProximaNova>
                {t(
                    'The online booking is currently experiencing some heavy load. You are now in the queue. Please wait...'
                )}
            </ProximaNova>
        </StyledScrollView>
    )
}

export default Waiting
