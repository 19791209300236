import Client from '../Client/Client'

import { IApiPerson, IPerson } from '../interface/IPerson'
import PersonTransformer from './Transformer'

export const PERSONS_LOADING = 'PERSONS_LOADING'
export const GET_PERSONS_SUCCESS = 'GET_PERSONS_SUCCESS'

export function getPersons(establishmentId: string) {
  return (dispatch: any) => {
    dispatch(personsLoading())

    Client.getPersons(establishmentId).then((response) => {
      const transformedPersons = response.data.map((apiPerson: IApiPerson) => {
        const transformedData = PersonTransformer.transform(apiPerson)
        return transformedData
      })
      dispatch(getPersonsSuccess(transformedPersons))
    })
  }
}

export function personsLoading() {
  return {
    type: PERSONS_LOADING,
  }
}

function getPersonsSuccess(persons: IPerson[]) {
  return {
    type: GET_PERSONS_SUCCESS,
    persons,
  }
}
