import React, { FunctionComponent } from 'react'

import { Link } from '../../../../../Router'

import { IProps } from './IProps'
import { LogoImg } from './style'

export const ConditionalLink: FunctionComponent<IProps> = ({
  to,
  condition,
  logo,
}) => {
  return (
  <>
    {
      condition ? (
        <Link to={to} style={{ textDecoration: 'none' }}>
          <LogoImg resizeMode="contain" source={{ uri: `${logo}` }} />
        </Link>
      ) : (
        <LogoImg resizeMode="contain" source={{ uri: `${logo}` }} />
      )
    }

  </>
  )
}
   
