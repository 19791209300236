import styled from 'styled-components/native'

import { ProximaNova } from '../TextComponent'

export const StatusContainer = styled.View<{
  success: boolean
}>`
  width: 100%;
  background-color: ${({ success }) => (success ? '#69D321' : '#145cd6')};
  padding-top: 30px;
  padding-bottom: 30px;
  margin-bottom: 25px;
`

export const StatusText = styled(ProximaNova)`
  color: white;
  text-align: center;
  max-width: 332px;
  line-height: 20px;
`
