export const isNativeCheck = () => {
  const standalone = window.navigator['standalone'] as boolean // eslint-disable-line dot-notation
  const userAgent = window.navigator.userAgent.toLowerCase()
  const safari = /safari/.test(userAgent)
  const ios = /iphone|ipod|ipad/.test(userAgent)
  const inFrame = window.self !== window.top
  return (
    (!standalone && !safari) ||
    (ios && !safari) ||
    userAgent.includes('wv') ||
    inFrame
  )
}
