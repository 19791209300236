import { Text, View } from 'react-native'

import styled from 'styled-components/native'

import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'

import { ModalCloseWrapper, ModalMessage } from '../WelcomeMessage/style'

export const DescriptionModalIcon = styled(FontAwesomeIcon)<{
  highlightcolor: string
}>`
  color: ${({ highlightcolor }) => highlightcolor};
  width: 28px;
  height: 28px;
  right: 0;
  top: 0;
  position: absolute;
  margin: 10px;
`
export const DescriptionIconWrapper = styled(Text)`
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 10;
`

export const ModalTitle = styled(ModalMessage)`
  font-size: 22px;
  padding: 1em;
  text-align: center;
  letter-spacing: 0.6px;
`

export const CloseIconWrapper = styled(ModalCloseWrapper)`
  position: absolute;
  right: 20px;
  top: 15px;
  z-index: 10;
  border-radius: 14px;
`

export const ButtonContainer = styled(View)`
  display: flex;
  flex-direction: row;
  justify-content: center;
`
