import { IActivityCategory } from '../Client/IExtraActivity'

import Client from './../Client/Client'

export const GET_EXTRA_ACTIVITIES = 'GET_EXTRA_ACTIVITIES'
export const EXTRA_ACTIVITIES_LOADING = 'EXTRA_ACTIVITIES_LOADING'

export function getExtraActivities(establishmentId: string) {
    return (dispatch: any) => {
        dispatch(extraActivitiesLoading())

        Client.getExtraActivities(establishmentId).then((categories) => {
            dispatch(getExtraActivitiesSuccess(categories))
        })
    }
}

function extraActivitiesLoading() {
    return {
        type: EXTRA_ACTIVITIES_LOADING,
    }
}

function getExtraActivitiesSuccess(categories: IActivityCategory[]) {
    return {
        type: GET_EXTRA_ACTIVITIES,
        payload: {
            categories,
        },
    }
}
