import React, { forwardRef } from 'react'
import { TextInput } from 'react-native'
import { FieldInput } from '../../style'
import { SharedInputProps } from '../SharedInputProps'

export const EmailInput = forwardRef<TextInput, SharedInputProps>(
  (
    {
      onChange,
      onBlur,
      value,
      fontColor,
      onSubmitEditing,
      autoComplete,
      onSubmit,
    },
    ref: React.RefObject<TextInput>
  ) => {
    const regexOnChangeText = (input: string) => {
      const checkIfWhiteSpace = /\s/
      if (checkIfWhiteSpace.test(input)) {
        return
      }
      onChange(input)
    }

    return (
      <FieldInput
        fontcolor={fontColor}
        onBlur={onBlur}
        onChangeText={regexOnChangeText}
        value={value}
        ref={ref}
        onSubmitEditing={onSubmitEditing}
        autoComplete={autoComplete ? 'email' : null}
        keyboardType="email-address"
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            onSubmit({ email: value })
          }
        }}
      />
    )
  }
)
