import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import DropDownPicker from 'react-native-dropdown-picker'

import { faSort } from '@fortawesome/pro-light-svg-icons'

import moment from 'moment'
import { useParams } from '../../../Router'
import { FaIcon } from '../FaIcon/FaIcon'

import { IProps, ISupportedLanguages } from './IProps'
import { useAppSelector } from '../../../store/hooks'

export function LangDropDown(props: IProps) {
  const [open, setOpen] = useState<boolean>(false)
  const { i18n } = useTranslation()
  const prevSelectedLang = localStorage.getItem('prevSelectedLang')
  const [storedLanguage, setStoredLanguage] = useState<string>(
    prevSelectedLang || i18n.language
  )
  const { settings } = useAppSelector((state) => ({
    settings: state.settings.values,
  }))
  const { establishmentId } = useParams()
  const langDropdownItems: ISupportedLanguages[] = [
    { label: 'Nederlands', value: 'nl' },
    { label: 'Français', value: 'fr' },
    { label: 'English', value: 'en' },
    { label: 'Deutsch', value: 'de' },
  ]

  const changeLangFunction = async (language: () => string) => {
    const newLang: string = language()
    i18n.changeLanguage(newLang)
    moment.locale(newLang)
    setStoredLanguage(newLang)
    localStorage.setItem('prevEstablishmentId', establishmentId)
    localStorage.setItem('prevSelectedLang', newLang)
  }

  useEffect(() => {
    const prevEstablishmentId = localStorage.getItem('prevEstablishmentId')
    if (establishmentId !== prevEstablishmentId) {
      if (settings && settings.locale) {
        changeLangFunction(() => settings.locale.substr(0, 2))
      }
    }
  }, [establishmentId, settings])

  const toggleOpen = () => setOpen(!open)

  function CustomArrow() {
    return <FaIcon icon={faSort} color={props.highlightColor} />
  }

  const arrowIconContainerStyle = {
    position: 'absolute',
    right: -6,
    top: -6,
    width: 'fit-content',
  }

  const generalStyle = { margin: 18 }

  const textStyle = { color: props.highlightColor }

  const dropDownContainerStyle = {
    borderColor: props.highlightColor,
    borderWidth: 1,
    padding: 10,
    backgroundColor: props.backgroundColor,
  }

  const labelStyle = {
    color: props.highlightColor,
    textAlign: 'center',
    lineHeight: 1.4,
    paddingRight: 16,
  }

  const containerStyle = {
    width: 'fit-content',
    alignSelf: 'center',
    borderWidth: 1,
    borderRadius: 99,
    borderColor: props.highlightColor,
  }

  return (
    <DropDownPicker
      style={generalStyle}
      labelStyle={labelStyle}
      containerStyle={containerStyle}
      textStyle={textStyle}
      dropDownContainerStyle={dropDownContainerStyle}
      arrowIconContainerStyle={arrowIconContainerStyle}
      items={langDropdownItems}
      open={open}
      setOpen={toggleOpen}
      value={storedLanguage}
      setValue={changeLangFunction}
      showTickIcon={false}
      ArrowDownIconComponent={CustomArrow}
    />
  )
}
