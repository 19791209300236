import { URL_API } from 'react-native-dotenv'

import axios from 'axios'

import { IActivityCategory, IApiActivityCategory } from './IExtraActivity'
import Transformer from './Transformer'

export default class Client {
    public static getExtraActivities(
        establishmentId: string
    ): Promise<IActivityCategory[]> {
        const requestOptions: object = {
            method: 'GET',
            url: `${URL_API}/anonymous/calendar/activity-tree?establishment_id=${establishmentId}`,
        }

        return axios(requestOptions).then((response) => {
            return response.data.map((category: IApiActivityCategory) =>
                Transformer.transformCategory(category)
            )
        })
    }
}
