import React from 'react'

import { ActivityMetaPrice } from '../style'

interface IProps {
  isShowPrice: boolean
  price: number
}

export const ActivityPrice = ({ isShowPrice, price }: IProps) => {
  if (!isShowPrice) {
    return null
  }
  if (!price) {
    return null
  }

  return <ActivityMetaPrice>{`€${price}`}</ActivityMetaPrice>
}
