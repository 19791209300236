import { ISurvey, SurveyClient } from '../Client'

import {
    SURVEY_LOADING,
    SURVEY_SUCCESS,
    SurveyLoading,
    SurveySuccess,
} from './ActionTypes'

export function getSurvey(surveyId: string) {
    return (dispatch: any) => {
        dispatch(getSurveyLoading())
        SurveyClient.getSurveyFromClient(surveyId).then(
            (response: ISurvey[]) => {
                dispatch(getSurveySuccess(response))
            }
        )
    }
}

export function getSurveyLoading(): SurveyLoading {
    return {
        type: SURVEY_LOADING,
    }
}

function getSurveySuccess(survey: any): SurveySuccess {
    return {
        type: SURVEY_SUCCESS,
        payload: {
            survey,
        },
    }
}
