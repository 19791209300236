import styled from 'styled-components/native'

export const StyledText = styled.Text<{
    highlight: string
    font: string
    textAlign: string
    width: string
}>`
    font-family: 'ProximaNova-Regular, proxima-nova, "Helvetica Neue", Helvetica, Arial, sans-serif';
    color: ${({ highlight }) => highlight};
    font-size: 16px;
    line-height: 16px;
    margin: 0;
    padding: 0;
    text-align: ${({ textAlign }) => (textAlign ? textAlign : 'center')};
    align-self: center;
    width: ${({ width }) => (width ? width : 'inherit')};
`
