import styled from 'styled-components/native'

import { ProximaNova } from '../../App/Components'

export const LabelText = styled(ProximaNova)<{
    fontcolor: string
}>`
    color: ${({ fontcolor }) => fontcolor};
    font-size: 12px;
`

export const LabelContainer = styled.View`
    display: flex;
    flex-direction: row;
    align-items: center;
`

export const LabelStatus = styled.View<{
    labelcolor: string
}>`
    width: 25px;
    height: 25px;
    border-radius: 50px;
    margin: 8px;
    background-color: ${({ labelcolor }) => labelcolor};
`
