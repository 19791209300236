import { URL_API } from 'react-native-dotenv'
import axios, { AxiosPromise } from 'axios'
import { Moment } from 'moment'
import { IMenuActivity } from '../../Menu/Store/IMenuActivity'
import { ICustomerBlock } from './interface/ICustomerBlock'
import { getUserToken } from '../../App/Util/localStorageHelper/userToken/setUserToken'

export default class Client {
  public static abortController = new AbortController()

  public static getFreeMoments(
    establishmentId: string,
    monthIndex: number,
    activitiesToSend: ICustomerBlock[]
  ): AxiosPromise {
    const requestOptions: object = {
      method: 'POST',
      url: `${URL_API}/anonymous/calendar/free-moments/${String(monthIndex)}`,
      data: {
        establishment_id: establishmentId,
        customers: activitiesToSend,
      },
    }
    return axios(requestOptions)
  }

  public static getNewFreeMoments(
    establishmentId: string,
    startsAt: Moment,
    endsAt: Moment,
    activitiesToSend: ICustomerBlock[],
    customerToken?: string
  ): AxiosPromise {
    const customerActivities: ICustomerBlock[] = activitiesToSend.map(
      (oneCustomerBlock) => ({
        token: customerToken,
        blocks: oneCustomerBlock.blocks.map((activity) => ({
          activity_id: activity.activity_id,
          category_id: activity.category_id,
          person_id: activity.person_id ? activity.person_id : null,
        })),
      })
    )

    const requestUrl = `${URL_API}/anonymous/calendar/free-moment-finder/${startsAt.format(
      'YYYY-MM-DD'
    )}/${endsAt.format('YYYY-MM-DD')}`
    const params = {
      establishment_id: establishmentId,
      customers: customerActivities,
    }
    const extraConfig = { signal: Client.abortController.signal }

    return axios.post(requestUrl, params, extraConfig).catch((e) => e || null)
  }

  public static postAppointment(
    establishmentId: string,
    activitiesToSend: IMenuActivity[][],
    firstName: string,
    lastName: string,
    phone: string,
    email: string,
    comment: string,
    sendEmail: boolean,
    date: string,
    customerPaysFullAmount: boolean,
    giftcardBarcode?: string,
    street?: string,
    city?: string,
    zip?: string
  ): AxiosPromise {
    const customerToken = getUserToken(establishmentId, email)
    const customerActivities: ICustomerBlock[] = activitiesToSend.map(
      (oneCustomerBlock) => ({
        blocks: oneCustomerBlock.map((activity: IMenuActivity) => ({
          activity_id: activity.id,
          category_id: activity.categoryId,
          person_id: activity.personSelected
            ? activity.personSelected.id
            : null,
        })),
      })
    )

    const CustomerData = {
      first_name: firstName,
      last_name: lastName,
      phone,
      email,
      send_email: sendEmail,
      street,
      city,
      zip,
      token: customerToken,
    }

    const requestOptions: object = {
      method: 'POST',
      url: `${URL_API}/anonymous/appointments`,
      data: {
        establishment_id: establishmentId,
        remark: comment,
        date,
        customer_data: CustomerData,
        customers: customerActivities,
        giftcard_barcode: giftcardBarcode,
        customer_pays_full_amount: customerPaysFullAmount,
      },
    }
    return axios(requestOptions)
  }

  public static addToWaitingList(
    establishmentId: string,
    firstName: string,
    lastName: string,
    phone: string,
    email: string,
    activitiesToSend: IMenuActivity[][],
    comment: string,
    date: string
  ): AxiosPromise {
    const customerToken = getUserToken(establishmentId, email)
    // NO UUID ATM?
    const activityUuids = activitiesToSend
      .map((activities) =>
        activities.map((activity) => ({
          activityUuid: activity.uuid,
          menuId: activity.categoryId,
        }))
      )
      .flat(1)
    const requestOptions: object = {
      method: 'POST',
      url: `${URL_API}/anonymous/waiting-list/create?establishment_id=${establishmentId}`,
      data: {
        activities: activityUuids.map((activity) => ({
          uuid: activity.activityUuid,
        })),
        wanted_at: date,
        remark: comment,
        customer: {
          first_name: firstName,
          last_name: lastName,
          phone: phone,
          email: email,
          token: customerToken,
        },
      },
    }

    return axios(requestOptions)
  }
}
