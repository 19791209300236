export const hexToRgba = (hex: string, alpha = 1) => {
  let hexColor: string = hex.charAt(0) === '#' ? hex.substring(1, 7) : hex

  if (hexColor.length === 3) {
    hexColor = hexColor
      .split('')
      .map((hexVal) => hexVal + hexVal)
      .join('')
  }

  const [r, g, b] = hexColor.match(/\w\w/g).map((x) => parseInt(x, 16))
  return `rgba(${r},${g},${b},${alpha})`
}
