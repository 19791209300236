import React from 'react'

import { Text } from 'react-native'
import styled from 'styled-components/native'
import { TAvailabilityFrontend } from '../../../../Appointment/store/interface/IAppointmentState'
import { ButtonWithText } from '../../../../App/Components'

type Props = {
  backgroundColor: string
  highlightColor: string
  onPress: () => void
  fullDate: boolean
  availability: TAvailabilityFrontend
}

const CalendarHour = ({
  backgroundColor,
  highlightColor,
  onPress,
  fullDate,
  availability,
}: Props) => {
  const fullDateFormat = availability.timeSlot.format('dddd D MMMM YYYY HH:mm')
  const hourAndMinuteFormat = availability.timeSlot.format('HH:mm')

  const StyledText = styled(Text)`
    margin-left: 8px;
    font-size: 13px;
  `

  return (
    <ButtonWithText
      backgroundcolor={backgroundColor}
      fontcolor={highlightColor}
      hoversensitive
      onPress={onPress}
    >
      {fullDate ? fullDateFormat : hourAndMinuteFormat}
      {availability.isBest ? <StyledText>⭐</StyledText> : null}
    </ButtonWithText>
  )
}

export default CalendarHour
