import React from 'react'
import { useTranslation } from 'react-i18next'
import { Linking } from 'react-native'
import { URL_API } from 'react-native-dotenv'
import { useSelector } from 'react-redux'

import {
  BackButton,
  DefaultButtonWithText,
  StatusMessageContainer,
  StatusText,
} from '../../../App/Components'
import { IAppState } from '../../../store/IAppState'

import { useParams, useHistory } from '../../../Router'
import { StyledScrollView } from '../../../App/Components/AppContainer'
import { SuccessGif } from '../../../App/Components/BookingSuccess/style'
import verifiedGif from '../../../../assets/verified.gif'

const AppointmentMovedSuccess = () => {
  const { establishmentId, previousAppointmentId } = useParams()
  const { t } = useTranslation()
  const history = useHistory()

  const options = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }

  const { settings, previousAppointments } = useSelector(
    (state: IAppState) => ({
      settings: state.settings.values,
      previousAppointments: state.appointment.previousAppointments,
    })
  )

  const prevAppointmentPath = `/${establishmentId}/previous-appointments/`
  if (!previousAppointments) {
    history.push(prevAppointmentPath)
    return null
  }

  const downloadAppointment = () => {
    Linking.openURL(`${URL_API}/anonymous/ics/${previousAppointmentId}`)
  }

  const movedDetails = previousAppointments.find(
    (appointment) => appointment.id === Number(previousAppointmentId)
  )
  const hour = movedDetails.date.substr(11, 5)
  const dateFull = new Date(movedDetails.date).toLocaleDateString(
    undefined,
    options
  )

  return (
    <StyledScrollView>
      <BackButton customPath={prevAppointmentPath} />
      <SuccessGif source={verifiedGif} />
      <StatusMessageContainer success>
        <StatusText>{t(`Your appointment has been moved to `)}</StatusText>
        <StatusText>
          {dateFull}
          {t(` at `)}
          {hour}
        </StatusText>
        <DefaultButtonWithText
          onPress={downloadAppointment}
          backgroundcolor={settings.backgroundColor}
          fontcolor={settings.highlightColor}
          hoversensitive
        >
          {t('Add to calendar')}
        </DefaultButtonWithText>
      </StatusMessageContainer>
    </StyledScrollView>
  )
}

export default AppointmentMovedSuccess
