import { View } from 'react-native'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ActivityItem } from '../../../Appointment/style'
import { ActivityName } from '../../../Appointment/Components/DisplayActivities/Components/DisplayActivity/style'

import { useAppSelector } from '../../../store/hooks'
import { AbsoluteSpinner } from '../../../App/Components'
import { EditPersonPerActivity } from '../EditPersonPerActivity/EditPersonPerActivity'
import { IPreviousAppointmentActivity } from '../../../PreviousAppointments/IPreviousAppointment'
import { ISettingValues } from '../../../Settings/ISettings'

type Props = {
  activity: IPreviousAppointmentActivity
  isPersonSelectable: boolean
  settingsValues: ISettingValues
  isPreferredPerson: boolean
}

export const ActivityEdit = ({
  activity,
  settingsValues,
  isPersonSelectable,
  isPreferredPerson,
}: Props) => {
  const { t } = useTranslation()
  const { persons, activities } = useAppSelector((state) => ({
    persons: state.persons,
    activities: state.activities.list,
  }))

  if (
    typeof activities === 'undefined' ||
    typeof persons.persons === 'undefined'
  ) {
    return <AbsoluteSpinner />
  }

  return (
    <View
      style={{
        flexDirection: 'row',
        alignItems: 'baseline',
        flexWrap: 'wrap',
      }}
    >
      <ActivityItem>
        <ActivityName highlightcolor={settingsValues.fontColor}>
          {t(`activities;;${activity.name}`)}
        </ActivityName>
      </ActivityItem>
      {settingsValues.isPreferredPersonPerActivity && isPersonSelectable ? (
        <EditPersonPerActivity
          selectedPerson={activity.person.id ? activity.person.firstName : null}
          highlightcolor={settingsValues.highlightColor}
          activityId={activity.id}
          isPreferredPerson={isPreferredPerson}
        />
      ) : null}
    </View>
  )
}
