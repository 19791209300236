import { URL_API } from 'react-native-dotenv'

import axios, { AxiosPromise } from 'axios'

export const getAccountList = (
  accountToken: string,
  establishmentId: string
): AxiosPromise => {
  const requestObject: object = {
    method: 'GET',
    url: `${URL_API}/anonymous/customers/authentication-info/${accountToken}?establishment_id=${establishmentId}`,
  }

  return axios(requestObject)
}
