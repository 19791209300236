import React from 'react'
import { useTranslation } from 'react-i18next'

import { IProps } from './IProps'
import { LabelContainer, LabelStatus, LabelText } from './style'

export const CircleLabel = ({ labelText, labelColor, fontColor }: IProps) => {
  const { t } = useTranslation()

  return (
    <LabelContainer>
      <LabelStatus labelcolor={labelColor} />
      <LabelText fontcolor={fontColor}>{t(labelText)}</LabelText>
    </LabelContainer>
  )
}
