import React from 'react'
import { useTranslation } from 'react-i18next'
import { CartButton, CartButtonText, CartButtonAmount } from './style'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { personSelectPathCheck } from '../../Util/personSelectPathCheck'
import { useHistory, useParams } from '../../../Router'
import { changeSnackBarState } from '../../../SnackBar/store/Action'
import { isDesktopCheck } from '../../Util/isDesktopCheck'

export const ListStructureCart = () => {
  const { establishmentId } = useParams<{ [key: string]: string }>()
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useAppDispatch()

  const settings = useAppSelector((state) => state.settings.values)
  const currentCustomerIndex = useAppSelector(
    (state) => state.appointment.currentCustomerIndex
  )
  const cartItems = useAppSelector(
    (state) =>
      state.appointment.customers[state.appointment.currentCustomerIndex]
        .activities
  )

  const goToCalendar = () => {
    if (cartItems.length === 0) {
      dispatch(
        changeSnackBarState(
          'warning',
          t('Please select at least one activity'),
          '',
          5000
        )
      )
      return
    }
    const isPersonSelectable = cartItems.some((item) => item.isPersonSelectable)
    const pathUrl = personSelectPathCheck(
      settings.isPersonPreferenceMandatory,
      isPersonSelectable,
      establishmentId,
      currentCustomerIndex
    )
    history.push(pathUrl)
  }

  return (
    <CartButton
      bgColor={settings.backgroundColor}
      borderColor={settings.highlightColor}
      onPress={goToCalendar}
      isDesktop={isDesktopCheck()}
    >
      <CartButtonText textColor={settings.fontColor}>
        {t('Next')}
      </CartButtonText>
      <CartButtonAmount
        bgColor={settings.highlightColor}
        textColor={settings.backgroundColor}
      >
        {cartItems.length}
      </CartButtonAmount>
    </CartButton>
  )
}
