const StyleVariables = {
  colorGrey: 'rgba(0,0,0,0.4)',
  colorWhite: 'DimGray',
  colorGreen: '#00FF00',
  optiosBlue: '#428BE3',
  availableColor: 'rgba(105,211,33,0.5)',
  busyColor: 'rgba(251,176,59,0.5)',
  fullColor: 'rgba(210,68,68,0.5)',
}

interface ICalendarColors {
  availableColor: string
  busyColor: string
  fullColor: string
}

export interface ICalendarStyle extends ICalendarColors {
  markerRadius: number
}

export default StyleVariables
