import styled from 'styled-components/native'
import { ProximaNova } from '../../../App/Components'

export const QuestionContainer = styled.View`
  align-items: center;
  display: flex;
  flex-direction: row;
  margin: 30px;
`

export const QuestionNumber = styled(ProximaNova)<{
  highlight: string
  bgcolor: string
}>`
  text-align: center;
  padding-top: 3px;
  width: 25px;
  height: 25px;
  border-radius: 9px;
  margin-right: 7px;
  background-color: ${({ highlight }) => highlight};
  color: ${({ bgcolor }) => bgcolor};
`

export const Question = styled(ProximaNova)<{ fontcolor: string }>`
  font-weight: bold;
  max-width: 330px;
  color: ${({ fontcolor }) => fontcolor};
`
