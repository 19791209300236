import React from 'react'
import { useTranslation } from 'react-i18next'

import { useHistory, useParams } from '../../../Router'
import { StyledButtonWithText } from '../../style'
import { IExtraActivity } from '../../../ExtraActivity/Client/IExtraActivity'
import { useAppSelector } from '../../../store/hooks'
import {
  IPreviousAppointment,
  PreviousAppointmentActivityPerson,
} from '../../../PreviousAppointments/IPreviousAppointment'

const preferredPersonCheck = (
  appointment: IPreviousAppointment | null
): PreviousAppointmentActivityPerson | null => {
  if (appointment) {
    const preferredPerson = appointment.activities[0].person.isPreferred
      ? appointment.activities[0].person
      : null
    return preferredPerson
  }
  return null
}

type Props = {
  isPersonSelectable: boolean
  moveAppointmentData?: IExtraActivity[]
}

export const SelectPersonForAllActivities = ({
  isPersonSelectable,
  moveAppointmentData,
}: Props) => {
  const { t } = useTranslation()
  const history = useHistory()
  const { establishmentId } = useParams<{ [key: string]: string }>()

  const {
    settings,
    selectedPerson,
    persons,
    customerActivities,
    editAppointmentPerson,
  } = useAppSelector((state) => ({
    settings: state.settings.values,
    selectedPerson: state.appointment.selectedPerson,
    persons: state.persons.persons,
    customerActivities: state.appointment.customers
      .map((customer) => customer.activities)
      .flat(1),
    editAppointmentPerson: preferredPersonCheck(
      state.appointment.editPreviousAppointment
    ),
  }))

  const dataSwitch = moveAppointmentData || customerActivities
  const personNameSwitch = moveAppointmentData
    ? editAppointmentPerson
    : selectedPerson

  const selectPersonNavigation = () =>
    moveAppointmentData
      ? history.push(`/${establishmentId}/edit-person`)
      : history.push('select-person')
  if (!isPersonSelectable) {
    return null
  }
  if (
    typeof settings !== 'undefined' &&
    settings.isPreferredPersonPerActivity
  ) {
    return null
  }
  if (!persons) {
    return null
  }

  const filteredPersons = persons.filter((person) => {
    if (
      !dataSwitch ||
      person.isCabine ||
      !person.isVisibleOnline ||
      !person.isVisibleInCalender
    ) {
      return false
    }
    return dataSwitch.some(
      (activity) =>
        person.activityIds.includes(activity.id) && activity.isPersonSelectable
    )
  })

  if (filteredPersons.length === 0) {
    return null
  }

  return (
    <StyledButtonWithText
      backgroundcolor={settings.backgroundColor + 15}
      fontcolor={settings.fontColor}
      activefontcolor={settings.backgroundColor}
      onPress={selectPersonNavigation}
      hoversensitive
    >
      {personNameSwitch ? personNameSwitch.firstName : t('Choose person')}
    </StyledButtonWithText>
  )
}
