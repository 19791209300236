import styled from 'styled-components/native'

import { ButtonWithText } from '../App/Components'

export const FieldContainer = styled.View``

export const BackToAppointmentWrapper = styled.Text<{
    highlightcolor: string
}>`
    width: 100%;
    text-align: center;
    color: ${({ highlightcolor }) => highlightcolor};
    margin-top: 20px;
`

export const CheckboxWrapper = styled.View`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 8px 35px;
  margin: 32px 0 0 0;
  border-bottom-width: 1px;
  border-bottom-color: #a9a9a9;
  align-items: center;
`

export const LabelWrapper = styled.View`
  margin-left: 8px; 
  display: flex;
  justify-content: space-between;
  flex-direction: inherit;
  width: 93%;
`

export const CheckboxLabel = styled.Text<{
    fontcolor: string
}>`
  justify-content: center;
  font-size: 0.8em;
  color: ${({ fontcolor }) => fontcolor};
`

export const LinkWrapper = styled.Text`
    text-decoration: none;
    color: dodgerblue;
    font-size: 0.8em;
    width: fit-content;
`

export const InputError = styled.Text`
    color: white;
    position: absolute;
    background-color: #d24444;
    border-radius: 3px;
    padding: 3px 5px;
    right: 5px;
    bottom: 35px;
`

export const ExtendedButtonWithText = styled(ButtonWithText)`
    width: 70%;
`
