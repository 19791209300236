import {IWorkshop} from './IWorkshop'
import Client from "../Client/Client";

export const WORKSHOPS_LOADING = 'WORKSHOPS_LOADING'
export const GET_WORKSHOPS_SUCCESS = 'GET_WORKSHOPS_SUCCESS'
export const CANCEL_WORKSHOP = 'CANCEL_WORKSHOP'

export function workshopsLoading() {
  return {
    type: WORKSHOPS_LOADING,
  }
}

function getWorkshopsSuccess(workshops: IWorkshop[]) {
  return {
    type: GET_WORKSHOPS_SUCCESS,
    list: workshops,
  }
}

export function getWorkshops(establishmentId: string) {
  return (dispatch: any) => {
    dispatch(workshopsLoading())

    Client.getWorkshops(establishmentId).then((workshops) => {
      dispatch(getWorkshopsSuccess(workshops))
    })
  }
}
