import { URL_API } from 'react-native-dotenv'

import axios, { AxiosResponse } from 'axios'

import { IMenuCategory } from '../Store/IMenuCategory'
import Transformer from '../Store/Transformer'

export default class Client {
  public static getCategories(establishmentId: string): Promise<any> {
    const requestOptions: object = {
      method: 'GET',
      url: `${URL_API}/anonymous/activity-menu?establishment_id=${establishmentId}`,
    }

    return axios(requestOptions).then(
      (response: AxiosResponse): IMenuCategory[] => Transformer.transform(response.data, establishmentId)

    )
  }
}
