import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  AmountContainer,
  AmountText,
  DepositContainer,
  Price,
  PriceContainer,
} from './style'
import { useAppSelector } from '../../../store/hooks'

type Props = {
  totalPrice: number
  isShowPrice: boolean
  totalDeposit: number
  isShowDeposit: boolean
}

export const TotalPrice = ({
  totalPrice,
  isShowPrice,
  totalDeposit,
  isShowDeposit,
}: Props) => {
  const { t } = useTranslation()
  const settings = useAppSelector((state) => state.settings.values)
  const isTotalZero = totalPrice === 0
  const isDepositZero = totalDeposit === 0

  if (!isShowPrice && !isShowDeposit) {
    return null
  }

  return (
    <PriceContainer fontColor={settings.fontColor}>
      {isShowPrice && isTotalZero === false && (
        <AmountContainer>
          <AmountText>{t('Total amount')} : </AmountText>
          <Price>&euro; {totalPrice.toFixed(2)}</Price>
        </AmountContainer>
      )}
      {isShowDeposit && isDepositZero === false && (
        <DepositContainer>
          <AmountText>{t('Total deposit')} : </AmountText>
          <Price>&euro; {totalDeposit.toFixed(2)}</Price>
        </DepositContainer>
      )}
    </PriceContainer>
  )
}
