import { Dimensions, View } from 'react-native'

import styled from 'styled-components/native'

export const AppContainer = styled(View)<{
  web: boolean
  backgroundcolor: string
  isMobile: boolean
}>`
  ${({ web, backgroundcolor }) => {
    if (web) {
      return `
         border-radius: 5px;
         background-color: ${backgroundcolor};
    `
    } 
    return `
          height: ${Dimensions.get('screen').height};
          width: ${Dimensions.get('screen').width};
          justify-content: center;
          align-content: center;
          border-radius: 5px;
          background-color: ${backgroundcolor};
          `
    
  }}
  shadow-color: #000;
  shadow-offset: {width: 0, height: 1};
  shadow-opacity: 0.4;
  shadow-radius: 15;
  elevation: 1;
  max-height: ${({ isMobile }) => (isMobile ? 'none' : '680px')};
  height: ${({ isMobile }) => (isMobile ? '100vh' : 'auto')};
 `
