import React, { FunctionComponent, useEffect, useRef } from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { CheckBox, Text, TextInput, View } from 'react-native'

import { IProps } from './IProps'
import {Style, StyledText} from './Style'

export const OpenTypeField: FunctionComponent<IProps> = ({
  fontcolor,
  questionId,
  isMultiline,
  control,
  errors,
  inputType,
}) => {
  const { t } = useTranslation()
  const ref = useRef<TextInput>()
  const InputStyle = Style(fontcolor)


  return (
    <View style={InputStyle.inputContainer}>
      <Controller
        control={control}
        name={questionId}
        rules={{
          required: {
            value: true,
            message: 'This field is required.',
          },
        }}
        defaultValue=""
        render={({ onChange, onBlur, value }) =>
          inputType !== 'condition' ? (
            <TextInput
              scrollEnabled
              multiline={isMultiline}
              numberOfLines={isMultiline ? 4 : undefined}
              style={[
                InputStyle.input,
                errors[questionId] ? InputStyle.hasError : null,
              ]}
              onBlur={onBlur}
              onChangeText={onChange}
              value={value}
              ref={ref}
            />
          ) : (
            <View style={InputStyle.conditionWrapper}>
              <CheckBox
                style={InputStyle.checkBox}
                value={value}
                onValueChange={onChange}
              />
              <StyledText fontcolor={fontcolor}>{t('Agree to the conditions above')}</StyledText>
            </View>
          )
        }
      />
      {errors[questionId] && (
        <Text style={InputStyle.inputError}>{errors[questionId].message}</Text>
      )}
    </View>
  )
}
