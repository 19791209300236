import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { faExclamation, faXmark } from '@fortawesome/pro-light-svg-icons'

import { FaIcon } from '../App/Components'
import { setAppointmentStatus } from '../Appointment/store/Actions'
import { IAppState } from '../store/IAppState'

import { changeSnackBarState } from './store/Action'
import {
  SnackBarCentered,
  SnackBarContainer,
  SnackBarInfoContainer,
  SnackFixedContainer,
  SnackFlexText,
  SnackFlexView,
  SnackMessage,
  SnackTitle,
  snackTypeColorSwitch,
} from './style'

export const SnackBar: React.FC = () => {
  const [closeTimeout, setCloseTimeout] = useState(null)

  const dispatch = useDispatch()

  const { snackbar } = useSelector((state: IAppState) => ({
    snackbar: state.snackBar,
  }))

  useEffect(() => {
    beginCloseTimeout()
  }, [snackbar.timeTillUnmount])

  const closeSnackBar = () => {
    clearTimeout(closeTimeout)
    dispatch(changeSnackBarState('unmount', '', '', 0))
    dispatch(setAppointmentStatus(undefined))
  }

  const beginCloseTimeout = () => {
    const { timeTillUnmount } = snackbar
    if (timeTillUnmount > 0 || timeTillUnmount) {
      const timeout = setTimeout(() => closeSnackBar(), timeTillUnmount)
      setCloseTimeout(timeout)
    }
  }

  if (snackbar.snackBarState === 'unmount') {
    return null
  }

  // CAN'T make styled FaIcon (maybe some day)
  const snackIconStyle = {
    width: 24,
    height: 24,
    borderRadius: 50,
    marginRight: 12,
    backgroundColor: snackTypeColorSwitch(snackbar.snackBarState),
    color: 'white',
  }
  const closeIconStyle = { height: 18, width: 18, cursor: 'pointer' }

  return (
    <SnackBarCentered>
      <SnackFixedContainer>
        <SnackBarContainer
          snackType={snackbar.snackBarState}
          onMouseEnter={() => clearTimeout(closeTimeout)}
          onMouseLeave={() => beginCloseTimeout()}
        >
          <SnackFlexView>
            <SnackBarInfoContainer>
              <SnackFlexView>
                <FaIcon icon={faExclamation} cssStyle={snackIconStyle} />
              </SnackFlexView>
              <SnackFlexText>
                <SnackTitle>{snackbar.title}</SnackTitle>
                <SnackMessage>{snackbar.message}</SnackMessage>
              </SnackFlexText>
            </SnackBarInfoContainer>
            <SnackFlexView>
              <FaIcon
                icon={faXmark}
                onClick={() => closeSnackBar()}
                alt="close icon"
                id="close-snackbar-icon"
                cssStyle={closeIconStyle}
              />
            </SnackFlexView>
          </SnackFlexView>
        </SnackBarContainer>
      </SnackFixedContainer>
    </SnackBarCentered>
  )
}
