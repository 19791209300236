import React, { PropsWithChildren, useRef } from 'react'

import { PoweredBy } from '../../PoweredBy/PoweredBy'

import { StyledScroll } from './style'

type Props = {
  autoScroll?: boolean
}

export const StyledScrollView = ({
  autoScroll,
  children,
}: PropsWithChildren<Props>) => {
  const scrollRef = useRef()
  const scrollFunction = () =>
    autoScroll ? scrollRef.current.scrollToEnd({ animated: true }) : null
  return (
    <StyledScroll ref={scrollRef} onContentSizeChange={scrollFunction}>
      {children}
      <PoweredBy />
    </StyledScroll>
  )
}
