import styled from 'styled-components/native'

export const DepositView = styled.View`
    width: 100%;
    background-color: black;
    padding: 30px 0;
    margin-bottom: 25px;
`

export const DepositText = styled.Text`
    color: white;
    text-align: center;
`
