import { IMenuCategory } from "../../Menu/Store/IMenuCategory"

export const menuCategoryFilter = (
  menuCategories: IMenuCategory[],
  isCertainServiceBlock: boolean,
  isExistingCustomer: boolean
):  IMenuCategory[] => menuCategories.filter((category)=> {
  if (!category.isOnline) return false
  if (!isCertainServiceBlock) return true
  if (category.existingCustomersOnly) return isExistingCustomer
  return true
} )