import React from 'react'
import { View } from 'react-native'
import moment from 'moment'
import { PreviousAppointment } from './Components/PreviousAppointment/PreviousAppointment'

import { IPreviousAppointment } from '../../IPreviousAppointment'

type Props = {
  previousAppointments: IPreviousAppointment[]
  hoursToCancel: any
  hoursBeforeNotAllowedToMove: any
}

export const PreviousAppointmentList = ({
  previousAppointments,
  hoursToCancel,
  hoursBeforeNotAllowedToMove,
}: Props) => {
  const maxCancelDate = moment().add(hoursToCancel, 'hours')
  const maxMoveDate = moment().add(hoursBeforeNotAllowedToMove, 'hours')

  return (
    <View>
      {previousAppointments.map((previousAppointment) => {
        const momentDate = moment(previousAppointment.date)

        const isSameDay = momentDate.isSame(maxCancelDate, 'day')
        const lockCancel: boolean = momentDate.isSameOrBefore(
          maxCancelDate,
          isSameDay ? 'hours' : 'day'
        )

        const lockMove: boolean = momentDate.isSameOrBefore(
          maxMoveDate,
          isSameDay ? 'hours' : 'day'
        )

        return (
          <PreviousAppointment
            key={previousAppointment.id}
            previousAppointment={previousAppointment}
            lockCancel={lockCancel}
            lockMove={lockMove}
            momentDate={momentDate}
          />
        )
      })}
    </View>
  )
}
