import React, { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { InlineView } from './style'
import { StyledText } from '../LastMinuteCard/style'
import { IProps } from './IProps'

export const LastMinutePrice: FunctionComponent<IProps> = ({
  isShowPrice,
  fontColor,
  regularTotal,
  lastMinutePrice,
}) => {
  const { t } = useTranslation()

  if (!isShowPrice) {
    return null
  }
  const formattedRegularTotal = Number.isNaN(regularTotal) ? 0 : regularTotal
  const formattedLastMinutePrice = Number.isNaN(lastMinutePrice) ? 0 : lastMinutePrice
  const isLastMinutePrice = formattedLastMinutePrice > 0

  return (
      <InlineView>
        <StyledText fontcolor={fontColor}>{`${t('Price')}: `}</StyledText>
        {formattedLastMinutePrice > formattedRegularTotal || (
            <StyledText fontcolor={fontColor} textDecoration={isLastMinutePrice}>
              {formattedRegularTotal} EUR
            </StyledText>
        )}
        {!isLastMinutePrice || (
            <StyledText fontcolor={fontColor}>
              {` ${formattedLastMinutePrice}`} EUR
            </StyledText>
        )}
      </InlineView>
  )
}
