import {
  IOptIns,
  IAPIOptIns,
} from './IOptIns'

class Transformer {
  public static transform(optIns: IAPIOptIns): IOptIns {
    return {
      customerUuid: optIns.customer_uuid,
      customerToken: optIns.customer_token,
      transactionalOptIn: optIns.transactional_opt_in,
      marketingOptIn: optIns.marketing_opt_in
    }
  }

  public static reverseTransform(optIns: IOptIns): IAPIOptIns {
    return {
      customer_uuid: optIns.customerUuid,
      customer_token: optIns.customerToken,
      transactional_opt_in: optIns.transactionalOptIn,
      marketing_opt_in: optIns.marketingOptIn
    }
  }
}

export default Transformer
