import React from 'react'
import { stringToHsl } from '../../../../Util'
import { TileEmptyImage, TileImageContainer } from './style'

interface IProps {
  imageUrl: string
  title: string
  link: string
}

export const TileImage = ({ imageUrl, title, link }: IProps) => {
  if (!imageUrl) {
    return (
      <TileEmptyImage
        data-url-info={link}
        backgroundColor={stringToHsl(title, 50, 50)}
      />
    )
  }
  return <TileImageContainer data-url-info={link} source={{ uri: imageUrl }} />
}
