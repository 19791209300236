import { Reducer } from 'redux'

import { IMenuActivity } from '../../Menu/Store/IMenuActivity'
import { IPreviousAppointment } from '../../PreviousAppointments/IPreviousAppointment'

import {
  ADD_ACTIVITY,
  ADD_AVAILABILITY,
  ADD_AVAILABILITY_FAIL,
  ADD_CUSTOMER,
  ADD_MENU,
  ADD_NEW_AVAILABILITY,
  ADD_NEW_AVAILABILITY_FAIL,
  ADD_PERSON_TO_APPOINTMENT,
  APPOINTMENT_STATUS,
  AVAILABILITY_LOADING,
  AVAILABILITY_NEW_LOADING,
  DELETE_ACTIVITY,
  DELETE_APPOINTMENT,
  DELETE_CUSTOMER_INDEX,
  DELETE_LAST_ADDED,
  FORM_LOADING,
  FORM_LOADING_DONE,
  REMOVE_PREVIOUS_APPOINTMENTS_STORE,
  SET_CURRENT_CUSTOMER_INDEX,
  SET_DATE,
  SET_PREVIOUS_APPOINTMENTS,
  AVAILABILITY_NEW_LOADING_DONE,
  PREV_APPOINTMENT_MOVED,
  RESET_ACTIVITY,
  SET_UPDATE_PREVIOUS_APPOINTMENT,
  REMOVE_UPDATE_PREVIOUS_APPOINTMENT,
  EDIT_UPDATE_PREVIOUS_APPOINTMENT,
  CANCEL_WORKSHOP_SUBSCRIPTION,
} from './Actions'

import { IAppointmentState, ICustomer } from './interface/IAppointmentState'

const initialState: IAppointmentState = {
  requestId: 0,
  lastAddedCount: 0,
  loading: false,
  currentCustomerIndex: 0,
  customers: [{ activities: [] }],
  editPreviousAppointment: null,
}

export const reducer: Reducer<IAppointmentState> = (
  // eslint-disable-next-line default-param-last
  state: IAppointmentState = initialState,
  action
): IAppointmentState => {
  let newCustomer
  let editPreviousAppointment
  switch (action.type) {
    case RESET_ACTIVITY:
      return initialState
    case ADD_ACTIVITY:
      if (
        state.customers[state.currentCustomerIndex].activities.find(
          (activity) => activity.id === action.activity.id
        )
      ) {
        return state
      }

      newCustomer = {
        ...state.customers[state.currentCustomerIndex],
        activities: [
          ...state.customers[state.currentCustomerIndex].activities,
          action.activity,
        ],
      }
      return {
        ...state,
        lastAddedCount: 1,
        customers: [
          ...state.customers.slice(0, state.currentCustomerIndex),
          newCustomer,
          ...state.customers.slice(state.currentCustomerIndex + 1),
        ],
      }
    case ADD_CUSTOMER:
      return {
        ...state,
        customers: [...state.customers, { activities: [] }],
        currentCustomerIndex: state.customers.length,
      }
    case SET_CURRENT_CUSTOMER_INDEX:
      return {
        ...state,
        currentCustomerIndex: action.customerIndex,
      }
    case ADD_PERSON_TO_APPOINTMENT:
      return {
        ...state,
        selectedPerson: action.person,
        customers: state.customers.map((customer, index) => {
          if (
            typeof action.customerIndex !== 'undefined' &&
            index !== action.customerIndex
          ) {
            return customer
          }

          return {
            ...customer,
            activities: customer.activities.map((activity) => {
              if (!activity.isPersonSelectable) {
                return activity
              }

              // if activity is not the same as selected activity
              if (
                typeof action.activityId !== 'undefined' &&
                activity.id !== action.activityId
              ) {
                return activity
              }

              if (action.person === undefined) {
                return {
                  ...activity,
                  personSelected: null,
                }
              }

              return {
                ...activity,
                personSelected: action.person.activityIds.includes(activity.id)
                  ? action.person
                  : null,
              }
            }),
          }
        }),
      }
    case ADD_MENU: {
      const activities = action.menu.map((activity: IMenuActivity) => {
        const newActivity: IMenuActivity = {
          ...activity,
          categoryId: action.categoryId,
        }

        return newActivity
      })

      return {
        ...state,
        lastAddedCount: activities.length,
        customers: [
          ...state.customers.slice(0, state.currentCustomerIndex),
          {
            activities,
          },
          ...state.customers.slice(state.currentCustomerIndex + 1),
        ],
      }
    }
    case DELETE_LAST_ADDED:
      if (state.lastAddedCount === 0) {
        return state
      }
      newCustomer = {
        ...state.customers[state.currentCustomerIndex],
        activities: state.customers[
          state.currentCustomerIndex
        ].activities.slice(0, -state.lastAddedCount),
      }

      return {
        ...state,
        customers: [
          ...state.customers.slice(0, state.currentCustomerIndex),
          newCustomer,
          ...state.customers.slice(state.currentCustomerIndex + 1),
        ],
        lastAddedCount: 0,
      }
    case DELETE_ACTIVITY: {
      const lastIndex = state.customers[action.payload.customerIndex].activities
        .map((e) => e.uuid === action.payload.activity.uuid)
        .lastIndexOf(true)
      if (lastIndex === -1) {
        return state
      }
      const oldCustomer = state.customers[action.payload.customerIndex]
      newCustomer = {
        ...oldCustomer,
        activities: [
          ...oldCustomer.activities.slice(0, lastIndex),
          ...oldCustomer.activities.slice(lastIndex + 1),
        ],
      }

      const removeCustomerMenu = newCustomer.activities.filter((activity) => {
        if (typeof activity.menuId !== 'undefined') {
          return activity.menuId !== action.payload.activity.categoryId
        }
        return true
      })

      if (removeCustomerMenu !== 'undefined') {
        if (removeCustomerMenu.length !== 0 || state.customers.length === 1) {
          return {
            ...state,
            customers: [
              ...state.customers.slice(0, action.payload.customerIndex),
              { activities: removeCustomerMenu },
              ...state.customers.slice(action.payload.customerIndex + 1),
            ],
            lastAddedCount: 0,
          }
        }
      }
      const filterCustomer: ICustomer[] = state.customers.filter(
        (customer, customerIndex) =>
          action.payload.customerIndex !== customerIndex
      )
      return {
        ...state,
        customers: filterCustomer,
        lastAddedCount: 0,
        currentCustomerIndex: 0,
      }
    }
    case AVAILABILITY_LOADING:
      return {
        ...state,
        loading: true,
      }
    case ADD_AVAILABILITY:
      return {
        ...state,
        availability: action.availability,
        loading: false,
      }
    case ADD_AVAILABILITY_FAIL:
      return {
        ...state,
        loading: false,
      }
    case AVAILABILITY_NEW_LOADING:
      return {
        ...state,
        loading: true,
        availability: [],
        requestId: action.meta.requestId,
      }
    case AVAILABILITY_NEW_LOADING_DONE:
      if (state.requestId === action.meta.requestId) {
        return {
          ...state,
          loading: false,
        }
      }
      return state
    case ADD_NEW_AVAILABILITY:
      if (state.requestId === action.meta.requestId) {
        return {
          ...state,
          availability: [...state.availability, ...action.availability],
          loading: false,
        }
      }
      return state

    case ADD_NEW_AVAILABILITY_FAIL:
      if (state.requestId === action.meta.requestId) {
        return {
          ...state,
          loading: false,
        }
      }
      return state
    case SET_DATE:
      return {
        ...state,
        date: action.date,
      }
    case SET_PREVIOUS_APPOINTMENTS:
      return {
        ...state,
        previousAppointments: action.appointments,
        subscribedWorkshops: action.workshops,
      }
    case REMOVE_PREVIOUS_APPOINTMENTS_STORE:
      return {
        ...state,
        previousAppointments: undefined,
      }
    case PREV_APPOINTMENT_MOVED:
      return {
        ...state,
        previousAppointments: [
          ...state.previousAppointments.map((prevAppointment) => {
            if (
              prevAppointment.id.toString() !==
              action.payload.updatedAppointment.id.toString()
            ) {
              return prevAppointment
            }
            return action.payload.updatedAppointment
          }),
        ],
      }
    case DELETE_APPOINTMENT:
      return {
        ...state,
        previousAppointments: [
          ...state.previousAppointments.filter(
            (appointment: IPreviousAppointment) =>
              appointment.id !== action.appointmentId
          ),
        ],
      }
    case FORM_LOADING:
      return {
        ...state,
        loading: true,
      }
    case FORM_LOADING_DONE:
      return {
        ...state,
        loading: false,
      }
    case APPOINTMENT_STATUS:
      return {
        ...state,
        appointmentStatus: action.payload.appointmentStatus,
      }

    case DELETE_CUSTOMER_INDEX:
      if (state.customers.length === 1) {
        return {
          ...state,
          customers: [{ activities: [] }],
          currentCustomerIndex: 0,
        }
      }
      return {
        ...state,
        customers: [
          ...state.customers.slice(0, action.payload.customerIndex),
          ...state.customers.slice(action.payload.customerIndex + 1),
        ],
        currentCustomerIndex: action.payload.customerIndex - 1,
      }
    case SET_UPDATE_PREVIOUS_APPOINTMENT:
      return {
        ...state,
        editPreviousAppointment: action.payload.previousAppointment,
      }
    case REMOVE_UPDATE_PREVIOUS_APPOINTMENT:
      return {
        ...state,
        editPreviousAppointment: null,
      }
    case EDIT_UPDATE_PREVIOUS_APPOINTMENT:
      editPreviousAppointment = state.editPreviousAppointment
      return {
        ...state,
        editPreviousAppointment: {
          ...editPreviousAppointment,
          activities: editPreviousAppointment.activities.map((activity) => {
            if (!action.payload.activityId) {
              return {
                ...activity,
                person: {
                  ...activity.person,
                  id: action.payload.person.id,
                  firstName: action.payload.person.firstName,
                },
              }
            }
            if (activity.id === action.payload.activityId) {
              return {
                ...activity,
                person: {
                  ...activity.person,
                  id: action.payload.person
                    ? action.payload.person.id
                    : action.payload.person,
                  firstName: action.payload.person
                    ? action.payload.person.firstName
                    : action.payload.person,
                  isPreferred:  !!action.payload.person,
                },
              }
            }
            return activity
          }),
        },
      }
    case CANCEL_WORKSHOP_SUBSCRIPTION: {
      if (!state.subscribedWorkshops) {
        return state
      }

      // eslint-disable-next-line no-case-declarations
      const workshopIndex: number = state.subscribedWorkshops.findIndex(
        (workshop) => workshop.uuid === action.payload.workshopUuid
      )

      if (workshopIndex === -1) {
        return state
      }

      return {
        ...state,
        subscribedWorkshops: [
          ...state.subscribedWorkshops.slice(0, workshopIndex),
          ...state.subscribedWorkshops.slice(workshopIndex + 1),
        ],
      }
    }

    default:
      return state
  }
}
