import React from 'react'

import { Text } from 'react-native'
import { useTranslation } from 'react-i18next'
import { useParams } from '../../../Router'

import { EmailCheck } from './Components/EmailCheck/EmailCheck'
import { VerificationCheck } from './Components/VerificationCheck/VerificationCheck'
import { IProps } from './IProps'
import { useAppDispatch } from '../../../store/hooks'
import { changeSnackBarState } from '../../../SnackBar/store/Action'
import { setAuthState } from '../../../Settings/Store/Action'
import { removeUserToken } from '../../../App/Util/localStorageHelper/userToken/setUserToken'

export const AuthenticationField = ({
  authTarget,
  userData,
  backgroundcolor,
  fontcolor,
  highlight,
  authState,
  errorMessage,
}: IProps) => {
  const { t } = useTranslation()
  const { establishmentId } = useParams<{ [key: string]: string }>()
  const dispatch = useAppDispatch()

  switch (authState) {
    case 'verificationCheck':
      return (
        <VerificationCheck
          fontColor={fontcolor}
          backgroundColor={backgroundcolor}
          establishmentId={establishmentId}
        />
      )
    case 'authenticated':
      return <Text>Welcome to your account</Text>
    case 'error': {
      removeUserToken(establishmentId, userData.email)
      dispatch(
        changeSnackBarState(
          'error',
          t(errorMessage),
          `${t('Invalid request.')} 😵️`,
          5000
        )
      )
      dispatch(setAuthState('emailCheck', undefined, undefined))
      return null
    }
    case 'emailCheck':
    default:
      return (
        <EmailCheck
          fontcolor={fontcolor}
          backgroundcolor={backgroundcolor}
          highlight={highlight}
          establishmentId={establishmentId}
          userData={userData}
          authTarget={authTarget}
        />
      )
  }
}
