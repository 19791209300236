import React from 'react'
import {
  Control,
  FieldName,
  FieldValues,
  SetFieldValue,
  SetValueConfig,
  useController,
} from 'react-hook-form'
import { CheckBox, Text, View } from 'react-native'

import { Style } from './Style'

type Props = {
  fontcolor: string
  questionId: string
  isMultipleChoice: boolean
  choices: string[]
  control: Control<FieldValues>
  setValue: (
    name: FieldName<FieldValues>,
    value: SetFieldValue<FieldValues>,
    config?: SetValueConfig
  ) => void
}

export const ChoiceTypeField = ({
  fontcolor,
  questionId,
  isMultipleChoice,
  choices,
  control,
  setValue,
}: Props) => {
  const InputStyle = Style(fontcolor)
  const sortedChoices = [...choices].sort()
  const { field } = useController({
    name: questionId,
    control,
    defaultValue: isMultipleChoice ? [] : '',
  })

  const handleValueChange = (isChecked: boolean, choiceValue: string) => {
    let updatedValue
    if (isMultipleChoice) {
      if (isChecked) {
        updatedValue = [...field.value, choiceValue]
      } else {
        updatedValue = field.value.filter((item) => item !== choiceValue)
      }
    } else {
      updatedValue = isChecked ? choiceValue : ''
    }
    field.onChange(updatedValue)
    setValue(questionId, updatedValue)
  }

  const choiceList = sortedChoices.map((choice) => {
    const choiceValue = choice.toLowerCase()

    return (
      <View style={InputStyle.inputContainer}>
        <CheckBox
          style={InputStyle.input}
          onValueChange={(isChecked) =>
            handleValueChange(isChecked, choiceValue)
          }
          value={field.value.includes(choiceValue)}
        />
        <Text style={InputStyle.optionText}>{choice}</Text>
      </View>
    )
  })

  return <View>{choiceList}</View>
}
