import styled from 'styled-components/native'
import { ProximaNova, ProximaNovaBold } from '../TextComponent'

export const NotificationContainer = styled.View`
  width: 100%;
  background-color: #fbb03b;
  padding: 30px 0;
  margin: 20px 0;
`
export const NotificationLink = styled(ProximaNovaBold)`
  color: purple;
  text-align: center;
`

export const NotificationText = styled(ProximaNova)`
  color: white;
  text-align: center;
  margin: 0 0 25px 0;
  padding: 0 30px;
  line-height: 23px;
`

export const NotificationTextBold = styled(ProximaNovaBold)`
  padding: 0 4px;
  color: white;
`

export const NotificationTitle = styled(ProximaNova)`
  color: white;
  text-align: center;
  font-size: 33px;
  font-weight: normal;
  margin: 0 0 25px 0;
`
