import { Reducer } from 'redux'

import { IPersonState } from '../interface/IPersonState'
import { initialState } from '../interface/IState'

import { GET_PERSONS_SUCCESS, PERSONS_LOADING } from './Actions'

export const reducer: Reducer<IPersonState> = (
  state: IPersonState = initialState,
  action: any
): IPersonState => {
  switch (action.type) {
    case PERSONS_LOADING:
      return {
        ...state,
        loading: true,
      }
    case GET_PERSONS_SUCCESS:
      return {
        ...state,
        loading: false,
        persons: action.persons,
      }
    default:
      return state
  }
}
