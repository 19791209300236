import {
    BrowserRouter as Router,
    Link,
    Redirect,
    Route,
    Switch,
    useHistory,
    useLocation,
    useParams,
    withRouter,
} from 'react-router-dom'

/*
 * Remove Platform specific exports :/
 */
export default {
    Link,
    Route,
    Redirect,
    Router,
    Switch,
    withRouter,
    useLocation,
    useParams,
    useHistory,
}
export {
    Link,
    Route,
    Redirect,
    Router,
    Switch,
    withRouter,
    useLocation,
    useParams,
    useHistory,
}
