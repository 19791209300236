import styled from 'styled-components/native'

import { ButtonWithText } from '../App/Components'

export const StyledImage = styled.Image`
  padding: 0 1.5em;
  margin: 1.5em auto 0 auto;
  max-width: 380px;
  width: 75%;
  height: 100px;
  align-self: center;
`

export const StyledContainer = styled.View<{
  highlightcolor: string
}>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 8px 0;
  background-color: ${({ highlightcolor }) => highlightcolor + 15};
`

export const ServiceTitle = styled.Text<{ fontcolor: string }>`
  text-align: center;
  padding: 0 1.5em;
  margin: 1.5em auto 1.5em;
  max-width: 380px;
  align-self: center;
  color: ${({ fontcolor }) => fontcolor};
`

export const AddActivityContainer = styled.View`
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
`

export const ActivityItem = styled.Text`
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  align-items: baseline;
`

export const StyledButtonWithText = styled(ButtonWithText)`
  padding: 6px 60px;
`
