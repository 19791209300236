import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { ScrollView } from 'react-native'

import { faTimes } from '@fortawesome/pro-light-svg-icons'

import { DefaultButtonWithText } from '../../ButtonWithText/style'
import { FaIcon } from '../../FaIcon/FaIcon'
import { ModalMessage, StyledModal } from '../../WelcomeMessage/style'

import { ButtonContainer, CloseIconWrapper, ModalTitle } from '../style'
import {
  IButtonSettings,
  TConfirmationState,
} from '../../../../PreviousAppointments/Components/CancelAppointmentButton/TConfirmationState'
import { useAppSelector } from '../../../../store/hooks'

type Props = {
  title: string
  description: string
  confirmationState: TConfirmationState
  setConfirmation: (state: TConfirmationState) => void
  buttonSettings?: IButtonSettings
}

const defaultButtonSettings: IButtonSettings = {
  cancel: { text: 'Cancel', visible: true },
  confirm: { text: 'Confirm', visible: true },
}

export const ConfirmationModal: React.FC<Props> = ({
  title,
  description,
  confirmationState,
  setConfirmation,
  buttonSettings = defaultButtonSettings,
}) => {
  const { t } = useTranslation()
  const settings = useAppSelector((state) => state.settings.values)

  const closeModal = useCallback(() => {
    setConfirmation('idle')
  }, [])

  if (confirmationState !== 'open') {
    return null
  }

  return (
    <StyledModal
      backgroundcolor={settings.backgroundColor}
      isVisible={confirmationState === 'open'}
      animationIn="slideInDown"
      animationOut="slideOutUp"
      onBackdropPress={closeModal}
      swipeDirection="down"
    >
      <ScrollView>
        <ModalTitle highlightcolor={settings.highlightColor}>
          {t(`activities;;${title}`)}
        </ModalTitle>
        <ModalMessage highlightcolor={settings.highlightColor}>
          {t(`activities;;${description}`)}
        </ModalMessage>
        <ButtonContainer>
          <DefaultButtonWithText
            customWidth="44%"
            disableHoverOnTouchDevice
            onPress={() => setConfirmation('idle')}
            fontcolor={settings.highlightColor}
            backgroundcolor={settings.backgroundColor}
            hoversensitive
            isRender={buttonSettings.cancel.visible}
          >
            {t(buttonSettings.cancel.text)}
          </DefaultButtonWithText>
          <DefaultButtonWithText
            customWidth="44%"
            disableHoverOnTouchDevice
            onPress={() => setConfirmation('confirm')}
            fontcolor={settings.highlightColor}
            backgroundcolor={settings.backgroundColor}
            hoversensitive
            isRender={buttonSettings.confirm.visible}
          >
            {t(buttonSettings.confirm.text)}
          </DefaultButtonWithText>
        </ButtonContainer>
      </ScrollView>
      <CloseIconWrapper onPress={closeModal}>
        <FaIcon size="lg" icon={faTimes} color={settings.highlightColor} />
      </CloseIconWrapper>
    </StyledModal>
  )
}
