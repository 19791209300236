import React, { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { useHistory, useParams } from '../../../Router'
import { IAppState } from '../../../store/IAppState'
import { StyledScrollView } from '../AppContainer'

import { BookingStatusContainer, StatusText } from './style'
import { TAppointmentStatusText } from './TStatus'
import { Logo } from '../Logo/Logo'

export const BookingStatus: FunctionComponent = () => {
  const { establishmentId } = useParams()
  const history = useHistory()

  const { t } = useTranslation()

  const { appointmentStatus } = useSelector((state: IAppState) => ({
    appointmentStatus: state.appointment.appointmentStatus,
  }))

  if (typeof appointmentStatus === 'undefined') {
    history.push(`/${establishmentId}`)
    return null
  }

  const statusMessage: TAppointmentStatusText = {
    blocked: t('Unable to book appointment. Please contact us by phone.'),
    contact_support: t('Oops something went wrong'),
  }

  return (
    <StyledScrollView>
      <Logo />
      <BookingStatusContainer status={appointmentStatus}>
        <StatusText>{statusMessage[appointmentStatus]}</StatusText>
      </BookingStatusContainer>
    </StyledScrollView>
  )
}
