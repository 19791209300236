import React from 'react'
import { useTranslation } from 'react-i18next'
import { ActivityIndicator, Text, View } from 'react-native'

export const AbsoluteSpinner: React.FunctionComponent = () => {
    const { t } = useTranslation()
    return (
        <View
            style={{
                alignContent: 'center',
                justifyContent: 'center',
                position: 'absolute',
                zIndex: 999,
                backgroundColor: 'rgba(255, 255, 255, 0.2)',
                width: '100%',
                height: '100%',
                borderRadius: 15,
            }}
        >
            <ActivityIndicator
                size="large"
                style={{ marginBottom: 20, marginTop: 20 }}
            />
            <Text
                style={{
                    textAlign: 'center',
                    marginBottom: 20,
                }}
            >
                {t('loading...')}
            </Text>
        </View>
    )
}
