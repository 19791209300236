import styled from 'styled-components/native'
import {TouchableOpacity} from 'react-native'
import { ProximaNova, ProximaNovaBold } from '../../App/Components'

export const MessageWrapper = styled.View`
  display: flex;
  justify-content: center;
  margin: 2em;
  padding: 1em;
  text-align: center;
`

export const MessageText = styled.Text`
  color: ${({ fontcolor }) => fontcolor};
  font-size: 13px;
`

export const MessageTextStrong = styled.Text`
  font-weight: bold;
`
export const FormHeading = styled.View`
  display: flex;
  flex-wrap: wrap;
  margin: 25px;
`

export const FormTitle = styled(ProximaNovaBold)<{ fontcolor: string }>`
  font-size: 20px;
  color: ${({ fontcolor }) => fontcolor};
`

export const FormDescription = styled(ProximaNova)<{ fontcolor: string }>`
  font-size: 13px;
  font-style: italic;
  color: ${({ fontcolor }) => fontcolor};
`

export const FormButton = styled(TouchableOpacity)<{
  highlight: string
  bgcolor: string
}>`
  justify-content: center;
  align-self: center;
  background-color: ${({ bgcolor }) => bgcolor};
  color: ${({ highlight }) => highlight};
  border-color: ${({ highlight }) => highlight};
  border-width: 2px;
  text-align: center;
  padding: 10px 70px;
  border-radius: 30px;
  margin-top: 14px;
  margin-bottom: 28px;
`

export const FormButtonText = styled(ProximaNova)<{
  highlight: string
}>`
  color: ${({ highlight }) => highlight};
  text-align: center;
`
