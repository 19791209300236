import React, { useRef } from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { hexToRgba } from '../../Util'

import {
  FieldInput,
  FieldInputContainer,
  InputError,
  FieldLabel,
} from './style'
import { PhoneInput } from './components/PhoneInput/PhoneInput'
import { EmailInput } from './components/EmailInput/EmailInput'
import { AddressInput } from './components/AddressInput/AddressInput'
import { CityInput } from './components/CityInput/CityInput'
import { ZipInput } from './components/ZipInput/ZipInput'

type TAutoComplete =
  | 'additional-name'
  | 'address-line1'
  | 'address-line2'
  | 'cc-number'
  | 'country'
  | 'current-password'
  | 'email'
  | 'family-name'
  | 'given-name'
  | 'honorific-prefix'
  | 'honorific-suffix'
  | 'name'
  | 'new-password'
  | 'off'
  | 'one-time-code'
  | 'postal-code'
  | 'street-address'
  | 'tel'
  | 'username'

type Props = {
  fontColor?: string
  inputRef: string
  inputLabel: string
  control: any
  rules: object
  error?: string
  fieldType?: 'default' | 'email' | 'phone' | 'street' | 'city' | 'zip'
  autoComplete?: TAutoComplete
  onSubmit?: (data: { email: string }) => void
}

export const InputField = ({
  inputRef,
  inputLabel,
  fontColor = null,
  control,
  rules,
  error,
  fieldType = 'default',
  autoComplete = 'off',
  onSubmit,
}: Props) => {
  const { t } = useTranslation()
  const ref = useRef(null)

  const fadedFontColor: string = hexToRgba(fontColor, 0.6)

  const onPressFieldLabel = () => {
    if (ref.current) {
      ref.current.focus()
    }
  }

  const skipLabelFocus = () => {
    if (ref.current) {
      ref.current.focus()
    }
  }

  const renderFieldInputs = ({ onChange, onBlur, value }) => {
    switch (fieldType) {
      case 'phone':
        return (
          <PhoneInput
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            fontColor={fontColor}
          />
        )
      case 'email':
        return (
          <EmailInput
            fontColor={fontColor}
            onBlur={onBlur}
            value={value}
            ref={ref}
            onSubmitEditing={() => ref}
            onChange={onChange}
            autoComplete={autoComplete}
            onSubmit={onSubmit}
          />
        )
      case 'street':
        return (
          <AddressInput
            fontColor={fontColor}
            onBlur={onBlur}
            value={value}
            ref={ref}
            onSubmitEditing={() => ref}
            onChange={onChange}
            autoComplete={autoComplete}
            onSubmit={onSubmit}
          />
        )
      case 'city':
        return (
          <CityInput
            fontColor={fontColor}
            onBlur={onBlur}
            value={value}
            ref={ref}
            onSubmitEditing={() => ref}
            onChange={onChange}
            autoComplete={autoComplete}
            onSubmit={onSubmit}
          />
        )
      case 'zip':
        return (
          <ZipInput
            fontColor={fontColor}
            onBlur={onBlur}
            value={value}
            ref={ref}
            onSubmitEditing={() => ref}
            onChange={onChange}
            autoComplete={autoComplete}
            onSubmit={onSubmit}
          />
        )
      case 'default':
      default:
        return (
          <FieldInput
            fontcolor={fontColor}
            onBlur={onBlur}
            value={value}
            ref={ref}
            onSubmitEditing={() => ref}
            onChange={onChange}
            autoComplete={autoComplete}
          />
        )
    }
  }

  return (
    <FieldInputContainer>
      <FieldLabel
        fontcolor={fadedFontColor}
        onPress={onPressFieldLabel}
        onFocus={skipLabelFocus}
      >
        {t(inputLabel)}
      </FieldLabel>
      <Controller
        defaultValue=""
        control={control}
        name={inputRef}
        rules={rules}
        render={renderFieldInputs}
      />
      {error ? <InputError showError={!!error}>{t(error)}</InputError> : null}
    </FieldInputContainer>
  )
}
