import React, { CSSProperties } from 'react'
import { Platform } from 'react-native'

import { FontAwesomeIcon as FontAwesomeReact } from '@fortawesome/react-fontawesome'
import { FontAwesomeIcon as FontAwesomeNative } from '@fortawesome/react-native-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { IconDefinition } from '@fortawesome/pro-light-svg-icons'

interface IProps {
  icon: IconDefinition
  cssStyle?: CSSProperties
  testID?: any
  [key: string]: any
}

export const FaIcon = ({ icon, cssStyle, testID, ...props }: IProps) => {
  if (Platform.OS === 'web') {
    return (
      <FontAwesomeReact style={cssStyle} icon={icon as IconProp} {...props} />
    )
  }
  // TODO fix the native variant of FA icons
  return (
    <FontAwesomeNative style={cssStyle} icon={icon as IconProp} {...props} />
  )
}
