import { URL_API } from 'react-native-dotenv'

import axios from 'axios'

export const checkGiftCard = (barcode: string, establishmentId: string) => {
  const requestOptions: object = {
    method: 'POST',
    url: `${URL_API}/anonymous/register/giftcards/lookup`,
    data: {
      establishment_id: establishmentId,
      barcode: barcode
    },
  }
  return axios(requestOptions)
}