import { Reducer } from 'redux'

import { EXTRA_ACTIVITIES_LOADING, GET_EXTRA_ACTIVITIES } from './Actions'
import { initialState } from './initialState'
import { IActivityState } from './IState'

export const reducer: Reducer<IActivityState> = (
    state: IActivityState = initialState,
    action: any
): IActivityState => {
    switch (action.type) {
        case EXTRA_ACTIVITIES_LOADING:
            return {
                ...state,
                loading: true,
            }
        case GET_EXTRA_ACTIVITIES:
            return {
                ...state,
                loading: false,
                list: action.payload.categories,
            }
        default:
            return state
    }
}
