import React, { FunctionComponent } from 'react'
import { useSelector } from 'react-redux'

import { IAppState } from '../../../../store/IAppState'

import { StyledText as TextWrapper } from './style'

export const ProximaNova: FunctionComponent = (props) => {
  const { settings } = useSelector((state: IAppState) => ({
    settings: state.settings.values,
  }))

  // @ts-ignore
  const { children, highlight } = props

  if (typeof settings === 'undefined') {
    return null
  }

  return (
    <TextWrapper {...props} highlight={highlight || settings.highlightColor}>
      {children}
    </TextWrapper>
  )
}
