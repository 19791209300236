import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ScrollView } from 'react-native'
import { useSelector } from 'react-redux'

import {
  faExclamationCircle,
  faTimes,
} from '@fortawesome/pro-regular-svg-icons'

import { validateEmailReset } from '../../../Settings/Store/Action'
import { IAppState } from '../../../store/IAppState'
import { FaIcon } from '../FaIcon/FaIcon'

import { IProps } from './IProps'
import {
  ModalCloseWrapper,
  ModalExclamationWrapper,
  ModalMessage,
  ModalMessageTitle,
  StyledMobileModal,
  StyledModal,
} from './style'
import { useHistory } from '../../../Router'
import { useAppDispatch } from '../../../store/hooks'
import { useMobileCheck } from '../../Util/useMobileCheck'

export const WelcomeMessage = ({
  customMessage,
  isValidateEmail,
  isVisible,
}: IProps) => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(true)

  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const history = useHistory()

  const { settings, isCustomerExisting } = useSelector((state: IAppState) => ({
    settings: state.settings.values,
    isCustomerExisting: state.settings.isCustomerExisting,
  }))
  const mobileCheck = useMobileCheck()
  const ModalComponent = mobileCheck ? StyledMobileModal : StyledModal

  const pathsNotShowingMessage = ['annulation-condition', 'opt-in']
  for (const path of pathsNotShowingMessage) {
    if (history.location.pathname.includes(path)) {
      return null
    }
  }

  const openModal = () => {
    setIsModalVisible(true)
  }

  const closeModal = () => {
    setIsModalVisible(false)
    if (isValidateEmail) {
      dispatch(validateEmailReset())
    }
  }

  if (!isVisible) {
    return null
  }
  if (typeof settings === 'undefined') {
    return null
  }
  if (!settings.message) {
    return null
  }
  if (!isCustomerExisting && settings.onlineAppointmentsExistingCustomersOnly) {
    return null
  }

  return (
    <>
      <ModalComponent
        propagateSwipe
        backgroundcolor={settings.backgroundColor}
        isVisible={isModalVisible}
        animationIn="slideInDown"
        animationOut="slideOutUp"
        onBackdropPress={closeModal}
        swipeDirection="up"
      >
        <ScrollView>
          <ModalMessageTitle>{t('Message')}</ModalMessageTitle>
          <ModalMessage highlightcolor={settings.highlightColor}>
            {customMessage || settings.message}
          </ModalMessage>
        </ScrollView>
        <ModalCloseWrapper onPress={closeModal}>
          <FaIcon
            cssStyle={{ width: '22px', height: '22px' }}
            icon={faTimes}
            color={settings.highlightColor}
          />
        </ModalCloseWrapper>
      </ModalComponent>
      <ModalExclamationWrapper onPress={openModal}>
        <FaIcon
          size="lg"
          icon={faExclamationCircle}
          color={settings.highlightColor}
        />
      </ModalExclamationWrapper>
    </>
  )
}
