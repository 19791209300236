export interface IUserData {
  firstName: string
  lastName: string
  phone: string
  email: string
  isEmailSend: boolean
  street: string
  city: string
  zip: string
}

export interface ISetUserData {
  firstName?: string
  lastName?: string
  phone?: string
  email?: string
  isEmailSend?: boolean
  street?: string
  city?: string
  zip?: string
}

export const initialUserData: IUserData = {
  firstName: '',
  lastName: '',
  phone: '',
  email: '',
  isEmailSend: false,
  street: '',
  city: '',
  zip: '',
}
