import { URL_API } from 'react-native-dotenv'

import axios, { AxiosResponse } from 'axios'

import { ISurvey, ISurveyAnswers } from './ISurvey'
import Transformer from './Transformer'

export default class Client {
  public static getSurveyFromClient(surveyId: string): Promise<any> {
    const requestOptions: object = {
      method: 'GET',
      url: `${URL_API}/anonymous/customers/surveys/${surveyId}`,
    }

    return axios(requestOptions).then(
      (response: AxiosResponse): ISurvey =>
        Transformer.transform(response.data.survey)
    )
  }

  public static postAnswers(
    answers: ISurveyAnswers,
    surveyId: string,
    invitationUuid?: string
  ): Promise<any> {
    const requestOptions: object = {
      method: 'POST',
      url: `${URL_API}/anonymous/customers/surveys/${surveyId}/submit`,
      data: {
        customer_id: answers.customerId,
        invitation_uuid: invitationUuid || null,
        token: answers.token,
        answers: answers.answers.map((answer) => ({
          question_id: answer.questionId,
          value: answer.value,
        })),
      },
    }
    return axios(requestOptions)
  }
}
